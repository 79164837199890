import { TEAM_ROLES } from '../../../constants';

export const getMemberMapping = (members, userAlias) =>
  members
    ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map((t) => ({
      id: t?.id,
      alias: `${t?.User?.alias ? t?.User?.alias : 'No alias yet'}${
        t?.User?.alias === userAlias ? ' (You)' : ''
      }`,
      email: t?.email,
      role: t?.role,
      status: t?.User ? 'Registered' : 'Invited',
      memberId: t?.id,
    }));

export const getActionStatus = (
  rowData,
  userAlias,
  onlyOneTeamManager,
  viewOnly,
  isPrivate,
) => {
  const isTeamManager = rowData?.role === TEAM_ROLES.TEAM_MANAGER;
  const isInvitedUser = rowData?.registration === 'Invited';
  const isCurrentUserRow =
    rowData?.aliasAndEmail?.alias === `${userAlias} (You)`;
  let action2Label = 'remove';
  let disableChangeRole = false;
  let disableAction2 = false;
  let showChangeRoleIcon = false;
  let showChangeRoleInvitedIcon = false;
  let showRemoveIcon = false;
  let hideAction2 = false;
  // only 1 TEAM_MANAGER and TEAM_MANAGER logged in
  // disable CHANGE_ROLE and label LEAVE disabled
  if (onlyOneTeamManager && isCurrentUserRow && isTeamManager) {
    disableChangeRole = true;
    disableAction2 = true;
    showChangeRoleIcon = true;
    showRemoveIcon = true;
  }
  // only 1 TEAM_MANAGER and TEAM_MANAGER logged in
  // disable CHANGE_ROLE and label LEAVE disabled
  if (onlyOneTeamManager && !isCurrentUserRow && isTeamManager) {
    disableChangeRole = true;
    disableAction2 = true;
    showChangeRoleIcon = true;
    showRemoveIcon = true;
  }
  // Invited user
  // disable CHANGE_ROLE
  if (isInvitedUser) {
    disableChangeRole = true;
    showChangeRoleInvitedIcon = true;
  }
  // current user row is always displayed as leave
  if (isCurrentUserRow) {
    action2Label = 'leave';
  }

  // view only can not have action2 for other rows
  if (viewOnly && !isCurrentUserRow) {
    hideAction2 = true;
    showRemoveIcon = false;
  }
  // if team is private , allow both team manager and general member to remove/leave
  // if the user is team manager and there are other team managers present
  if (isPrivate) {
    if (
      action2Label === 'leave' &&
      (!isTeamManager || (isTeamManager && !onlyOneTeamManager))
    ) {
      disableAction2 = false;
    }
  }

  return {
    action2Label,
    disableChangeRole,
    disableAction2,
    showChangeRoleIcon,
    showChangeRoleInvitedIcon,
    showRemoveIcon,
    isCurrentUserRow,
    hideAction2,
  };
};
