import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import {
  FdLoadingSpinner,
  FdModal,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdTab,
} from '@fifthdomain/fe-shared';
import { listTasksByOrgId } from '../../graphql/queries';
import ChallengeListTable from './ChallengeListTable';
import ChallengesComposition from './ChallengesComposition';
import { PROFICIENCY_LEVELS } from '../../constants';
import { getDifficultyLevel } from '../../shared/utils/difficultyMapping';

const ChallengeOverview = ({ openModal, onDismiss, userData }) => {
  const globalSnap = useSnapshot(globalStore);

  const { data: listTasksData, loading: listTasksLoading } = useQueryRecursive(
    gql(listTasksByOrgId),
    {
      variables: {
        orgId: globalSnap.orgId,
        filter: {
          userId: { eq: userData?.id },
        },
        limit: 1000,
      },
      skip: !globalSnap.orgId,
    },
  );
  const allTasksCreated =
    listTasksData?.listTasksByOrgId?.items
      ?.filter((t) => t?.type !== 'CONTAINER')
      ?.map((item) => ({
        ...item,
        specialties: item?.specialty?.name,
        difficultyLabel:
          item?.difficulty > 5
            ? getDifficultyLevel(item?.difficulty)
            : PROFICIENCY_LEVELS[item?.difficulty],
      })) || [];

  if (listTasksLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <FdModal
      size="lg"
      showClose
      title={`Challenge Contribution by ${userData?.name}  `}
      description={
        'Below, you will find all the challenges created by the user that are owned by your organisation.The "Challenge Composition" tab provides insights into the diversity of their challenges, categorised by Professional Specialties, Proficiency Levels, and Types.'
      }
      open={openModal}
      onDismiss={() => {
        onDismiss();
      }}
      data-cy="open-modal"
    >
      <FdTab
        label={[
          {
            label: 'Challenges List',
            index: 0,
            data: <ChallengeListTable rows={allTasksCreated} />,
          },
          {
            label: 'Challenges Composition',
            index: 1,
            data: <ChallengesComposition allTasksCreated={allTasksCreated} />,
          },
        ]}
      />
    </FdModal>
  );
};

ChallengeOverview.propTypes = {
  userData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  openModal: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

ChallengeOverview.defaultProps = {
  userData: [],
};

export default ChallengeOverview;
