import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';
import { getFileSignedURL } from '../../shared/utils/fileUtils';

const useStyles = makeStyles((theme) => ({
  urlLink: {
    '& a': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));
function FileAttachment({ id, name, url }) {
  const classes = useStyles();
  setTimeout(() => {
    getFileSignedURL(name, id, url);
  }, 1300);

  return (
    <Box display="flex" alignItems="center">
      <Box mt={0.5} />
      <FdTypography
        variant="body1"
        className={classes.urlLink}
        data-cy="download-link"
      >
        <a id={id} key={id} href={url}>
          {name}
        </a>
      </FdTypography>
    </Box>
  );
}

FileAttachment.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default FileAttachment;
