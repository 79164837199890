import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  makeStyles,
} from '@material-ui/core';
import {
  FdCard,
  FdRadioGroup,
  FdTypography,
  FdTextField,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import { ASSESSMENT_TYPES } from '../../constants';
import FdTextView from '../FdTextView';

const useStyles = makeStyles(() => ({
  narrowTextField: {
    '& [class*="MuiFormHelper"]': {
      width: '400px',
    },
  },
}));

const CompetitionType = ({ editMode, isDuplicateMode }) => {
  const { control, setValue, resetField } = useFormContext();
  const watchMultiLevel = useWatch({
    control,
    name: 'multiLevel',
  });

  const watchassessmentType = useWatch({
    control,
    name: 'assessmentType',
  });

  const classes = useStyles();
  const levelsRef = useRef(null);
  const Wrapper = editMode ? Box : FdCard;
  return (
    <Wrapper
      heading="Competition Type"
      subHeading={
        isDuplicateMode
          ? 'Select the competition type for the duplicate competition to be created. Note that this cannot be changed later.'
          : 'Select the type of competition that you would like to create.'
      }
      variant="outlined"
    >
      <Box display="flex" flexDirection="column">
        <Controller
          control={control}
          name="assessmentType"
          render={({
            field: { ref, value: fieldValue, ...rest },
            fieldState: { error },
          }) =>
            editMode ? (
              <FdTextView
                my={2}
                label="Competition Type"
                value={fieldValue?.value}
              />
            ) : (
              <FdDelayed>
                <Box mt={1} mb={1} ml={1}>
                  <FdRadioGroup
                    id="assessmentType"
                    label="Competition Type"
                    options={[
                      ASSESSMENT_TYPES.INDIVIDUAL_BASED,
                      ASSESSMENT_TYPES.TEAM_BASED,
                    ]}
                    defaultValue={fieldValue?.value}
                    fullWidth
                    error={error && error.message}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              </FdDelayed>
            )
          }
        />

        <Controller
          control={control}
          name="maxTeamSize"
          render={({ field: { ...rest }, fieldState: { error } }) => (
            <Box>
              {watchassessmentType?.value === ASSESSMENT_TYPES.TEAM_BASED && (
                <Box mt={1} mb={2}>
                  <Box mb={0.5}>
                    <FdTypography variant="subtitle1">
                      Max Team Size
                    </FdTypography>
                    <FdTypography variant="body1" color="secondary">
                      This is the maximum number of participants allowed on a
                      team for this competition.
                    </FdTypography>
                  </Box>
                  <FdTextField
                    id="maxTeamSize"
                    label=""
                    type="number"
                    required
                    error={error}
                    helperText={error && error.message}
                    width="80px"
                    {...rest}
                    inputRef={levelsRef}
                    className={classes.narrowTextField}
                  />
                </Box>
              )}
            </Box>
          )}
        />

        <Controller
          control={control}
          name="multiLevel"
          render={({
            field: { ref, value: fieldValue, ...rest },
            fieldState: { error },
          }) => (
            <Box>
              {watchMultiLevel && editMode && (
                <FdTextView label="Level" value="Multi- Level" />
              )}
              {!editMode && (
                <Box my={2}>
                  <FormGroup>
                    <FdTypography variant="subtitle1">Level</FdTypography>
                    {isDuplicateMode && (
                      <Box mt={1}>
                        <FdTypography variant="body1" color="secondary">
                          The duplicate competition will retain the level type
                          of the original competition. This cannot be changed.
                        </FdTypography>
                      </Box>
                    )}
                    <FormControlLabel
                      control={
                        <Switch
                          id="multiLevel"
                          disabled={isDuplicateMode}
                          checked={fieldValue}
                          value={fieldValue?.value}
                          error={error && error.message}
                          data-cy="multi-level"
                          {...rest}
                          onChange={(e) => {
                            setValue('multiLevel', e.target.checked);
                            if (!e.target.checked) {
                              resetField('levels');
                              setValue('tasks', []);
                              levelsRef.current.value = '';
                            }
                          }}
                          inputRef={ref}
                        />
                      }
                      label="Multi-Level"
                    />
                  </FormGroup>
                </Box>
              )}
            </Box>
          )}
        />
        <Controller
          control={control}
          name="levels"
          render={({ field: { ...rest }, fieldState: { error } }) => (
            <Box>
              {watchMultiLevel && (
                <Box mt={1} mb={2}>
                  <Box mb={0.5}>
                    <FdTypography
                      variant="subtitle1"
                      color={!watchMultiLevel ? 'secondary' : 'primary'}
                    >
                      Specify number of levels
                    </FdTypography>
                  </Box>
                  <FdTextField
                    id="levels"
                    label=""
                    disabled={!watchMultiLevel || isDuplicateMode}
                    type="number"
                    required
                    error={error}
                    helperText={
                      (error && error.message) ||
                      (editMode &&
                        'Please remember to add challenges to the new level(s) created.')
                    }
                    width="80px"
                    {...rest}
                    inputRef={levelsRef}
                    className={classes.narrowTextField}
                  />
                </Box>
              )}
            </Box>
          )}
        />
        <Controller
          control={control}
          name="guided"
          render={({
            field: { ref, value: fieldValue, ...rest },
            fieldState: { error },
          }) => (
            <Box>
              {editMode && (
                <FdTextView
                  label="Is this a Guided Competition"
                  value={fieldValue ? 'Yes' : 'No'}
                />
              )}
              {!editMode && (
                <Box my={2}>
                  <FormGroup>
                    <FdTypography variant="subtitle1">
                      Make this a Guided Competition
                    </FdTypography>
                    <FdTypography variant="caption">
                      In a guided competition, participants can access solution
                      walkthroughs to help navigate and solve challenges
                      throughout the competition.
                    </FdTypography>
                    {isDuplicateMode && (
                      <Box mt={1}>
                        <FdTypography variant="body1" color="secondary">
                          The duplicate competition will retain the guided type
                          of the original competition. This cannot be changed.
                        </FdTypography>
                      </Box>
                    )}
                    <FormControlLabel
                      control={
                        <Switch
                          id="guided"
                          disabled={isDuplicateMode}
                          checked={fieldValue}
                          value={fieldValue?.value}
                          error={error && error.message}
                          data-cy="guided"
                          {...rest}
                          onChange={(e) => {
                            setValue('guided', e.target.checked);
                            if (!e.target.checked) {
                              resetField('guided');
                            }
                          }}
                          inputRef={ref}
                        />
                      }
                      label="Guided"
                    />
                  </FormGroup>
                </Box>
              )}
            </Box>
          )}
        />
      </Box>
    </Wrapper>
  );
};

CompetitionType.propTypes = {
  editMode: PropTypes.bool,
  isDuplicateMode: PropTypes.bool.isRequired,
};

CompetitionType.defaultProps = {
  editMode: false,
};

export default CompetitionType;
