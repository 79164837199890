import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { BasePage, FdLoadingSpinner } from '@fifthdomain/fe-shared';
import { getUserById } from '../graphql/queries';
import UserProfile from '../components/User/UserProfile';

const NonAffiliatedUserProfile = () => {
  const { userId } = useParams();
  const { data: userData, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: userId,
    },
    skip: !userId,
  });
  const selectedUser = userData?.getUserById?.items[0];

  if (userLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <BasePage
      breadCrumbs={[
        { name: 'Home', url: '/user-management/non-affiliated-users' },
      ]}
      currentPageBreadcrumbLabel={`Users / Non-Affiliated / View ${selectedUser?.name}`}
      linkComponent={RouterLink}
      renderBreadCrumbAsButton
      data-cy="user-profile-card"
    >
      <UserProfile selectedUser={selectedUser} />
    </BasePage>
  );
};

export default NonAffiliatedUserProfile;
