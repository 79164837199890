import notificationStore from './notificationStore';

const initializeNotificationStore = () => {
  notificationStore.announcementsRefreshPage = false;
  notificationStore.chatForumAdminEditInProgress = false;
  notificationStore.chatForumAdminPendingRefresh = false;
  notificationStore.chatForumParticipantEditInProgress = false;
  notificationStore.chatForumParticipantPendingRefresh = false;
};

export default initializeNotificationStore;
