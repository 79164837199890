import React from 'react';

const TrainingIcon = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="12" height="12" rx="6" fill="#B3E5FC" />
    <path
      opacity="0.85"
      d="M6.3139 6.44L5.6865 6.74L4.38915 5.93L4.74007 5.25C4.81096 5.11667 4.91553 5.00833 5.05377 4.925C5.19202 4.84167 5.34266 4.8 5.50572 4.8H6.07996V2.4C6.07996 2.28667 6.12072 2.19167 6.20225 2.115C6.28377 2.03833 6.3848 2 6.50532 2H8.71719C8.79518 2 8.85544 2.03167 8.89797 2.095C8.94051 2.15833 8.94405 2.22333 8.90861 2.29L8.67466 2.71C8.66048 2.74333 8.65339 2.77333 8.65339 2.8C8.65339 2.82667 8.66048 2.85667 8.67466 2.89L8.90861 3.31C8.94405 3.37667 8.94051 3.44167 8.89797 3.505C8.85544 3.56833 8.79518 3.6 8.71719 3.6H6.93068V4.8H7.46238C7.62543 4.8 7.77431 4.84 7.90901 4.92C8.04371 5 8.15005 5.10667 8.22803 5.24L8.60022 5.94L7.32414 6.74L6.69673 6.44C6.64001 6.40667 6.57621 6.39 6.50532 6.39C6.43442 6.39 6.37062 6.40667 6.3139 6.44ZM2.54946 9.42L4.00632 6.65L5.42065 7.53C5.48445 7.57 5.55357 7.59333 5.62801 7.6C5.70245 7.60667 5.77511 7.59333 5.84601 7.56L6.50532 7.25L7.16463 7.56C7.23552 7.59333 7.30819 7.60833 7.38262 7.605C7.45706 7.60167 7.52618 7.58 7.58999 7.54L8.98304 6.67L10.4505 9.42C10.5214 9.55333 10.5161 9.68333 10.4346 9.81C10.3531 9.93667 10.2308 10 10.0677 10H2.93229C2.76923 10 2.64694 9.93667 2.56541 9.81C2.48388 9.68333 2.47857 9.55333 2.54946 9.42Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export default TrainingIcon;
