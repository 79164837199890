import React, { useEffect, useContext } from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  AuthContext,
  globalStore,
  useSnapshot,
  FdProgress,
} from '@fifthdomain/fe-shared';
import setAppMarginRightByIds from '../shared/utils/layout';
import UserAssessmentProvider from '../providers/UserAssessmentProvider';

const ParticipantRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const globalSnap = useSnapshot(globalStore);
  const history = useHistory();
  const assessmentId = rest?.computedMatch.params?.assessmentId;
  const orgId = rest?.computedMatch.params?.orgId;
  const sharedLinkRoute =
    assessmentId &&
    rest?.path === '/competitions/comp/:assessmentId/org/:orgId';

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (userData) {
        setUser(userData);
        // add logged in user to global state
        globalStore.user = userData;
      })
      .catch((err) => {
        console.log('error: ', err);
        // via custom url copy
        if (sharedLinkRoute) {
          window.location.replace(
            `${window.location.protocol}//${window.location.host}/a/login/e/comp/${assessmentId}/org/${orgId}`,
          );
        } else {
          history.push('/');
        }
      });
  }, [assessmentId, history, setUser, sharedLinkRoute, orgId]);

  if (!user || !globalSnap?.userType) {
    return <FdProgress />;
  }

  return (
    <Route
      {...rest}
      // eslint-disable-next-line consistent-return
      render={(props) => {
        const type = globalSnap?.userType;
        if (user && type === 'PARTICIPANT') {
          setAppMarginRightByIds(['topnav'], '0'); // reset topnav to full width on page initialize
          // redirect participant to survey if they haven't done.

          if (sharedLinkRoute) {
            return <UserAssessmentProvider assessmentId={assessmentId} />;
          }
          return <Component {...rest} {...props} />;
        }
        if (user && ['ADMIN', 'MANAGER'].includes(type)) {
          return <Redirect to="/landing" />;
        }
      }}
    />
  );
};

ParticipantRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ParticipantRoute;
