import {
  PROFICIENCY,
  PROFICIENCY_LEVELS,
  proficiencyLevels,
  PROFICIENCY_RANGE,
} from '../../constants';

const getProficiency = (value) =>
  Object.entries(PROFICIENCY).find(([_, proficiency]) => {
    const [start, end] = proficiency.range.split('-').map(Number);
    return value >= start && value <= end;
  });

const getDifficultyValue = (difficulty) => {
  const proficiencyEntry = Object.entries(PROFICIENCY).find(
    ([key]) => key === difficulty,
  );

  if (proficiencyEntry) {
    // eslint-disable-next-line no-unused-vars
    const [_, { range }] = proficiencyEntry;
    const [start, end] = range.split('-').map(Number);
    return Math.floor((start + end) / 2);
  }
  return null;
};

const getDifficultyLevel = (value) => {
  const proficiencyEntry = getProficiency(value);
  if (proficiencyEntry) {
    return proficiencyEntry[0];
  }
  return null;
};

const getDifficultyColor = (value) => {
  const proficiencyEntry = getProficiency(value);
  if (proficiencyEntry) {
    return proficiencyEntry[1].color;
  }
  return null;
};

const getProficiencyLevel = (difficulty) => PROFICIENCY_LEVELS[difficulty];

const getDifficultyLabel = (difficulty) =>
  difficulty > 5
    ? getDifficultyLevel(difficulty)
    : proficiencyLevels.at(difficulty - 1);

const getDifficultyLabelColor = (difficulty) =>
  PROFICIENCY[getDifficultyLabel(difficulty)]?.color || '';

const getProficiencyKey = (difficulty) => {
  const difficultyMapping =
    difficulty > 5
      ? Object.entries(PROFICIENCY_RANGE).find(([_, proficiency]) => {
          const [start, end] = proficiency.range.split('-').map(Number);
          return difficulty >= start && difficulty <= end;
        })?.[0]
      : proficiencyLevels.at(difficulty - 1);

  return proficiencyLevels?.indexOf(difficultyMapping) + 1;
};

export {
  getDifficultyLevel,
  getDifficultyValue,
  getDifficultyColor,
  getProficiencyLevel,
  getDifficultyLabel,
  getDifficultyLabelColor,
  getProficiencyKey,
};
