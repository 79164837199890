import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import {
  FdTypography,
  useQueryRecursive,
  FdSkeleton,
  FdCard,
  FdButton,
  FdExternalLink,
  FdChip,
} from '@fifthdomain/fe-shared';
import {
  listJumpboxInstancesByUserAssessmentId,
  getVmConsole,
} from '../../graphql/queries';
import { startJumpbox, restartJumpbox } from '../../graphql/mutations';

const ParticipantJumpBox = ({ assessmentId, enableJumpbox, enableVPN }) => {
  const [isJumboxStarting, setIsJumboxStarting] = useState(false);
  const [isJumpboxReady, setIsJumpboxReady] = useState(false);
  const [fetchConsoleLoading, setFetchConsoleLoading] = useState(false);
  const [restartingJumpBox, setRestartingJumpBox] = useState(false);

  const {
    data: jumpboxData,
    loading: jumpboxLoading,
    startPolling,
    stopPolling,
    refetch: refetchListJumpboxes,
  } = useQueryRecursive(gql(listJumpboxInstancesByUserAssessmentId), {
    variables: {
      userAssessmentId: assessmentId,
      filter: {
        or: [
          { status: { eq: 'READY' } },
          { status: { eq: 'IN_BUILD_QUEUE' } },
          { status: { eq: 'BUILD_IN_PROGRESS' } },
        ],
      },
    },
    staleTime: { seconds: 0 },
    skip: enableJumpbox !== 'TRUE',
    onCompleted: (data) => {
      // start polling jumpbox instance until its READY
      const jumpboxStatus =
        data?.listJumpboxInstancesByUserAssessmentId?.items?.[0]?.status;
      if (jumpboxStatus && jumpboxStatus === 'READY') {
        setIsJumpboxReady(true);
        setIsJumboxStarting(false);
        setRestartingJumpBox(false);
        stopPolling();
        return;
      }
      setIsJumpboxReady(false);
      if (data?.listJumpboxInstancesByUserAssessmentId?.items.length > 0) {
        startPolling(60000);
      }
    },
  });

  const [startJumpboxMutation, { loading: startJumpboxLoading }] = useMutation(
    gql(startJumpbox),
    {
      onError: () => {
        // retry start on error
        refetchListJumpboxes();
        setRestartingJumpBox(true);
      },
      onCompleted: () => {
        refetchListJumpboxes();
        setRestartingJumpBox(false);
      },
    },
  );

  const [restartJumpboxMutation, { loading: restartJumpboxLoading }] =
    useMutation(gql(restartJumpbox), {
      onCompleted: () => {
        setTimeout(() => {
          refetchListJumpboxes();
        }, 5000);
      },
    });

  const [fetchConsole] = useLazyQuery(gql(getVmConsole), {
    skip: enableJumpbox !== 'TRUE' || !isJumpboxReady,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const { url } = data.getVmConsole;
      if (url) {
        window.open(url, '_blank');
      } else {
        setIsJumpboxReady(false);
      }
      setFetchConsoleLoading(false);
    },
  });

  const jumpbox =
    jumpboxData?.listJumpboxInstancesByUserAssessmentId?.items?.[0];

  return (
    <FdCard
      variant="outlined"
      style={{
        width:
          enableJumpbox === 'TRUE' && enableVPN === 'TRUE' ? '50%' : '100%',
      }}
    >
      <Box>
        <Box mb={2}>
          <FdTypography variant="subtitle1">Jump Box</FdTypography>
        </Box>
        <Box className="flex gap-x-3 mb-4">
          <FdSkeleton
            loading={
              jumpboxLoading ||
              isJumboxStarting ||
              startJumpboxLoading ||
              restartJumpboxLoading ||
              fetchConsoleLoading
            }
            height="32px"
            width="198px"
          >
            <FdButton
              style={
                isJumpboxReady
                  ? {
                      backgroundColor: '#228b22',
                      color: '#FFFFFF',
                      borderColor: '#228b22',
                    }
                  : {}
              }
              disabled={
                isJumboxStarting ||
                startJumpboxLoading ||
                restartJumpboxLoading ||
                fetchConsoleLoading
              }
              onClick={() => {
                if (isJumpboxReady) {
                  setFetchConsoleLoading(true);
                  fetchConsole({
                    variables: {
                      vmId: jumpbox?.id,
                      type: 'JUMPBOX',
                    },
                  });
                  refetchListJumpboxes();
                } else if (!startJumpboxLoading) {
                  setIsJumboxStarting(true);
                  startJumpboxMutation({
                    variables: {
                      userAssessmentId: assessmentId,
                    },
                  });
                  startPolling(1000);
                }
              }}
            >
              {!isJumpboxReady || startJumpboxLoading ? (
                <Box display="flex" alignItems="center">
                  <Box mr={2}>Start Jump Box</Box>
                  {isJumboxStarting && (
                    <CircularProgress size={25} style={{ color: '#fff' }} />
                  )}
                </Box>
              ) : (
                'Connect to Jump Box'
              )}
            </FdButton>
          </FdSkeleton>
          {['BUILD_IN_PROGRESS', 'POWERING_OFF']?.includes(jumpbox?.status) && (
            <FdChip
              color="warning"
              size="medium"
              label={
                jumpbox?.status === 'BUILD_IN_PROGRESS'
                  ? 'Starting'
                  : 'Stopping'
              }
              className="ml-2"
            />
          )}
          {isJumpboxReady && (
            <FdButton
              variant="secondary"
              disabled={isJumboxStarting || restartingJumpBox}
              onClick={() => {
                setIsJumboxStarting(true);
                setRestartingJumpBox(true);
                restartJumpboxMutation({
                  variables: {
                    labInstanceId: jumpbox?.id,
                    hardReset: false,
                  },
                });
              }}
            >
              Restart Jump box
            </FdButton>
          )}
        </Box>
        <Box mb={2}>
          <FdTypography variant="body2">
            To securely access challenges, click &apos;Start Jump Box&apos; to
            initiate the process. After a short setup, connect by clicking
            &apos;Connect Jump Box&apos;. You can find the Jump Box credentials
            on the Competition Overview page in the left navigation menu.
            <FdExternalLink
              href="https://au.intercom.help/fifth-domain/en/articles/2346-using-jump-box-in-your-competition"
              noUnderline
            >
              Click here
            </FdExternalLink>
            learn more about Jump Box.
          </FdTypography>
        </Box>
      </Box>
    </FdCard>
  );
};

ParticipantJumpBox.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  enableJumpbox: PropTypes.bool.isRequired,
  enableVPN: PropTypes.bool.isRequired,
};

export default ParticipantJumpBox;
