import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdButton, FdTextField } from '@fifthdomain/fe-shared';

const FlagSubmission = ({
  onClickSubmit,
  loading,
  control,
  handleSubmit,
  Controller,
}) => {
  return (
    <form onSubmit={handleSubmit(onClickSubmit)}>
      <Box mt={1}>
        <Controller
          control={control}
          name="flag"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id="flag"
                label="Submit Flag"
                required
                fullWidth
                disabled={loading}
                placeholder="FLAG{text}"
                error={error}
                helperText={error && error.message}
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
        <Box mt={1} pb={1}>
          <FdButton size="large" type="submit" disabled={loading}>
            {loading ? (
              <Box width="60px">
                <CircularProgress size="2rem" />
              </Box>
            ) : (
              'Submit'
            )}
          </FdButton>
        </Box>
      </Box>
    </form>
  );
};

FlagSubmission.propTypes = {
  onClickSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  control: PropTypes.object.isRequired,
  Controller: PropTypes.elementType.isRequired,
};

export default FlagSubmission;
