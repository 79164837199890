import React from 'react';

const TargetProficiency = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="10" height="10" stroke="#0097A7" strokeWidth="2" />
  </svg>
);

export default TargetProficiency;
