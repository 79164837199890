import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdExternalLink } from '@fifthdomain/fe-shared';
import useGetProfileLink from '../../hooks/useGetProfileLink';

const ProfileHyperlink = ({ userId, children }) => {
  const profileLink = useGetProfileLink(userId);

  return (
    <Box display="flex" mr={2}>
      {profileLink ? (
        <FdExternalLink href={profileLink}>{children}</FdExternalLink>
      ) : (
        children
      )}
    </Box>
  );
};

ProfileHyperlink.propTypes = {
  userId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ProfileHyperlink;
