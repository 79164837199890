import React, { useEffect, useRef } from 'react';
import anime from 'animejs';

const TickIcon = () => {
  const circleRef = useRef(null);
  const tickRef = useRef(null);

  useEffect(() => {
    anime({
      targets: circleRef.current,
      fill: ['rgba(0, 0, 0, 0)', 'rgba(51, 51, 255, 1)'],
      easing: 'easeInExpo',
      duration: 1500,
      complete: () => {
        anime({
          targets: tickRef.current,
          fill: ['rgba(0, 0, 0, 0)', '#FFFFFF'],
          easing: 'easeInElastic',
          duration: 1000,
        });
      },
    });
  }, []);

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        ref={circleRef}
        d="M8.5 0C4.084 0 0.5 3.584 0.5 8C0.5 12.416 4.084 16 8.5 16C12.916 16 16.5 12.416 16.5 8C16.5 3.584 12.916 0 8.5 0Z"
        fill="rgba(0, 0, 0, 0)"
      />
      <path
        ref={tickRef}
        d="M6.332 11.432L3.46 8.56C3.148 8.248 3.148 7.744 3.46 7.432C3.772 7.12 4.276 7.12 4.588 7.432L6.9 9.736L12.404 4.232C12.716 3.92 13.22 3.92 13.532 4.232C13.844 4.544 13.844 5.048 13.532 5.36L7.46 11.432C7.156 11.744 6.644 11.744 6.332 11.432Z"
        fill="rgba(0, 0, 0, 0)"
      />
    </svg>
  );
};

export default TickIcon;
