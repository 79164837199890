import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WORK_ROLE_SKILLS_COLOR } from '../../../constants';

const HeatMapTick = ({
  data,
  skillValue,
  textColor,
  y,
  flip,
  svgValue,
  textValue,
}) => {
  const skillData = data?.find((i) => i?.id === skillValue);
  const proficiencyRequired = skillData?.proficiencyLevelsRequired;
  const maxSkillCompleted = _.maxBy(
    skillData?.data?.filter((i) => i?.yLevelPoints > 0),
    'x',
  )?.x;

  const result = proficiencyRequired
    ? maxSkillCompleted >= proficiencyRequired
    : undefined;

  return (
    <>
      {flip && (
        <g transform={`translate(0,${y})`}>
          {result ? (
            <g transform={svgValue}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.16 7.08L3.855 5.775C3.745 5.665 3.61 5.61 3.45 5.61C3.29 5.61 3.15 5.67 3.03 5.79C2.92 5.9 2.865 6.04 2.865 6.21C2.865 6.38 2.92 6.52 3.03 6.63L4.74 8.34C4.85 8.45 4.99 8.505 5.16 8.505C5.33 8.505 5.47 8.45 5.58 8.34L8.985 4.935C9.095 4.825 9.15 4.69 9.15 4.53C9.15 4.37 9.09 4.23 8.97 4.11C8.86 4 8.72 3.945 8.55 3.945C8.38 3.945 8.24 4 8.13 4.11L5.16 7.08ZM6 12C5.17 12 4.39 11.8424 3.66 11.5272C2.93 11.2124 2.295 10.785 1.755 10.245C1.215 9.705 0.7876 9.07 0.4728 8.34C0.1576 7.61 0 6.83 0 6C0 5.17 0.1576 4.39 0.4728 3.66C0.7876 2.93 1.215 2.295 1.755 1.755C2.295 1.215 2.93 0.7874 3.66 0.4722C4.39 0.1574 5.17 0 6 0C6.83 0 7.61 0.1574 8.34 0.4722C9.07 0.7874 9.705 1.215 10.245 1.755C10.785 2.295 11.2124 2.93 11.5272 3.66C11.8424 4.39 12 5.17 12 6C12 6.83 11.8424 7.61 11.5272 8.34C11.2124 9.07 10.785 9.705 10.245 10.245C9.705 10.785 9.07 11.2124 8.34 11.5272C7.61 11.8424 6.83 12 6 12Z"
                  fill={WORK_ROLE_SKILLS_COLOR.fulfilledSkills}
                />
              </svg>
            </g>
          ) : (
            result === false && (
              <g transform={svgValue}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 6.6C6.17 6.6 6.3126 6.5424 6.4278 6.4272C6.5426 6.3124 6.6 6.17 6.6 6V3.585C6.6 3.415 6.5426 3.275 6.4278 3.165C6.3126 3.055 6.17 3 6 3C5.83 3 5.6876 3.0574 5.5728 3.1722C5.4576 3.2874 5.4 3.43 5.4 3.6V6.015C5.4 6.185 5.4576 6.325 5.5728 6.435C5.6876 6.545 5.83 6.6 6 6.6ZM6 9C6.17 9 6.3126 8.9424 6.4278 8.8272C6.5426 8.7124 6.6 8.57 6.6 8.4C6.6 8.23 6.5426 8.0874 6.4278 7.9722C6.3126 7.8574 6.17 7.8 6 7.8C5.83 7.8 5.6876 7.8574 5.5728 7.9722C5.4576 8.0874 5.4 8.23 5.4 8.4C5.4 8.57 5.4576 8.7124 5.5728 8.8272C5.6876 8.9424 5.83 9 6 9ZM6 12C5.17 12 4.39 11.8424 3.66 11.5272C2.93 11.2124 2.295 10.785 1.755 10.245C1.215 9.705 0.7876 9.07 0.4728 8.34C0.1576 7.61 0 6.83 0 6C0 5.17 0.1576 4.39 0.4728 3.66C0.7876 2.93 1.215 2.295 1.755 1.755C2.295 1.215 2.93 0.7874 3.66 0.4722C4.39 0.1574 5.17 0 6 0C6.83 0 7.61 0.1574 8.34 0.4722C9.07 0.7874 9.705 1.215 10.245 1.755C10.785 2.295 11.2124 2.93 11.5272 3.66C11.8424 4.39 12 5.17 12 6C12 6.83 11.8424 7.61 11.5272 8.34C11.2124 9.07 10.785 9.705 10.245 10.245C9.705 10.785 9.07 11.2124 8.34 11.5272C7.61 11.8424 6.83 12 6 12Z"
                    fill={WORK_ROLE_SKILLS_COLOR.unfulfilledSkills}
                  />
                </svg>
              </g>
            )
          )}
          <text
            transform={textValue} // textvalue
            style={{
              fill: textColor,
              fontFamily: 'sans-serif',
              fontSize: '11px',
            }}
          >
            {skillValue}
          </text>
        </g>
      )}
    </>
  );
};

HeatMapTick.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flip: PropTypes.bool.isRequired,
  skillValue: PropTypes.string.isRequired,
  svgValue: PropTypes.string.isRequired,
  textValue: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  y: PropTypes.string.isRequired,
};

export default HeatMapTick;
