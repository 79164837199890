import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { Box } from '@material-ui/core';
import {
  useQueryRecursive,
  FdTypography,
  FdCortex,
  cortexSort,
  getCortexLevels,
} from '@fifthdomain/fe-shared';
import { listSkills } from '../../graphql/queries';
import {
  AssignedTraining,
  DemonstratedTraining,
  ErrorIcon,
  NotDemonstratedTraining,
  TargetProficiency,
  TickIcon,
  TrainingIcon,
} from './icons';
import { proficiencyLevels } from '../../constants';

const LegendElement = ({ Icon, label }) => (
  <Box className="flex items-center justify-center gap-x-2">
    <Icon />
    <FdTypography variant="captiontext2">{label}</FdTypography>
  </Box>
);

LegendElement.propTypes = {
  Icon: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
};

const getYBorderColor = (
  solved,
  skillSolved,
  trainingInLevel,
  value,
  l,
  requiredProficiency,
) => {
  const trainingReachedTarget = trainingInLevel >= requiredProficiency;

  if (requiredProficiency === l) return 'rgba(0, 151, 167, 1)';

  // solved in current level
  if (solved) return 'rgba(105, 240, 174, 1)';
  // solved in some level, so higher levels which are not solved shows as solved
  if (skillSolved) return 'rgba(227, 230, 236, 1)';
  // training planned on current level and lower levels
  if (trainingInLevel >= l) return 'rgba(3, 169, 244, 1)';
  if (!trainingReachedTarget && l > trainingInLevel) {
    // level 3 and above training is not mandatory
    if (l > requiredProficiency) return 'rgba(227, 230, 236, 1)';
    return 'rgba(77, 208, 225, 1)';
  }

  // no training, no solves
  if (!trainingInLevel && value === 0) {
    // level 3 and above training is not mandatory
    if (l > requiredProficiency) return 'rgba(227, 230, 236, 1)';
    return 'rgba(77, 208, 225, 1)';
  }
  return 'rgba(245, 245, 245, 1)';
};

const getYBackgroundColor = (solved, training, value) => {
  if (solved) return 'rgba(105, 240, 174, 1)';
  if (training) return '#fff';
  if (value === 0) return '#fff';
  return 'rgba(245, 245, 245, 1)';
};

const ToolTip = ({ skillName, proficiencyName, text }) => (
  <Box className="text-center">
    <Box className="flex gap-x-1 justify-center">
      <FdTypography variant="captiontext1">
        <strong>Skill:</strong>
      </FdTypography>
      <FdTypography variant="captiontext1">{skillName}</FdTypography>
    </Box>
    <Box className="flex gap-x-1 justify-center">
      <FdTypography variant="captiontext1">
        <strong>Proficiency:</strong>
      </FdTypography>
      <FdTypography variant="captiontext1">{proficiencyName}</FdTypography>
    </Box>
    <Box>
      <FdTypography variant="captiontext1" style={{ fontStyle: 'italic' }}>
        {text}
      </FdTypography>
    </Box>
  </Box>
);

ToolTip.propTypes = {
  skillName: PropTypes.string.isRequired,
  proficiencyName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const getCellTooltip = (
  skillName,
  solved,
  training,
  trainingPlannedInsufficient,
  challengeNames,
  l,
) => {
  let text;

  if (solved) {
    text =
      'This skill is fulfilled, as the assigned user has already demonstrated this skill at proficiency Level 3 (Competent) or above.';
  } else if (trainingPlannedInsufficient) {
    text = (
      <>
        This skill is currently unfulfilled, as it has not yet been demonstrated
        at proficiency Level 3 (Competent) or higher.
        <br />
        <br />
        The following selected challenge(s) will develop this required skill:
        <strong>{` ${challengeNames.join(', ')}`}</strong>
        <br />
        <br />
        However, as these challenges are below proficiency Level 3 (Competent),
        this skill will remain unfulfilled post-training.
      </>
    );
  } else if (training) {
    text = (
      <>
        This skill is currently unfulfilled, as it has not yet been demonstrated
        at proficiency Level 3 (Competent) or higher.
        <br />
        <br />
        This skill will become fulfilled upon completion of the following
        selected challenge(s):
        <strong>{` ${challengeNames.join(', ')}`}</strong>
      </>
    );
  } else {
    text = (
      <>
        This skill is currently unfulfilled, as it has not yet been demonstrated
        at proficiency Level 3 (Competent) or higher.
        <br />
        <br />
        No challenge selected below covers this unfulfilled skill. Please select
        a relevant challenge to ensure training coverage for required skills.
      </>
    );
  }

  return (
    <ToolTip
      skillName={skillName}
      proficiencyName={`Level ${l} - ${proficiencyLevels[l - 1]}`}
      text={text}
    />
  );
};

const WorkRoleCortex = ({ data, loading }) => {
  const { data: listSkillsData } = useQueryRecursive(gql(listSkills));

  const specialties = _(listSkillsData?.listSkills?.items || [])
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items: items?.filter((s) => s?.alias),
    }))
    .value();

  const specialtiesFormatted =
    specialties?.sort(cortexSort)?.map((specialty, idx) => {
      const { levels = [], flip = false } = getCortexLevels(idx);

      const dataSorted = specialty?.items?.map((skill) => {
        return {
          id: skill?.alias,
          data: [
            ...levels?.map((l) => {
              const levelData = data?.filter((d) => d?.alias === skill?.alias);
              const solvedLevels = levelData?.filter((d) => d?.solved);
              const solvedUptoLevel = _.maxBy(
                solvedLevels,
                'difficulty',
              )?.difficulty;

              const levelDataByDifficulty = levelData?.find(
                (d) => d?.difficulty === l,
              );
              const solved = solvedUptoLevel >= l;
              const requiredProficiency = _.maxBy(
                levelData?.map((i) => i?.requiredProficiency),
              );
              const skillSolved = solvedUptoLevel >= requiredProficiency;
              const value = levelDataByDifficulty?.value;

              const trainingLevels = levelData?.filter((d) => d?.training);
              const trainingDifficulty = trainingLevels?.map(
                (item) => item?.difficulty,
              );
              const trainingMaxLevel = _.maxBy(
                trainingLevels,
                'difficulty',
              )?.difficulty;
              const training = trainingMaxLevel >= l;
              const bgColor = getYBackgroundColor(solved, training, value);
              const challengesSelected =
                _.uniq(_.flatMap(levelData, 'challengesSelected')) || [];
              const trainingPlannedInsufficient =
                trainingMaxLevel < requiredProficiency;
              const borderColor = getYBorderColor(
                solved,
                skillSolved,
                trainingMaxLevel,
                value,
                l,
                requiredProficiency,
              );
              const borderStyle =
                requiredProficiency === l
                  ? 'border-l-2 border-r-2 border-t-2 border-b-2 border-solid'
                  : trainingDifficulty?.includes(l)
                  ? 'border-dotted'
                  : 'border-solid';
              return {
                x: l,
                yAlias: skill?.alias,
                yBackgroundColor: bgColor,
                yBorderColor: borderColor,
                yBorderStyle: borderStyle,
                ySolved: solved,
                ySkillSolved: skillSolved,
                yValue: value,
                yTraining: trainingMaxLevel,
                yToolTip:
                  bgColor !== 'rgba(245, 245, 245, 1)' &&
                  getCellTooltip(
                    `${skill?.name} (${skill?.alias})`,
                    skillSolved,
                    trainingMaxLevel,
                    trainingPlannedInsufficient,
                    challengesSelected,
                    l,
                  ),
              };
            }),
          ],
        };
      });
      return { ...specialty, flip, levels, dataSorted };
    }) || [];

  const iconStatus = specialtiesFormatted
    .map((s) =>
      s?.dataSorted?.map((ds) => ({
        id: ds?.id,
        status:
          ds?.data?.filter((d) => d?.ySkillSolved).length > 0
            ? 'fulfilled'
            : ds?.data?.filter((d) => d?.yTraining >= 3).length
            ? 'training'
            : ds?.data?.filter((d) => d?.yBackgroundColor === '#fff').length
            ? 'unfulfilled'
            : 'disabled',
      })),
    )
    .flat();
  const rowHeaders = iconStatus?.map((i) => ({
    id: i?.id, // id is skill alias
    headerContent: {
      fulfilled: <TickIcon />,
      unfulfilled: <ErrorIcon />,
      training: <TrainingIcon />,
    }[i?.status],
  }));

  return (
    <FdCortex
      specialties={specialtiesFormatted}
      rowHeaders={rowHeaders}
      legends={
        <Box mt={5} className="flex flex-col items-center">
          <FdTypography variant="captiontext2" color="secondary">
            Skills Fulfilment Icons Key
          </FdTypography>
          <Box
            mt={0.5}
            mb={3}
            className="flex items-center justify-center gap-x-3"
          >
            <LegendElement Icon={TickIcon} label="Fulfilled Skill (Current)" />
            <LegendElement
              Icon={TrainingIcon}
              label="Fulfilled Skill (Post-Training)"
            />
            <LegendElement
              Icon={ErrorIcon}
              label="Unfulfilled Skill (Post-Training)"
            />
          </Box>
          <FdTypography variant="captiontext2" color="secondary">
            Skill-Proficiency Square Key
          </FdTypography>
          <Box
            mt={0.5}
            mb={3}
            className="flex items-center justify-center gap-x-3"
          >
            <LegendElement
              Icon={TargetProficiency}
              label="Target Proficiency"
            />
            <LegendElement Icon={AssignedTraining} label="Assigned Training" />
            <LegendElement Icon={DemonstratedTraining} label="Demonstrated" />
            <LegendElement
              Icon={NotDemonstratedTraining}
              label="Not Demonstrated "
            />
          </Box>
        </Box>
      }
      loading={loading}
    />
  );
};

WorkRoleCortex.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default WorkRoleCortex;
