import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { Box, Card, CardContent } from '@material-ui/core';
import { FdTypography, AppThemeContext } from '@fifthdomain/fe-shared';
import getGraphTheme from '../../shared/utils/graphUtil';

const colorByFunc = ({ id }) => {
  switch (id) {
    case 'Easy':
      return '#81C784';
    case 'Medium':
      return '#FFB74D';
    case 'Hard':
      return '#E57373';
    default:
      return '#81C784';
  }
};

const CategoriesGraph = ({ data }) => {
  const theme = useContext(AppThemeContext);
  const graphTheme = getGraphTheme(theme);
  const textColor = graphTheme === 'dark' ? '#fff' : '#000';

  return (
    <Box mt={2}>
      <Card>
        <CardContent>
          <FdTypography variant="h4">Categories by Difficulty</FdTypography>
          <Box height="250px" width="100%">
            {data.length > 0 ? (
              <ResponsiveBar
                data={data}
                keys={['Easy', 'Medium', 'Hard']}
                indexBy="category"
                margin={{
                  top: 50,
                  right: 130,
                  bottom: 50,
                  left: 150,
                }}
                padding={0.3}
                layout="horizontal"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={colorByFunc}
                colorBy="id"
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendPosition: 'middle',
                }}
                enableGridY={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                enableLabel={false}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'row',
                    justify: false,
                    translateX: 120,
                    translateY: 30,
                    itemsSpacing: 0,
                    itemWidth: 60,
                    itemHeight: 20,
                    itemDirection: 'top-to-bottom',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    symbolShape: 'circle',
                    itemTextColor: textColor,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                animate
                motionStiffness={90}
                motionDamping={15}
                theme={{
                  textColor,
                  tooltip: {
                    container: {
                      color: textColor,
                      background: graphTheme === 'dark' ? '#000' : '#fff',
                    },
                  },
                }}
              />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <FdTypography variant="subtitle2">
                  Start adding challenges to see the difficulty progression.
                </FdTypography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

CategoriesGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      easy: PropTypes.number,
      medium: PropTypes.number,
      hard: PropTypes.number,
    }).isRequired,
  ).isRequired,
};

export default CategoriesGraph;
