import React from 'react';

const DemonstratedTraining = () => (
  <>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="11" height="11" fill="#5CEDCA" />
      <rect x="0.5" y="0.5" width="11" height="11" stroke="#4DD0E1" />
    </svg>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="11" height="11" fill="#5CEDCA" />
      <rect x="0.5" y="0.5" width="11" height="11" stroke="#DBE6FF" />
    </svg>
  </>
);

export default DemonstratedTraining;
