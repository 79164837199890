import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, makeStyles } from '@material-ui/core';
import { FdTypography, FdIconsV5 } from '@fifthdomain/fe-shared';

const useStyles = makeStyles(() => ({
  externalIcon: {
    padding: '12px 4px',
    borderRadius: '4px',
    maxHeight: '48px',
    backgroundColor: '#8B97B2',
  },
}));

const ExternalEvents = ({ externalData, affiliated }) => {
  const { Domain } = FdIconsV5;
  const classes = useStyles();
  return (
    <Box mb={2}>
      <Card variant="outlined" style={{ borderRadius: '8px', padding: 0 }}>
        <CardContent
          style={{
            padding: 0,
            height: '68px',
          }}
        >
          <Box className="flex items-center" style={{ height: '100%' }}>
            <Box className="flex items-center " height={68}>
              <Box display="flex">
                <Box ml={1}>
                  <Box className={classes.externalIcon}>
                    <Domain style={{ height: '18px' }} />
                  </Box>
                </Box>
                <Box className="flex flex-col justify-around ml-3">
                  <Box mb={0.4}>
                    <FdTypography variant="body2" fontWeight="medium">
                      {!affiliated
                        ? 'Externally Hosted Event(s)'
                        : `${externalData} x Externally Hosted Event(s)`}
                    </FdTypography>
                  </Box>
                  <Box>
                    <FdTypography variant="captiontext2">
                      {affiliated
                        ? `Affiliated users can be assigned to events hosted
                      externally from your organisation. Any skill and
                      performance data that affiliated users demonstrate in
                      externally hosted events will reflect in your view of
                      their profile.`
                        : `This user may have participated in events hosted externally from your organisation. Aside from FifthDomain Scores, you cannot see skill and performance data that non-affiliated users demonstrate outside from the context of your organisation.  
                      `}
                    </FdTypography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

ExternalEvents.propTypes = {
  externalData: PropTypes.number.isRequired,
  affiliated: PropTypes.bool,
};
ExternalEvents.defaultProps = {
  affiliated: false,
};

export default ExternalEvents;
