import React from 'react';
import PropTypes from 'prop-types';
import { Chip, useTheme } from '@material-ui/core';
import { initCaps } from '../../../shared/utils/stringUtils';
import { TEAM_ROLES } from '../../../constants';

const RoleChip = ({ role }) => {
  const isTeamManager = role === TEAM_ROLES.TEAM_MANAGER;
  const theme = useTheme();
  return (
    <Chip
      label={initCaps(role, true)}
      style={{
        backgroundColor: isTeamManager
          ? 'rgba(179, 229, 252, 1)'
          : theme?.palette?.type === 'dark'
          ? '#EFEFEF'
          : 'light-grey',
        marginLeft: '0.5rem',
        height: '24px',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 600,
      }}
    />
  );
};

RoleChip.propTypes = {
  role: PropTypes.string.isRequired,
};

export default RoleChip;
