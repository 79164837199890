import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import singleSpa from 'single-spa';
import { gql, useQuery } from '@apollo/client';
import {
  BasePage,
  FdLoadingSpinner,
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { Scoreboard } from '../components/Assessment';
import useGetAssessmentsTasks from '../hooks/useGetAssessmentsTasks';
import {
  getSystemTime,
  getAssessment,
  listScoreboardsByAssessmentId,
  listTaskAttemptAggregatesByAssessmentId,
} from '../graphql/queries';
import useStopAssessment from '../hooks/useStopAssessment';
import {
  onCreateNewTaskAttempt,
  onUpdateAssessment,
} from '../graphql/subscriptions';
import { getCompetitionStatus } from '../shared/utils/getParticipantStatus';
import useSubscription from '../hooks/useSubscription';
import FdSystemDateCountdown from '../components/FdSystemDateCountdown';

const AssessmentScoreboard = () => {
  const { assessmentId } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const [endDateTime, setEndDateTime] = useState(undefined);
  const { data: assessmentTasksData, loading: assessmentTasksLoading } =
    useGetAssessmentsTasks({ userId: globalSnap?.userId });

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  // get the assessment selected
  const assessmentDataFiltered =
    assessmentTasksData.listUserAssessments?.items.find(
      (ad) => ad.id === assessmentId,
    );

  // get Assessment EndDateTime
  const { loading: getAssessmentLoading } = useQuery(gql(getAssessment), {
    variables: {
      id: assessmentDataFiltered?.userAssessmentAssessmentId,
    },
    onCompleted: (data) => {
      setEndDateTime(data?.getAssessment?.endDateTime);
    },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
    fetchPolicy: 'network-only',
  });

  const {
    data: listTaskAttemptsData,
    loading: listTaskAttemptsLoading,
    refetch: refetchScoreboard,
  } = useQueryRecursive(gql(listScoreboardsByAssessmentId), {
    variables: {
      assessmentId: assessmentDataFiltered?.userAssessmentAssessmentId,
      limit: 2000,
    },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
    staleTime: { minutes: 0 },
  });

  useSubscription({
    query: gql(onCreateNewTaskAttempt),
    variables: {
      teamId: assessmentDataFiltered?.teamId,
      assessmentId: assessmentDataFiltered?.assessment?.id,
    },
    onData: (_result) => {
      const { success } = _result?.value?.data?.onCreateNewTaskAttempt || {
        success: false,
      };
      if (success) {
        refetchScoreboard();
      }
    },
  });

  const {
    data: listTaskAttemptAggregatesData,
    loading: listTaskAttemptAggregatesLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByAssessmentId), {
    variables: {
      assessmentId: assessmentDataFiltered?.userAssessmentAssessmentId,
      limit: 2000,
    },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
  });

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment(assessmentDataFiltered?.assessment?.participantEventType);

  useSubscription({
    query: gql(onUpdateAssessment),
    variables: {
      filter: {
        id: { eq: assessmentDataFiltered?.userAssessmentAssessmentId },
      },
    },
    onData: (_data) => {
      const _endDateTime = _data?.value?.data?.onUpdateAssessment?.endDateTime;
      setEndDateTime(_endDateTime);

      if (_endDateTime) {
        // call stop user assessment if endDate is present
        // backend will stop the assessment based on server date time
        stopAssessment({
          variables: {
            userAssessmentId: assessmentId,
          },
        });
      }
    },
  });

  if (
    assessmentTasksLoading ||
    serverTimeLoading ||
    listTaskAttemptsLoading ||
    listTaskAttemptAggregatesLoading ||
    stopAssessmentInProgress ||
    getAssessmentLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const {
    assessment: { name, startDateTime, guided },
  } = assessmentDataFiltered || { assessment: {} };

  const updatedCompetitionStatus = getCompetitionStatus(
    startDateTime,
    endDateTime,
    serverTime?.getSystemTime,
  );

  // redirect to homepage only if the competition is in not started state
  if (updatedCompetitionStatus === 'NOT_STARTED' || guided) {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  // all attempts
  const allAttempts =
    listTaskAttemptsData?.listScoreboardsByAssessmentId?.items?.filter(
      (a) => a?.points >= 0,
    ) || [];

  return (
    <BasePage
      heading={name}
      data-cy="scoreboard-page"
      breadCrumbs={[{ url: '/landing/landing-homepage', name: 'Home' }]}
      currentPageBreadcrumbLabel={name}
      renderBreadCrumbAsButton
      linkComponent={RouterLink}
    >
      {endDateTime && updatedCompetitionStatus !== 'ENDED' && (
        <FdSystemDateCountdown
          endDateTime={endDateTime}
          onFinish={() => {
            stopAssessment({
              variables: {
                userAssessmentId: assessmentId,
              },
            });
          }}
          renderer={({ completed }) => !completed && <></>}
        />
      )}

      <Box mb={2}>
        <Scoreboard
          assessmentData={{
            getAssessment: { ...assessmentDataFiltered?.assessment },
          }}
          allAttempts={allAttempts}
          attemptsTimeline={
            listTaskAttemptAggregatesData?.listTaskAttemptAggregatesByAssessmentId?.items?.filter(
              (a) => a.status === 'COMPLETED',
            ) || []
          }
          updatedCompetitionStatus={updatedCompetitionStatus}
          serverCurrentTime={serverTime?.getSystemTime}
        />
      </Box>
    </BasePage>
  );
};

export default AssessmentScoreboard;
