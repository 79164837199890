import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useQueryRecursive } from '@fifthdomain/fe-shared';
import { listChatForumsByAssessmentId } from '../../graphql/queries';
import ChatForum from '../Chats/ChatForum';
import useChatForumSubscription from '../../hooks/useChatForumSubscription';

const AdminChatForum = ({ assessment, isCompetitionEnded }) => {
  const { assessmentId, teamBased, teams: teamsData } = assessment;
  const {
    data: chatForumData,
    refetch: refetchChatForumData,
    loading: chatForumLoading,
  } = useQueryRecursive(gql(listChatForumsByAssessmentId), {
    variables: {
      assessmentId,
    },
    skip: !assessmentId,
  });

  const chatMessages = chatForumData?.listChatForumsByAssessmentId?.items || [];
  const teams = teamBased ? teamsData?.items : [];

  // refresh page query on chat forum subscription
  useChatForumSubscription({
    assessmentId,
    refreshData: refetchChatForumData,
  });

  return (
    <ChatForum
      chatMessages={chatMessages}
      assessmentId={assessmentId}
      viewOnly={isCompetitionEnded}
      teams={teams}
      loading={chatForumLoading}
      isAdminView
    />
  );
};

AdminChatForum.propTypes = {
  assessment: PropTypes.shape({
    assessmentId: PropTypes.string.isRequired,
    teamBased: PropTypes.bool.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  isCompetitionEnded: PropTypes.bool.isRequired,
};

export default AdminChatForum;
