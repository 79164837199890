import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import useAsyncEffect from 'use-async-effect';
import { gql, useMutation } from '@apollo/client';
import { Box, IconButton } from '@material-ui/core';
import {
  FdIcons,
  FdTypography,
  FdButton,
  FdTooltip,
  FdTextField,
  FdExternalLink,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import {
  errorToastMessage,
  successToastMessage,
} from '../../../shared/utils/toast';
import FdImageUpload from './FdImageUpload';
import { getPreSignedUrl } from '../../../shared/utils/getImageFromS3';
import { updateTeam } from '../../../graphql/mutations';
import { uploadFileToS3 } from '../../../shared/utils/fileUploadAWS';
import { scrollToBottom } from '../../../shared/utils/scroll';

const { InfoOutlined } = FdIcons;

const EditContainer = ({
  title,
  tooltip,
  labelOnly,
  children,
  hideEdit,
  field,
  onCancel,
  onSave,
  error,
  disabled,
}) => {
  const [editMode, setEditMode] = useState(false);
  return (
    <Box>
      <Box className="flex items-center justify-between mb-2">
        <Box className="flex items-center">
          <FdTypography variant="subtitle1">{title}</FdTypography>
          <FdTooltip title={tooltip}>
            <IconButton size="small">
              <InfoOutlined />
            </IconButton>
          </FdTooltip>
        </Box>
        {editMode ? (
          <Box className="flex space-x-2">
            <FdButton
              variant="secondary"
              onClick={() => {
                setEditMode(false);
                onCancel(field);
              }}
            >
              Cancel
            </FdButton>
            <FdButton
              variant="primary"
              onClick={() => {
                if (!error) {
                  setEditMode(false);
                  onSave(field);
                }
              }}
              disabled={disabled}
            >
              Save
            </FdButton>
          </Box>
        ) : (
          !hideEdit && (
            <FdButton
              variant="primary"
              onClick={() => {
                setEditMode(true);
                setTimeout(() => {
                  scrollToBottom();
                }, 0);
              }}
            >
              Edit
            </FdButton>
          )
        )}
      </Box>
      {editMode ? children : labelOnly}
    </Box>
  );
};

EditContainer.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  labelOnly: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideEdit: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const TeamDetails = ({ viewOnly, team, refetchTeam }) => {
  const [avatarUrl, setAvatarUrl] = useState(undefined);
  const { control, setValue, watch, resetField, trigger, getValues } =
    useFormContext();
  const watchAvatar = watch('avatar');
  const watchKey = watch('key');

  const [updateTeamMutation, { loading: updateTeamLoading }] = useMutation(
    gql(updateTeam),
  );

  useAsyncEffect(async () => {
    const fileUrlS3 = await getPreSignedUrl(`/team-avatars/${watchKey}`);
    setAvatarUrl(fileUrlS3);
  }, [watchKey]);

  const onCancelField = (field) => resetField(field);
  const onSaveField = async (field) => {
    const valid = await trigger([field]);
    if (valid) {
      const { name, avatar } = getValues();
      const avatarKey = avatar?.name
        ? `${name}/${avatar?.name}`.replace(/\s/g, '-').toLowerCase()
        : '';
      setValue('key', avatarKey);
      // update relevant field
      updateTeamMutation({
        variables: {
          input: {
            id: team?.id,
            ...(field === 'avatar'
              ? { key: avatarKey }
              : { [field]: getValues(field) }),
          },
        },
        onCompleted: () => {
          // if avatar present then upload
          if (field === 'avatar' && avatar?.name) {
            uploadFileToS3({
              key: `/team-avatars/${avatarKey}`,
              file: avatar,
              level: 'public',
            });
          }
          successToastMessage('Success! Team updated!');
          refetchTeam();
        },
        onError: ({ graphQLErrors }) => {
          onCancelField(field);
          errorToastMessage(graphQLErrors[0]?.message);
        },
      });
    }
  };

  return (
    <Box>
      <Box>
        <Controller
          control={control}
          name="name"
          render={({
            field: { ref, value, ...rest },
            fieldState: { error },
          }) => (
            <Box mb={2}>
              <EditContainer
                title="Team Name"
                tooltip="Your team's name is viewable by others when your team participates in team competitions on the platform. Team Managers can edit your team's name."
                labelOnly={<FdTypography variant="body2">{value}</FdTypography>}
                hideEdit={viewOnly}
                field="name"
                onCancel={onCancelField}
                onSave={onSaveField}
                error={error}
                disabled={updateTeamLoading}
              >
                <FdTextField
                  id="name"
                  required
                  fullWidth
                  error={error}
                  placeholder="Enter Team Name"
                  helperText={
                    error ? error.message : 'Enter a name for your team.'
                  }
                  value={value}
                  {...rest}
                  inputRef={ref}
                />
              </EditContainer>
            </Box>
          )}
        />
        <Box>
          <EditContainer
            title="Team Avatar"
            tooltip="Your team's avatar is viewable by others when your team participates in team competitions on the platform. Team Managers can edit your team's avatar."
            labelOnly={
              <Box display="flex">
                <FdTypography variant="body2">Team avatar file:</FdTypography>
                <Box ml={1} className="flex items-center text-sm">
                  {watchKey ? (
                    <FdSkeleton
                      loading={!avatarUrl}
                      height="10px"
                      width="500px"
                    >
                      <FdExternalLink href={avatarUrl}>
                        <FdTypography variant="body2">{watchKey}</FdTypography>
                      </FdExternalLink>
                    </FdSkeleton>
                  ) : (
                    <FdTypography variant="body2">None</FdTypography>
                  )}
                </Box>
              </Box>
            }
            hideEdit={viewOnly}
            field="avatar"
            onSave={onSaveField}
            onCancel={onCancelField}
            updateTeamLoading={updateTeamLoading}
          >
            <FdTypography variant="captiontext1" className="py-1">
              Upload a team avatar image file by clicking the Upload Image
              button below. Files greater than 2MB cannot be supported. JPEG,
              JPG, and PNG image file types are accepted. Avatar framing will be
              optimal for files with a square size ratio.
            </FdTypography>
            <Box mt={1} mb={3}>
              <FdImageUpload
                onDrop={(files) => {
                  setValue('avatar', files[0]);
                }}
                onDropRejected={(files) => {
                  setValue('avatar', files[0]?.file);
                  setValue('error', true);
                }}
                fileSelected={watchAvatar}
                onDelete={() => {
                  setValue('avatar', undefined);
                  setValue('error', false);
                }}
              />
            </Box>
          </EditContainer>
        </Box>
      </Box>
    </Box>
  );
};

TeamDetails.propTypes = {
  team: PropTypes.objectOf(PropTypes.shape({ name: PropTypes.string }))
    .isRequired,
  viewOnly: PropTypes.bool.isRequired,
  onDeletion: PropTypes.func.isRequired,
  refetchTeam: PropTypes.func.isRequired,
};

export default TeamDetails;
