import React from 'react';
import singleSpa from 'single-spa';
import { Box, Divider, useTheme } from '@material-ui/core';
import {
  FdTypography,
  FdCard,
  FdButton,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { TROPHY_TILES_BANNER } from '../../constants';
import TrophyBadge from './images/tiers/TrophyBadge';
import BadgeContainer from './BadgeContainer';
import TrophyRoomCardContent from './TrophyRoomCardContent';

const TrophyRoomCardParticipant = () => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const isDarkTheme = theme?.palette?.type === 'dark';

  return (
    <Box>
      <FdCard
        variant="outlined"
        media={
          <Box
            className="flex items-center justify-center "
            style={{
              height: '120px',
              background: isDarkTheme
                ? 'conic-gradient(from 90deg at 50% 50%, #170073 0deg, #050505 225.29deg, #170073 360deg)'
                : 'linear-gradient(89.99deg, #B6B0FF 1.46%, #D9D6FE 49.01%, #F0F8FF 99.99%)',
            }}
          >
            <Box className="flex items-center justify-center gap-x-10">
              {TROPHY_TILES_BANNER?.map((_type) => (
                <BadgeContainer size="small">
                  <TrophyBadge type={_type} tier={5} status="unlocked" />
                </BadgeContainer>
              ))}
            </Box>
          </Box>
        }
      >
        <Box className="flex justify-between mb-4">
          <FdTypography variant="h3">Trophy Room</FdTypography>
          <FdButton
            variant="secondary"
            onClick={() => singleSpa.navigateToUrl('/competitions/trophy-room')}
          >
            View
          </FdButton>
        </Box>
        <Box minHeight="36px">
          <FdTypography variant="captiontext1">
            Your Trophy Room displays the trophies you have obtained through
            various activities on the platform. Your five most recently obtained
            trophies are displayed below. Click &apos;View&apos; to navigate to
            your expanded Trophy Room.
          </FdTypography>
        </Box>
        <Box my={1}>
          <Divider />
        </Box>
        <Box mt={3}>
          <TrophyRoomCardContent userId={globalSnap?.userId} />
        </Box>
      </FdCard>
    </Box>
  );
};

export default TrophyRoomCardParticipant;
