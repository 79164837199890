import React, { useState, useEffect } from 'react';
import shortid from 'shortid';
import {
  Box,
  Drawer,
  makeStyles,
  IconButton,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import {
  FdTypography,
  FdIcons,
  FdMarkdownRender,
  FdButton,
  FdChip,
  FdTab,
  FdChips,
  FdTooltip,
  useSnapshot,
  globalStore,
  Authorization,
} from '@fifthdomain/fe-shared';
import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';
import FileAttachment from '../Participant/FileAttachment';
import setAppMarginRightByIds from '../../shared/utils/layout';
import { getTagColor } from '../../shared/utils/tagUtils';
import { sortByDateField } from '../../shared/utils/dateUtils';
import TaskDrawerHeader from './TaskDrawerHeader';

const drawerWidth = 400;

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  description: {
    wordWrap: 'break-word',
  },
}));

const ViewTaskDrawer = ({
  revealHintCallBack,
  editTasks,
  competitionStatus,
  activeTaskData,
  listHintRevealsData,
  openDrawer,
  openDrawerCallBack,
  mainPageIds,
}) => {
  const [activeTask, setActiveTask] = useState(activeTaskData);
  const [selectedHintIds, setSelectedHintIds] = useState([]);
  const classes = useStyles();
  const globalSnap = useSnapshot(globalStore);
  const { Close, EmojiObjects, InfoOutlined } = FdIcons;

  const hasManagePermission = Authorization.canManageEvents(
    globalSnap?.permissions,
  );

  const ContentWithLabel = ({ label, content }) => (
    <Box display="flex" alignItems="center" mb={2}>
      <Box width="120px">
        <FdTypography variant="subtitle1">{label}</FdTypography>
      </Box>
      {content}
    </Box>
  );

  ContentWithLabel.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };

  const LabelWithTooltip = ({ label, tooltipText }) => (
    <Box display="flex" alignItems="center">
      <FdTypography variant="subtitle1">{label}</FdTypography>
      <FdTooltip title={tooltipText}>
        <IconButton>
          <InfoOutlined />
        </IconButton>
      </FdTooltip>
    </Box>
  );
  LabelWithTooltip.propTypes = {
    label: PropTypes.string.isRequired,
    tooltipText: PropTypes.node.isRequired,
  };

  useEffect(() => {
    setActiveTask(activeTaskData);
  }, [activeTaskData]);

  useEffect(() => {
    setAppMarginRightByIds(mainPageIds, '400px');
    return () => {
      openDrawerCallBack(false);
      setAppMarginRightByIds(mainPageIds, '0');
    };
  }, [mainPageIds, openDrawerCallBack]);

  const tagsData = Array.isArray(activeTask?.tags)
    ? activeTask.tags
        .slice()
        .sort(sortByDateField('updatedAt', 'desc'))
        .map((t) => ({
          label: t.Tag?.name,
          color: t.Tag?.color && getTagColor(t.Tag?.color),
        }))
    : [];
  const owned = activeTask?.ownerOrgId === globalSnap.orgId;

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openDrawer}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Card
        style={{
          overflow: 'auto',
          height: '100%',
        }}
      >
        <CardHeader
          action={
            <IconButton
              onClick={() => {
                setAppMarginRightByIds(mainPageIds, '0');
                openDrawerCallBack(false);
              }}
            >
              <Close style={{ fontSize: 28 }} />
            </IconButton>
          }
          title={activeTask?.name}
        />
        <CardContent>
          <TaskDrawerHeader activeTask={activeTask} />
          <FdTab
            variant="fullWidth"
            label={[
              {
                label: 'Challenge Details',
                path: '/tasks',
                index: 0,
                data: () => (
                  <Box>
                    {activeTask?.summary && (
                      <Box mb={3} className={classes.description}>
                        <LabelWithTooltip
                          label="Summary"
                          tooltipText="Summary is viewable by admins/managers for quick reference and overview of the challenge for  use-cases such as challenge curation for events, leasing challenges etc."
                        />
                        <FdTypography variant="body1" color="secondary">
                          <ShowMoreText
                            more="See more"
                            less="See less"
                            anchorClass="see-more"
                          >
                            <FdMarkdownRender markdown={activeTask?.summary} />
                          </ShowMoreText>
                        </FdTypography>
                      </Box>
                    )}
                    {activeTask?.skills?.length > 0 && (
                      <Box mb={3}>
                        <LabelWithTooltip
                          label="Skills"
                          tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
                        />
                        <Box ml={4}>
                          <FdTypography variant="body1" color="secondary">
                            <ul style={{ listStyleType: 'disc' }}>
                              {activeTask?.skills.map((skill) => (
                                <li key={shortid.generate()}>
                                  {`${skill?.name} (${skill?.alias})`}
                                </li>
                              ))}
                            </ul>
                          </FdTypography>
                        </Box>
                      </Box>
                    )}
                    {activeTask?.techniqueTags?.length > 0 && (
                      <Box mb={3}>
                        <LabelWithTooltip
                          label="Techniques"
                          tooltipText=" Techniques refer to the specific methods or strategies required to resolve a challenge."
                        />
                        <FdChips
                          data={[...new Set(activeTask?.techniqueTags)]?.map(
                            (t) => ({
                              label: t,
                              color: 'default',
                            }),
                          )}
                        />
                      </Box>
                    )}
                    {activeTask?.technologyTags?.length > 0 && (
                      <Box mb={3}>
                        <LabelWithTooltip
                          label="Technologies"
                          tooltipText="Technologies include the environments (e.g., Windows, Linux, Docker) and tools (e.g., Splunk, Nessus, Python) incorporated within the challenge, crucial for its resolution."
                        />
                        <FdChips
                          data={activeTask?.technologyTags?.map((t) => ({
                            label: t,
                            color: 'default',
                          }))}
                        />
                      </Box>
                    )}
                    {tagsData?.length > 0 && (
                      <Box display="flex" flexDirection="column" mb={2}>
                        <Box display="flex" justifyContent="space-between">
                          <Box width="120px">
                            <FdTypography variant="subtitle1">
                              Tags
                            </FdTypography>
                          </Box>
                          <FdTypography variant="body2">
                            {tagsData?.length}
                          </FdTypography>
                        </Box>
                        <Box my={1}>
                          <FdChips data={tagsData} />
                        </Box>
                      </Box>
                    )}
                  </Box>
                ),
              },
              {
                label: 'Challenge Resources',
                path: '/tasks',
                index: 1,
                data: () => (
                  <Box>
                    {activeTask?.description && (
                      <Box mb={3} className={classes.description}>
                        <LabelWithTooltip
                          label="Description"
                          tooltipText="Description is viewable by participants for challenge instructions or other related details."
                        />
                        <FdTypography variant="body1" color="secondary">
                          <ShowMoreText
                            more="See more"
                            less="See less"
                            anchorClass="see-more"
                          >
                            <FdMarkdownRender
                              markdown={activeTask?.description}
                            />
                          </ShowMoreText>
                        </FdTypography>
                      </Box>
                    )}
                    {activeTask?.type === 'LAB' &&
                      activeTask?.labId &&
                      activeTask?.labId !== 'EMPTY' && (
                        <Box my={3}>
                          <FdTypography
                            variant="subtitle1"
                            data-cy="lab-heading"
                          >
                            Attached Lab
                          </FdTypography>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mt={1}
                            mb={2}
                          >
                            <FdTypography variant="body1" color="secondary">
                              {activeTask?.lab?.name}
                            </FdTypography>
                            {/* <FdButton
                              variant="secondary"
                              onClick={() =>
                                window.open(
                                  `/labs/view/${activeTask?.labId}`,
                                  '_blank',
                                )
                              }
                            >
                              View Lab
                            </FdButton> */}
                          </Box>
                        </Box>
                      )}
                    {activeTask?.files?.items?.length !== 0 && (
                      <Box mb={2}>
                        <FdTypography
                          variant="subtitle1"
                          data-cy="attachments-subtitle"
                        >
                          Attachments
                        </FdTypography>
                        {activeTask?.files?.items?.map((f) => (
                          <FileAttachment id={f.id} name={f.name} url={f.url} />
                        ))}
                      </Box>
                    )}
                    {activeTask?.hints?.items?.length !== 0 && (
                      <Box display="flex" flexDirection="column" mb={2}>
                        <FdTypography variant="subtitle1">Hints</FdTypography>
                        {editTasks && competitionStatus !== 'Ended' && (
                          <FdTypography variant="captiontext1">
                            Note: The hints once revealed can’t be hidden.
                          </FdTypography>
                        )}
                        <FdTypography variant="body1" color="secondary">
                          {activeTask?.hints?.items?.map((h, index) => (
                            <Box key={index}>
                              <Box display="flex" flexDirection="row" mt={1.5}>
                                <EmojiObjects />
                                <Box ml={1.5}>{h?.text}</Box>
                              </Box>
                              {editTasks && (
                                <Box mt={2} mb={2} ml={4}>
                                  {listHintRevealsData?.listHintReveals?.items?.find(
                                    (item) =>
                                      item?.hintId === h?.id &&
                                      item?.taskAssessmentId ===
                                        activeTask?.taskAssessmentId,
                                  ) ? (
                                    <FdChip
                                      color="default"
                                      size="small"
                                      label="Hint Revealed"
                                    />
                                  ) : (
                                    competitionStatus !== 'Ended' &&
                                    hasManagePermission && (
                                      <FdButton
                                        variant="secondary"
                                        size="small"
                                        disabled={selectedHintIds?.includes(
                                          h?.id,
                                        )}
                                        onClick={() => {
                                          revealHintCallBack(
                                            h?.id,
                                            activeTask?.taskAssessmentId,
                                          );
                                          setSelectedHintIds(
                                            selectedHintIds?.concat(h?.id),
                                          );
                                        }}
                                      >
                                        reveal hint
                                      </FdButton>
                                    )
                                  )}
                                </Box>
                              )}
                            </Box>
                          ))}
                        </FdTypography>
                      </Box>
                    )}

                    {owned && (
                      <ContentWithLabel
                        label="Flag"
                        content={
                          <FdTypography variant="body1" color="secondary">
                            {activeTask?.answer}
                          </FdTypography>
                        }
                      />
                    )}
                  </Box>
                ),
              },
            ]}
          />
        </CardContent>
      </Card>
    </Drawer>
  );
};

ViewTaskDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activeTaskData: PropTypes.object.isRequired,
  competitionStatus: PropTypes.string,
  openDrawer: PropTypes.bool.isRequired,
  openDrawerCallBack: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mainPageIds: PropTypes.array,
  revealHintCallBack: PropTypes.func,
  editTasks: PropTypes.bool,
  listHintRevealsData: PropTypes.shape({
    listHintReveals: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          hintId: PropTypes.string,
          taskAssessmentId: PropTypes.string,
        }),
      ),
    }),
  }),
};

ViewTaskDrawer.defaultProps = {
  mainPageIds: [],
  revealHintCallBack: () => {},
  editTasks: false,
  listHintRevealsData: {},
  competitionStatus: null,
};

export default ViewTaskDrawer;
