import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import * as InProgress from '../../resources/images/level-icons-black/in-progress';
import * as Completed from '../../resources/images/level-icons-black/completed';

const useStyles = makeStyles(() => ({
  iconImage: {
    position: 'absolute',
    top: '-5px',
    width: '147px',
    height: '68px',
  },
  iconImageBlack: {
    top: '-38px',
    width: '140px',
    height: '53px',
    objectFit: 'contain',
  },
  iconImageShadow: {
    filter: 'drop-shadow(0px 4px 10px rgba(255, 255, 255, 0.6))',
  },
  iconSolved: {
    opacity: 0.4,
  },
  nonSelected: {
    left: '-41px',
  },
  selected: {
    left: '-45px',
  },
}));

// default image when look up fails
const DefaultImage = Completed.Level16Completed;
const showDropShadowUptoLevel = 11;

const LevelIcon = ({ level, completed, selected }) => {
  const classes = useStyles();
  const LevelComponent =
    (completed
      ? Completed[`Level${level}Completed`]
      : InProgress[`Level${level}InProgress`]) || DefaultImage;

  return (
    <Box width="45px" height="56px">
      <img
        src={LevelComponent}
        className={`${classes.iconImageBlack} ${
          level <= showDropShadowUptoLevel && !completed
            ? classes.iconImageShadow
            : classes.iconSolved
        } ${selected ? classes.selected : classes.nonSelected}`}
        alt={level}
      />
    </Box>
  );
};

LevelIcon.propTypes = {
  level: PropTypes.number.isRequired,
  completed: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default React.memo(LevelIcon);
