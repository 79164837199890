import React from 'react';

const ErrorIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6.6C6.17 6.6 6.3126 6.5424 6.4278 6.4272C6.5426 6.3124 6.6 6.17 6.6 6L6.6 3.585C6.6 3.415 6.5426 3.275 6.4278 3.165C6.3126 3.055 6.17 3 6 3C5.83 3 5.6876 3.0574 5.5728 3.1722C5.4576 3.2874 5.4 3.43 5.4 3.6L5.4 6.015C5.4 6.185 5.4576 6.325 5.5728 6.435C5.6876 6.545 5.83 6.6 6 6.6ZM6 9C6.17 9 6.3126 8.9424 6.4278 8.8272C6.5426 8.7124 6.6 8.57 6.6 8.4C6.6 8.23 6.5426 8.0874 6.4278 7.9722C6.3126 7.8574 6.17 7.8 6 7.8C5.83 7.8 5.6876 7.8574 5.5728 7.9722C5.4576 8.0874 5.4 8.23 5.4 8.4C5.4 8.57 5.4576 8.7124 5.5728 8.8272C5.6876 8.9424 5.83 9 6 9ZM6 12C5.17 12 4.39 11.8424 3.66 11.5272C2.93 11.2124 2.295 10.785 1.755 10.245C1.215 9.705 0.7876 9.07 0.4728 8.34C0.1576 7.61 0 6.83 0 6C0 5.17 0.1576 4.39 0.4728 3.66C0.7876 2.93 1.215 2.295 1.755 1.755C2.295 1.215 2.93 0.7874 3.66 0.4722C4.39 0.1574 5.17 0 6 0C6.83 0 7.61 0.1574 8.34 0.4722C9.07 0.7874 9.705 1.215 10.245 1.755C10.785 2.295 11.2124 2.93 11.5272 3.66C11.8424 4.39 12 5.17 12 6C12 6.83 11.8424 7.61 11.5272 8.34C11.2124 9.07 10.785 9.705 10.245 10.245C9.705 10.785 9.07 11.2124 8.34 11.5272C7.61 11.8424 6.83 12 6 12Z"
      fill="#EF9A9A"
    />
  </svg>
);

export default ErrorIcon;
