import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Box,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import singleSpa from 'single-spa';
import { useHistory } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'react-toastify/dist/ReactToastify.css';
import { useMutation, gql, useQuery } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  FdButton,
  BasePage,
  BasePageButtonContainer,
  FdLoadingSpinner,
  FdAlert,
  FdModal,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdCard,
  Authorization,
} from '@fifthdomain/fe-shared';
import PropTypes from 'prop-types';
import ViewTaskDrawer from '../components/Assessment/ViewTaskDrawer';
import {
  Details,
  Availability,
  TasksTable,
  PreMessage,
  PostMessage,
  CompetitionType,
  Jumpbox,
  ScoreboardVisibility,
} from '../components/Assessment';
import {
  listTasksByOrgId,
  listTaskOrgs,
  getSystemTime,
  queryTemplatesByStatus,
} from '../graphql/queries';
import { createModulePart, createNewAssessment } from '../graphql/mutations';
import {
  getLabBasedTasks,
  getReleasedTemplatesChallenges,
} from '../shared/utils/taskUtils';
import { scrollToTop } from '../shared/utils/scroll';
import {
  ASSESSMENT_TYPES,
  AVAILABILITY_TYPES,
  defaultLabDuration,
} from '../constants';
import {
  successToastMessage,
  warningToastMessage,
} from '../shared/utils/toast';
import AssessmentTasksSummary from '../components/Assessment/AssessmentTasksSummary';
import { getCommaSeparated } from '../shared/utils/stringUtils';
import { getArrayByLength } from '../shared/utils/objectUtils';
import {
  validationSchema,
  initialValues,
} from '../validation-schemas/Competitions';
import { invalidateAdminHomePageDataQuery } from '../queries/invalidateQueries';
import { getDifficultyLabel } from '../shared/utils/difficultyMapping';
import { getTasksByAssessmentId as getAssessment } from '../queries/customQueries';
import AssessmentOverview from '../components/Assessment/AssessmentOverview';

const drawerWidth = 400;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  stepper: {
    background: 'none',
    paddingLeft: '5px',
  },
}));

const CreateAssessment = ({ formMode }) => {
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const globalSnap = useSnapshot(globalStore);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeTask, setActiveTask] = useState();
  const isDuplicateMode = formMode === 'duplicate';
  const { _assessmentId } = useParams();
  const hasManagePermission = Authorization.canManageEvents(
    globalSnap?.permissions,
  );

  const steps = ['Details', 'Challenges', 'Messaging', 'Summary'];

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  const [createNewAssessmentMutation, { loading: createLoading }] = useMutation(
    gql(createNewAssessment),
  );

  const { data: allTasksData, loading: allTasksLoading } = useQueryRecursive(
    gql(listTasksByOrgId),
    {
      variables: {
        orgId: globalSnap?.orgId,
        filter: {
          status: { eq: 'APPROVED' },
        },
        limit: 1000,
      },
      skip: !globalSnap?.orgId,
      onCompleted: () => scrollToTop(),
    },
  );
  const {
    data: allTasksAvailableToOrg,
    loading: allTasksAvailableToOrgLoading,
    refetch: allTasksAvailableRefetch,
  } = useQueryRecursive(gql(listTaskOrgs), {
    variables: {
      orgId: globalSnap.orgId,
      limit: 1000,
    },
    skip: !globalSnap?.orgId,
  });

  const { data: releasedTemplates, loading: templatesLoading } =
    useQueryRecursive(gql(queryTemplatesByStatus), {
      variables: {
        status: 'RELEASED',
      },
    });

  const releasedTemplatesChallenges =
    globalSnap.orgPricingTier === 'STARTER'
      ? []
      : getReleasedTemplatesChallenges(
          releasedTemplates?.queryTemplatesByStatus?.items,
        );

  const allTasksMadeAvailable =
    allTasksAvailableToOrg?.listTaskOrgs?.items?.map(
      (taskOrg) => taskOrg?.task,
    );

  const allTasks = _.uniqBy(
    [
      ...(allTasksData?.listTasksByOrgId?.items?.map((t) => ({
        ...t,
        owned: true,
      })) || []),
      ...(allTasksMadeAvailable?.map((t) => ({
        ...t,
        owned: false,
      })) || []),
      ...(releasedTemplatesChallenges?.map((challenge) => ({
        ...challenge,
        owned: challenge?.orgId === globalSnap?.orgId,
      })) || []),
    ],
    'id',
  ).filter((_task) => _task.type !== 'CONTAINER');

  const classes = useStyles();

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
    trigger,
    watch,
    handleSubmit,
  } = reactHookFormMethods;

  const watchTasks = watch('tasks');
  const watchAssessmentType = watch('assessmentType')?.value;
  const labControls = watch('labControls');

  // Get the competition details for duplicate
  const { loading: assessmentDataLoading } = useQuery(gql(getAssessment), {
    variables: {
      id: _assessmentId,
      hasPermission: hasManagePermission,
    },
    fetchPolicy: 'network-only',
    skip: !isDuplicateMode,
    onCompleted: (data) => {
      reset({
        assessmentType: data.getAssessment?.teamBased
          ? { id: 'assessmentType', value: ASSESSMENT_TYPES.TEAM_BASED }
          : {
              id: 'assessmentType',
              value: ASSESSMENT_TYPES.INDIVIDUAL_BASED,
            },
        multiLevel: data.getAssessment?.multiLevel || false,
        guided: data.getAssessment?.guided || false,
        scoreboardVisibility: !data?.getAssessment?.hideScoreBoard,
        levels: !data.getAssessment?.multiLevel
          ? null
          : data.getAssessment?.level,
        name: `Copy of ${data.getAssessment?.name}`,
        description: data.getAssessment?.description,
        availabilityType:
          data.getAssessment?.availabilityType === 'MANUAL'
            ? { id: 'availabilityType', value: AVAILABILITY_TYPES.MANUAL }
            : { id: 'availabilityType', value: AVAILABILITY_TYPES.AUTOMATIC },
        startDateTime: null,
        endDateTime: null,
        preMessage: data.getAssessment?.preMessage,
        videoUrl: data?.getAssessment?.videoUrl,
        postMessage: data.getAssessment?.postMessage,
        story: data.getAssessment?.story,
        tasks: data.getAssessment?.multiLevel
          ? data.getAssessment?.levels?.items
              ?.map((l) =>
                l.tasks.items?.map((t) => ({
                  level: l.levelNumber,
                  taskId: t.taskId,
                  taskAssessmentId: t.id,
                  task: t,
                })),
              )
              .flat() || []
          : data.getAssessment?.tasks.items?.map((task) => ({
              taskId: task.taskId,
              taskAssessmentId: task.id,
            })) || [],
        labControls: data.getAssessment.tasks.items
          ?.filter((assessmentTask) => assessmentTask.task.type === 'LAB')
          .reduce((acc, curr) => {
            const {
              duration,
              expiry,
              inactivityExpiry,
              initialLabCount,
              minLabCount,
            } = curr?.modulePart || {
              duration: defaultLabDuration,
              expiry: null,
              inactivityExpiry: 14,
              initialLabCount: 0,
              minLabCount: 0,
            };
            return {
              ...acc,
              [curr.taskId]: {
                labControlDefined: true,
                labDuration: duration,
                labExpiryDateTime: expiry,
                labAbandonment: inactivityExpiry,
                initialLabPool: initialLabCount,
                minimumLabPool: minLabCount,
              },
            };
          }, {}),
        taskLabs: data.getAssessment.tasks.items
          ?.filter((assessmentTask) => assessmentTask.task.type === 'LAB')
          .reduce((acc, curr) => {
            return {
              ...acc,
              [curr.taskId]: curr.modulePartId,
            };
          }, {}),
      });
    },
  });

  const [createModulePartMutation, { loading: createModulePartLoading }] =
    useMutation(gql(createModulePart));

  useEffect(() => {
    if (watchAssessmentType && !isDuplicateMode) {
      setValue(
        'postMessage',
        `The competition has ended. You can visit the scoreboard to see how other ${
          watchAssessmentType === ASSESSMENT_TYPES.TEAM_BASED
            ? 'teams'
            : 'participants'
        } went.`,
      );
    }
  }, [watchAssessmentType, setValue, isDuplicateMode]);

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger([
          'assessmentType',
          'multiLevel',
          'guided',
          'levels',
          'name',
          'description',
          'availabilityType',
          'startDateTime',
          'endDateTime',
          'maxTeamSize',
        ]);
        break;
      }
      case 1: {
        result = await trigger(['tasks']);
        if (result) {
          // show challenges toast on success
          const _tasks = getValues('tasks');
          const _multiLevel = getValues('multiLevel');
          const allLevels = getArrayByLength(
            Number(getValues('levels')),
            (x, i) => `Level ${i + 1}`,
          );
          const levelCount = allLevels.map((al, index) => ({
            level: al,
            count: _tasks.filter((t) => t.level === index + 1)?.length || 0,
          }));
          successToastMessage(
            <Box>
              <Typography variant="subtitle1">
                {`${_tasks.length} challenges added to the competition.`}
              </Typography>
              {_multiLevel &&
                levelCount.map((l) => (
                  <Box>
                    {`${l.count} challenge${l.count > 1 ? 's' : ''} to ${
                      l.level
                    }`}
                  </Box>
                ))}
            </Box>,
          );
        }
        break;
      }
      case 3:
        result = true;
        break;
      case 2:
        if (
          getValues().availabilityType.value === AVAILABILITY_TYPES.AUTOMATIC &&
          getValues().startDateTime === null
        ) {
          setValue('startDateTime', new Date().toISOString());
        }
        result = await trigger(['preMessage', 'postMessage']);
        break;
      default:
        break;
    }
    return result;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollToTop();
  };
  if (
    allTasksLoading ||
    serverTimeLoading ||
    assessmentDataLoading ||
    templatesLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const tableData =
    allTasks
      ?.map((task) => ({
        ...task,
        difficultyLabel: getDifficultyLabel(task?.difficulty),
        difficultyInteger: task?.difficulty,
        specialtyName: task?.specialty?.name,
        skills: task?.skills?.items.map((s) => s.skill),
        techniqueTags: task?.skills?.items
          .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
          .flat(),
        technologyTags: task?.technologyTags?.items.map(
          (t) => t.technologyTag?.name,
        ),
        creator: task?.user?.name,
        ownerOrg: task?.org?.name,
        ownerOrgId: task?.org?.id,
        creatorOrg: task?.user?.org?.name,
        creatorOrgId: task?.user?.org?.id,
        tags:
          task?.tags?.items?.filter(
            (t1) => t1?.Tag?.orgId === globalSnap?.orgId,
          ) || [],
      }))
      .filter((td) => td.id) || [];

  const taskIds = watchTasks?.map((t) => t.taskId) || [];
  const labBasedSelectedTasks = getLabBasedTasks(allTasks, taskIds).map(
    (labBasedTask) => ({
      ...labBasedTask,
      difficulty: getDifficultyLabel(labBasedTask?.difficulty),
      specialtyName: labBasedTask?.specialty?.name,
      skills: labBasedTask?.skills?.items.map((s) => s.skill?.name),
      status:
        labControls?.[labBasedTask.id] &&
        labControls?.[labBasedTask.id]?.labControlDefined
          ? 'Defined'
          : 'Undefined',
    }),
  );

  const onSubmit = async () => {
    const values = getValues();

    const teamBased =
      values.assessmentType.value === ASSESSMENT_TYPES.TEAM_BASED;
    const isCompetitionAutomatic =
      values.availabilityType.value === AVAILABILITY_TYPES.AUTOMATIC;

    if (!isCompetitionAutomatic) {
      values.startDateTime = null;
      values.endDateTime = null;
    }

    const startDateTime =
      isCompetitionAutomatic && values.startDateTime === null
        ? new Date(serverTime?.getSystemTime).toISOString()
        : values.startDateTime;

    const { endDateTime, jumpbox, enableVPN, scoreboardVisibility } = values;

    const isDev = ['ctf.local:5000', 'localhost:5000'].includes(
      document.location.host,
    );

    // segment track event
    /* eslint-disable no-undef */
    if (!isDev) {
      if (analytics) {
        analytics.track('COMPETITION_CREATED', {
          userId: globalSnap?.userId,
          orgId: globalSnap?.orgId,
        });
      }
    }

    const hasLabBasedTasks = labBasedSelectedTasks.length > 0;
    // api payload
    const apiTaskIds =
      values.multiLevel || hasLabBasedTasks
        ? []
        : values.tasks.map((t) => t.taskId);
    const apiTaskLevels = values.multiLevel
      ? getArrayByLength(values.levels, (x, i) => i + 1).map((l) => ({
          levelNumber: l,
          taskIds: values.tasks
            .filter((t) => t.level === l)
            .map((t) => t.taskId),
        }))
      : [];
    const apiLevels = Number(values.levels);

    // create module parts for lab based tasks
    const modulePartsPromises = labBasedSelectedTasks?.map(
      (labBasedTask, index) => {
        const { name, description, labId, id } = labBasedTask;
        return createModulePartMutation({
          variables: {
            input: {
              assessmentId: _assessmentId,
              courseModuleId: id,
              description,
              name,
              orderNumber: index + 1,
              type: 'LAB',
              labId,
            },
          },
        });
      },
    );

    const newMutationValues = { ...values };

    const availabilityType =
      newMutationValues.availabilityType.value.toUpperCase();
    // delete non-api values
    delete newMutationValues.assessmentType;
    delete newMutationValues.availabilityType;
    delete newMutationValues.tasks;
    delete newMutationValues.levels;
    delete newMutationValues.labControls;
    delete newMutationValues.scoreboardVisibility;
    delete newMutationValues.taskModuleParts;
    delete newMutationValues.jumpbox;
    delete newMutationValues.enableVPN;

    Promise.all(modulePartsPromises).then((modulePartsData) => {
      const modulePartIds = modulePartsData.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.data.createModulePart.courseModuleId]:
            curr.data.createModulePart.id,
        }),
        {},
      );

      createNewAssessmentMutation({
        variables: {
          assesssment: {
            ...newMutationValues,
            taskIds: hasLabBasedTasks ? [] : apiTaskIds,
            level: apiLevels,
            taskLevels: apiTaskLevels,
            taskLabs: hasLabBasedTasks
              ? taskIds.map((taskId) => ({
                  taskId,
                  modulePartId: modulePartIds[taskId],
                }))
              : [],
            startDateTime,
            status: 'READY',
            endDateTime,
            teamBased,
            enableJumpbox: jumpbox?.toString()?.toUpperCase() || 'FALSE',
            enableVPN: enableVPN?.toString()?.toUpperCase() || 'FALSE',
            orgId: globalSnap?.orgId,
            participantEventType: 'COMPETITION',
            hideScoreBoard: !scoreboardVisibility,
            availabilityType,
          },
        },
        onCompleted: (data) => {
          const assessmentId = data?.createNewAssessment;
          const _toastMessage = isDuplicateMode
            ? 'Success! Duplicate competition created'
            : 'Your competition has been successfully created';
          successToastMessage(_toastMessage);
          // eslint-disable-next-line no-use-before-define
          reset();
          invalidateAdminHomePageDataQuery({ orgId: globalSnap.orgId });
          singleSpa.navigateToUrl(
            // tabindex=3, load on participant tab
            `/competitions/view/${assessmentId}?tabindex=3`,
          );
        },
      });
    });
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 3) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  const viewTaskActions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const activeTaskData = tableData?.find((t) => t.id === id);
        setActiveTask(activeTaskData);
      },
    },
  ];
  const getIncompleteLevels = () =>
    getArrayByLength(Number(getValues('levels')), (x, i) => i + 1)
      .filter(
        (el) => ![...new Set(watchTasks?.map((t) => t.level))].includes(el),
      )
      .map((l) => `Level ${l}`);

  const levelErrorMessage = errors.tasks
    ? getCommaSeparated(getIncompleteLevels())
    : '';

  const BackButton = () => (
    <FdButton size="large" variant="secondary" onClick={handleBack}>
      Back
    </FdButton>
  );

  return (
    <BasePage
      heading={`${isDuplicateMode ? 'Duplicate' : 'Create'} Competition`}
      data-cy="create-assessment-base-page"
    >
      <Box width="834px">
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <FormProvider {...reactHookFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            {
              0: (
                <Box>
                  <CompetitionType isDuplicateMode={isDuplicateMode} />
                  <Details isDuplicateMode={isDuplicateMode} />
                  <Availability isDuplicateMode={isDuplicateMode} />
                  <Jumpbox />
                  <ScoreboardVisibility />
                </Box>
              ),
              1: (
                <>
                  <Box>
                    <Grid container>
                      <Grid item xs>
                        {isDuplicateMode && (
                          <Box mb={2}>
                            <FdCard
                              variant="outlined"
                              heading="Challenges"
                              subHeading="Each challenge from the competition to be duplicated is listed below. Use the checkboxes to select challenges to be included in the duplicate competition. Accompanying challenge content (e.g. labs, static files) will also be copied to the duplicate competition. Note that challenges that your organization no longer has access to will not be displayed below. "
                            />
                          </Box>
                        )}
                        <AssessmentOverview
                          allTasks={tableData}
                          tasks={watchTasks}
                        />
                        {errors.tasks && getIncompleteLevels().length > 0 && (
                          <Box mt={2}>
                            <FdAlert
                              alertTitle="No challenges assigned to Level"
                              variant="error"
                              message={`Please assign challenges to - ${levelErrorMessage}`}
                            />
                          </Box>
                        )}
                        {errors.tasks && !getValues('multiLevel') && (
                          <Box mt={2}>
                            <FdAlert
                              variant="error"
                              message="Please select challenges(s) for this competition"
                            />
                          </Box>
                        )}
                        <TasksTable
                          data={tableData}
                          viewTaskActions={viewTaskActions}
                          isRefreshLoading={allTasksAvailableToOrgLoading}
                          onRefreshTasks={() => allTasksAvailableRefetch()}
                          editTasks
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          width: openDrawer ? drawerWidth : 0,
                        }}
                      />
                    </Grid>
                  </Box>
                  {activeTask && openDrawer && (
                    <ViewTaskDrawer
                      activeTaskData={activeTask}
                      openDrawer={openDrawer}
                      openDrawerCallBack={setOpenDrawer}
                      mainPageIds={['topnav', 'competitions']}
                    />
                  )}
                </>
              ),
              2: (
                <Box>
                  <PreMessage />
                  <PostMessage />
                </Box>
              ),
              3: (
                <AssessmentTasksSummary
                  values={getValues()}
                  allTasks={tableData}
                />
              ),
            }[activeStep]
          }
          <BasePageButtonContainer>
            <Box
              display="flex"
              justifyContent={activeStep === 2 ? 'space-between' : 'flex-end'}
              width="100%"
            >
              <Box>{activeStep === 3 && <BackButton />}</Box>
              <Box>
                <FdButton
                  size="large"
                  variant="tertiary"
                  onClick={() => {
                    if (!isDirty) {
                      warningToastMessage(
                        'Changes to Competition are not saved',
                      );
                    }
                    singleSpa.navigateToUrl('/landing');
                  }}
                >
                  Cancel
                </FdButton>
                {activeStep !== 0 && activeStep < 3 && <BackButton />}
                <FdButton
                  size="large"
                  onClick={handleNext}
                  disabled={createLoading || createModulePartLoading}
                >
                  {createLoading || createModulePartLoading
                    ? 'Loading...'
                    : activeStep === steps.length - 1
                    ? 'Create Competition'
                    : 'Next'}
                </FdButton>
              </Box>
            </Box>
          </BasePageButtonContainer>
        </form>
      </FormProvider>
      <NavigationPrompt
        when={
          (currentLocation, nextLocation) =>
            (isDirty && currentLocation?.pathname && !nextLocation?.pathname) || // for refresh
            (isDirty && currentLocation?.pathname !== nextLocation?.pathname) // all other paths
        }
        afterCancel={() => {
          if (isDuplicateMode) {
            if (
              window.location.pathname !==
              `/competitions/duplicate/${_assessmentId}`
            ) {
              history.goBack();
            }
          } else if (window.location.pathname !== '/competitions/create') {
            history.goBack();
          }
        }}
        afterConfirm={() =>
          isDuplicateMode
            ? warningToastMessage('Duplicate competition not created')
            : warningToastMessage('Changes to competition are not saved')
        }
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title={
              isDuplicateMode
                ? 'Abandon Duplicate Creation?'
                : 'Are you sure you want to leave?'
            }
            description={
              isDuplicateMode
                ? 'Are you sure that you want to abandon creating this duplicate competition? Any inputs made during the process will be lost.'
                : 'You have unsaved changes. Click the Stay button to go back to the form and save your changes.'
            }
            confirm={isDuplicateMode ? 'Confirm' : 'Stay'}
            dismiss={isDuplicateMode ? 'Cancel' : 'Leave'}
            open
            onConfirm={isDuplicateMode ? onConfirm : onCancel}
            onDismiss={isDuplicateMode ? onCancel : onConfirm}
          />
        )}
      </NavigationPrompt>
    </BasePage>
  );
};

CreateAssessment.propTypes = {
  formMode: PropTypes.oneOf(['create', 'duplicate']).isRequired,
};

export default CreateAssessment;
