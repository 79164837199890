import _ from 'lodash';

// object: {}, value: any => returns any
export const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);

// object: {}, values: [any] => returns [any]
export const getKeysByValues = (object, values) =>
  Object.keys(object).filter((key) => values.includes(object[key]));

// getArrayByLength(3, `SlNo: ${i + 1}`) returns ['SlNo: 1', 'SlNo: 2', 'SlNo: 3']
export const getArrayByLength = (_length, formatFn = (__, i) => i) =>
  Array.from(Array(Number(_length)), formatFn);

// call it as [].sort(sortObjectArrayByField('abc','desc'))
export const sortObjectArrayByField = (field, order = 'asc') => {
  const sortOrder = order === 'desc' ? -1 : 1;

  return (a, b) => {
    const valA = _.get(a, field);
    const valB = _.get(b, field);

    if (valA < valB) {
      return -1 * sortOrder;
    }
    if (valA > valB) {
      return 1 * sortOrder;
    }
    return 0;
  };
};

export const getValueByKey = (_object, _value) =>
  Object.entries(_object).find(([key]) => key === _value)?.[1];

export const calculatePercentageDifference = (oldValue, newValue) => {
  const oldNumber = parseFloat(oldValue);
  const newNumber = parseFloat(newValue);

  // Check if the conversion was successful
  if (Number.isNaN(oldNumber) || Number.isNaN(newNumber)) {
    return 0; // Return zero if inputs cannot be converted to numbers
  }

  // Check if the oldNumber is not zero to avoid division by zero
  if (oldNumber === 0) {
    return 0; // Return zero if old value is zero
  }

  // Calculate the absolute difference between the new and old values
  const difference = newNumber - oldNumber;

  // Calculate the percentage difference
  const percentageDifference = (difference / oldNumber) * 100;

  return Math.round(percentageDifference);
};
