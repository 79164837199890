import React from 'react';

const TickIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.16 7.08L3.855 5.775C3.745 5.665 3.61 5.61 3.45 5.61C3.29 5.61 3.15 5.67 3.03 5.79C2.92 5.9 2.865 6.04 2.865 6.21C2.865 6.38 2.92 6.52 3.03 6.63L4.74 8.34C4.85 8.45 4.99 8.505 5.16 8.505C5.33 8.505 5.47 8.45 5.58 8.34L8.985 4.935C9.095 4.825 9.15 4.69 9.15 4.53C9.15 4.37 9.09 4.23 8.97 4.11C8.86 4 8.72 3.945 8.55 3.945C8.38 3.945 8.24 4 8.13 4.11L5.16 7.08ZM6 12C5.17 12 4.39 11.8424 3.66 11.5272C2.93 11.2124 2.295 10.785 1.755 10.245C1.215 9.705 0.7876 9.07 0.4728 8.34C0.1576 7.61 0 6.83 0 6C0 5.17 0.1576 4.39 0.4728 3.66C0.7876 2.93 1.215 2.295 1.755 1.755C2.295 1.215 2.93 0.7874 3.66 0.4722C4.39 0.1574 5.17 0 6 0C6.83 0 7.61 0.1574 8.34 0.4722C9.07 0.7874 9.705 1.215 10.245 1.755C10.785 2.295 11.2124 2.93 11.5272 3.66C11.8424 4.39 12 5.17 12 6C12 6.83 11.8424 7.61 11.5272 8.34C11.2124 9.07 10.785 9.705 10.245 10.245C9.705 10.785 9.07 11.2124 8.34 11.5272C7.61 11.8424 6.83 12 6 12Z"
      fill="#69F0AE"
    />
  </svg>
);

export default TickIcon;
