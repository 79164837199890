import { gql, useQuery } from '@apollo/client';
import { listAffliationsByUserId } from '../graphql/queries';

const useGetProfileLink = (userId) => {
  const { data, loading, error } = useQuery(gql(listAffliationsByUserId), {
    fetchPolicy: 'cache-first',
    skip: !userId,
    variables: { userId },
  });

  if (!userId || loading || error) return null;

  const affiliationDetails =
    data?.listAffliationsByUserId?.items?.find((a) => a?.status === 'ACTIVE') ||
    {};

  const profileLink =
    affiliationDetails?.status === 'ACTIVE'
      ? `/competitions/affiliated-view-user/${userId}`
      : `/competitions/non-affiliated-view-user/${userId}`;

  return profileLink;
};

export default useGetProfileLink;
