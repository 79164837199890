import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@material-ui/core';
import {
  FdTypography,
  FdSkeleton,
  FdCard,
  FdAvatarGroup,
  getAvatarName,
  getAvatarColor,
  FdIcons,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import TeamAvatar from '../Insights/TeamAvatar';
import RoleChip from './Management/RoleChip';
import { TEAM_ROLES } from '../../constants';

const TeamSummary = ({ team, loading }) => {
  const globalSnap = useSnapshot(globalStore);
  const { Person } = FdIcons;
  const teamMembers = [...(team?.members?.items || [])];
  const memberAvatars =
    teamMembers
      ?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt))
      ?.map((m) => {
        const avatarName = m?.User?.alias || '-';
        return {
          content: m?.User ? getAvatarName(avatarName) : <Person />,
          color: m?.User ? getAvatarColor(avatarName) : 'lightgrey',
        };
      }) || [];
  const role =
    teamMembers?.find((m) => m.userId === globalSnap.userId)?.role ||
    TEAM_ROLES.GENERAL_MEMBER;

  return (
    <FdSkeleton loading={loading} height={108}>
      <FdCard variant="outlined">
        <Box className="flex flex-col justify-between">
          <Box className="flex items-center">
            <TeamAvatar size="small" team={team} />
            <Box className="flex gap-x-4 ml-4 items-center" height="36px">
              <FdTypography variant="h3">{team?.name}</FdTypography>
              <Divider variant="vertical" />
              <FdAvatarGroup
                avatars={memberAvatars}
                size="small"
                max={5}
                data-cy="avatar-group"
                spacing="small"
              />
              <Divider variant="vertical" />
              <Box className="flex items-center">
                <FdTypography variant="captiontext1">
                  My Role Type:
                </FdTypography>
                <RoleChip role={role} />
              </Box>
            </Box>
          </Box>
          <Box mt={1.5}>
            <FdTypography variant="captiontext1" color="secondary">
              Welcome to this team&apos;s profile. Explore details about this
              team&apos;s skills and enrolled competitions in the &apos;Team
              Dashboard&apos; tab. To view this team&apos;s members and details,
              click the &apos;Team Management&apos; tab.
            </FdTypography>
          </Box>
        </Box>
      </FdCard>
    </FdSkeleton>
  );
};

TeamSummary.propTypes = {
  loading: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    name: PropTypes.string,
    members: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default TeamSummary;
