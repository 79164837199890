import React from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Card, Box, CardContent } from '@material-ui/core';
import { FdSkeleton } from '@fifthdomain/fe-shared';
import SummaryTitle from './SummaryTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
  },
  card: {
    width: '100%',
    paddingTop: '4px',
    boxSizing: 'border-box',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: '8px',
    },
  },
  boxContent: {
    '&:last-child': {
      paddingBottom: '16px',
    },
  },
  cardWithBorder: {
    width: '100%',
    paddingTop: '4px',
    paddingBottom: 0,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.table.border}`,
  },
  itemCard: {
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.table.border}`,
    },
  },
}));

const Summary = ({
  data,
  titleVariant,
  subtitleVariant,
  justifyContent,
  noBorder,
  noBorderBox,
  loading,
}) => {
  const classes = useStyles();
  const ParentWrapper = noBorderBox ? Box : Card;
  const ChildWrapper = noBorderBox ? Box : CardContent;

  return (
    <ParentWrapper
      variant="outlined"
      className={
        noBorder || noBorderBox ? classes.card : classes.cardWithBorder
      }
    >
      <ChildWrapper
        className={
          noBorder || noBorderBox ? classes.cardContent : classes.boxContent
        }
      >
        <Grid container className={classes.root}>
          {data.map((d) => (
            <Grid item xs className={classes.itemCard} key={shortid.generate()}>
              <Grid container justifyContent="center">
                <FdSkeleton
                  loading={loading}
                  animation="wave"
                  height={60}
                  width={200}
                >
                  <SummaryTitle
                    data={d}
                    titleVariant={titleVariant}
                    subtitleVariant={subtitleVariant}
                    justifyContent={justifyContent}
                    loading={loading}
                  />
                </FdSkeleton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </ChildWrapper>
    </ParentWrapper>
  );
};

Summary.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]),
      description: PropTypes.string,
    }).isRequired,
  ).isRequired,
  titleVariant: PropTypes.string,
  subtitleVariant: PropTypes.string,
  justifyContent: PropTypes.string,
  noBorder: PropTypes.bool,
  noBorderBox: PropTypes.bool,
  loading: PropTypes.bool,
};

Summary.defaultProps = {
  titleVariant: 'h3',
  subtitleVariant: 'subtitle1',
  justifyContent: 'center',
  noBorder: false,
  noBorderBox: false,
  loading: false,
};

export default Summary;
