export const getTasksByAssessmentId = /* GraphQL */ `
  query GetTasksByAssessmentId($id: ID!, $hasPermission: Boolean!) {
    getAssessment(id: $id) {
      id
      orgId
      name
      description
      preMessage
      postMessage
      startDateTime
      endDateTime
      hideScoreBoard
      hours
      minutes
      teamBased
      multiLevel
      guided
      story
      level
      availabilityType
      status
      maxTeamSize
      enableJumpbox
      videoUrl
      enableVPN
      levels {
        items {
          id
          levelNumber
          tasks {
            items {
              taskId
              modulePartId
              modulePart {
                name
                description
                courseModuleId
                type
                duration
                expiry
                inactivityExpiry
                initialLabCount
                minLabCount
                deletionMode
                labId
              }
              id
              task {
                id
                name
                category
                skills {
                  items {
                    skillId
                    skill {
                      id
                      name
                      alias
                    }
                    techniqueTags {
                      items {
                        techniqueTag {
                          name
                        }
                      }
                    }
                  }
                }
                technologyTags {
                  items {
                    technologyTag {
                      name
                    }
                  }
                }
                type
                recommendedPoints
                answer @include(if: $hasPermission)
                description
                summary
                difficulty
                estimatedSolveTime
                specialtyId
                specialty {
                  name
                  skills {
                    items {
                      id
                      name
                      alias
                    }
                  }
                }
                userId
                user {
                  id
                  name
                  org {
                    name
                    id
                  }
                }
                competencies {
                  items {
                    competency {
                      id
                    }
                  }
                }
                hints {
                  items {
                    id
                    taskId
                    text
                  }
                }
                tags {
                  items {
                    Tag {
                      color
                      id
                      name
                      orgId
                    }
                    updatedAt
                  }
                }
                files {
                  items {
                    name
                    url
                    type
                  }
                }
                labId
                lab {
                  id
                  name
                  vms {
                    items {
                      id
                    }
                  }
                  createdAt
                  updatedAt
                }
                org {
                  id
                  name
                }
              }
            }
          }
        }
      }
      tasks {
        items {
          id
          taskId
          task {
            id
            name
            category
            type
            recommendedPoints
            answer @include(if: $hasPermission)
            description
            difficulty
            estimatedSolveTime
            specialtyId
            specialty {
              id
              name
            }
            skills {
              items {
                skillId
                skill {
                  id
                  name
                  alias
                }
              }
            }
            userId
            user {
              id
              name
              org {
                name
                id
              }
            }
            competencies {
              items {
                competency {
                  id
                }
              }
            }
            hints {
              items {
                id
                taskId
                text
              }
            }
            tags {
              items {
                Tag {
                  color
                  id
                  name
                  orgId
                }
                updatedAt
              }
            }
            files {
              items {
                name
                url
                type
              }
            }
            org {
              id
              name
            }
            labId
            lab {
              id
              name
              vms {
                items {
                  id
                }
              }
              createdAt
              updatedAt
            }
          }
          modulePartId
          modulePart {
            name
            description
            courseModuleId
            type
            duration
            expiry
            inactivityExpiry
            initialLabCount
            minLabCount
            deletionMode
            labId
          }
          hintReveals {
            items {
              id
              hintId
              taskAssessmentId
            }
          }
        }
      }
      teams {
        items {
          teamId
          id
          status
          team {
            name
            key
            members {
              items {
                userId
              }
            }
          }
        }
      }
    }
  }
`;

export const getAssessmentWithTasks = /* GraphQL */ `
  query ListUserAssessmentsWithTasks(
    $userId: ID!
    $nextToken: String
    $limit: Int
  ) {
    listUserAssessments(
      filter: { userId: { eq: $userId } }
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        userId
        user {
          id
          orgId
        }
        id
        status
        startedOn
        userAssessmentAssessmentId
        groupId
        teamId
        status
        finishedOn
        createdAt
        updatedAt
        assessment {
          id
          name
          minutes
          hours
          endDateTime
          startDateTime
          postMessage
          preMessage
          videoUrl
          hideScoreBoard
          teamBased
          multiLevel
          guided
          story
          level
          enableJumpbox
          enableVPN
          participantEventType
          status
          levels {
            items {
              id
              levelNumber
              tasks {
                items {
                  taskId
                  modulePartId
                  modulePart {
                    name
                    description
                    type
                    duration
                    expiry
                    inactivityExpiry
                    initialLabCount
                    minLabCount
                    labId
                    status
                    id
                  }
                  id
                  task {
                    id
                    name
                    recommendedPoints
                    estimatedSolveTime
                    difficulty
                    description
                    category
                    specialty {
                      name
                    }
                    skills {
                      items {
                        skillId
                        skill {
                          name
                          alias
                        }
                      }
                    }
                    solutionVideo
                    solutionSteps
                    org {
                      id
                      name
                      createdAt
                      updatedAt
                    }
                    labId
                    modulePartId
                    modulePart {
                      name
                      description
                      type
                      duration
                      expiry
                      inactivityExpiry
                      initialLabCount
                      minLabCount
                      labId
                      status
                      id
                    }
                    hints {
                      items {
                        id
                        taskId
                        text
                      }
                    }
                    files {
                      items {
                        id
                        name
                        url
                        type
                      }
                    }
                  }
                }
              }
            }
          }
          tasks {
            items {
              task {
                id
                name
                recommendedPoints
                estimatedSolveTime
                difficulty
                description
                solutionVideo
                solutionSteps
                category
                specialty {
                  name
                }
                skills {
                  items {
                    skillId
                    skill {
                      name
                      alias
                    }
                  }
                }
                labId
                modulePartId
                modulePart {
                  name
                  description
                  type
                  duration
                  expiry
                  inactivityExpiry
                  initialLabCount
                  minLabCount
                  labId
                  status
                  id
                }
                hints {
                  items {
                    id
                    taskId
                    text
                  }
                }
                files {
                  items {
                    id
                    name
                    url
                    type
                  }
                }
                org {
                  name
                }
              }
              id
              modulePartId
              modulePart {
                name
                description
                type
                duration
                expiry
                inactivityExpiry
                initialLabCount
                minLabCount
                labId
                status
                id
              }
            }
          }
          teams {
            items {
              teamId
              status
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getTasksAttempts = /* GraphQL */ `
  query ListTasksAttempts(
    $taskAttemptUserAssessmentId: ID!
    $nextToken: String
    $limit: Int
  ) {
    listTaskAttempts(
      filter: {
        taskAttemptUserAssessmentId: { eq: $taskAttemptUserAssessmentId }
      }
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        id
        userId
        success
        firstBlood
        taskAttemptUserAssessmentId
        groupId
        task {
          id
          name
          recommendedPoints
        }
      }
      nextToken
    }
  }
`;

export const getTasksAttemptsByAssessmentId = /* GraphQL */ `
  query ListTasksAttemptsByAssessmentId(
    $assessmentId: ID!
    $nextToken: String
    $limit: Int
  ) {
    listTaskAttemptsByAssessmentId(
      assessmentId: $assessmentId
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        id
        userId
        success
        firstBlood
        taskAttemptUserAssessmentId
        teamId
        task {
          id
          name
          recommendedPoints
        }
        userAssessment {
          user {
            id
            alias
          }
        }
        createdAt
      }
      nextToken
    }
  }
`;

export const getAllTasks = /* GraphQL */ `
  query listTasks($limit: Int, $nextToken: String) {
    listTasks(
      filter: { status: { ne: DRAFT } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        difficulty
        estimatedSolveTime
        description
        hints {
          items {
            id
            taskId
            text
          }
        }
        tags {
          items {
            Tag {
              color
              id
              name
              orgId
            }
            updatedAt
          }
        }
        status
        recommendedPoints
        name
        id
        category
        answer
        files {
          items {
            name
            type
            url
            size
          }
        }
      }
      nextToken
    }
  }
`;

// providing assessment id, return taskID and ID from taskassessment table.
export const getTaskAssessmentIdByAssessment = /* GraphQL */ `
  query getTaskAssessmentIdByAssessment($id: ID!) {
    getAssessment(id: $id) {
      tasks {
        items {
          id
          taskId
        }
      }
    }
  }
`;

export const getTaskAttemptsAssessment = /* GraphQL */ `
  query ListTaskAttemptsAssessment($assessmentId: ID!, $limit: Int) {
    listTaskAttempts(
      filter: { assessmentId: { eq: $assessmentId } }
      limit: $limit
    ) {
      items {
        success
        createdAt
        userId
        task {
          id
          name
          difficulty
          category
          recommendedPoints
          estimatedSolveTime
        }
      }
    }
  }
`;

export const listTaskAttempts = /* GraphQL */ `
  query ListTaskAttempts(
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttempts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          solution
          answer
          hints {
            nextToken
          }
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
          user {
            name
            id
          }
          assessment {
            name
            hours
            minutes
            tasks {
              items {
                taskId
              }
            }
          }
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTaskAttemptsViewAssessment = /* GraphQL */ `
  query ListTaskAttemptsViewAssessment(
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttempts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          summary
          externalId
          category
          specialty {
            name
          }
          difficulty
          recommendedPoints
          estimatedSolveTime
          solution
          answer
          userId
          status
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
          user {
            id
            alias
            email
            name
          }
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTaskAttemptsScoreboard = /* GraphQL */ `
  query ListTaskAttemptsScoreboard(
    $assessmentId: ID!
    $nextToken: String
    $limit: Int
  ) {
    listTaskAttempts(
      filter: { assessmentId: { eq: $assessmentId } }
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          hints {
            nextToken
          }
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
          user {
            id
            alias
          }
          assessment {
            name
            hours
            minutes
            tasks {
              items {
                taskId
              }
            }
          }
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listGroupsByAssessmentId = /* GraphQL */ `
  query ListGroupsByAssessmenId(
    $assessmentId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByAssessmenId(
      assessmentId: $assessmentId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        group {
          name
          id
          users {
            items {
              userId
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listGroupsUsersByAssessmenId = /* GraphQL */ `
  query ListGroupsUsersByAssessmenId(
    $assessmentId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByAssessmenId(
      assessmentId: $assessmentId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
          users {
            items {
              user {
                id
                name
                email
                alias
              }
            }
          }
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listHintReveals = /* GraphQL */ `
  query ListHintReveals(
    $filter: ModelHintRevealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHintReveals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hintId
        taskAssessmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAllFinishedAssessments = /* GraphQL */ `
  query ListAllFinishedAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        participantEventType
        users {
          items {
            id
            userId
            userAssessmentAssessmentId
            groupId
            status
            startedOn
            finishedOn
            createdAt
            updatedAt
            user {
              id
              email
              type
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listUserAssessmentsForRouter = /* GraphQL */ `
  query ListUserAssessments(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          type
          name
        }
        assessment {
          id
          orgId
          creator {
            org {
              name
            }
          }
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          status
          tasks {
            items {
              id
              task {
                id
                recommendedPoints
                attempts(filter: { success: { eq: true } }) {
                  items {
                    id
                    success
                    assessmentId
                    taskAttemptUserAssessmentId
                    taskAttemptTaskId
                    task {
                      recommendedPoints
                    }
                  }
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCourseUsersByOrgId = /* GraphQL */ `
  query listCourseUsersByOrgId($orgId: ID!) {
    listCourseUsersByOrgId(orgId: $orgId) {
      items {
        user {
          id
          email
          name
          type
        }
        courseId
        course {
          name
        }
      }
    }
  }
`;
export const listTeamsByEventId = /* GraphQL */ `
  query ListTeamsByEventId(
    $eventId: ID!
    $teamId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamsByEventId(
      eventId: $eventId
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        teamId
        team {
          id
          name
          description
          createdAt
          updatedAt
          members {
            items {
              User {
                id
                name
                alias
              }
              role
              id
            }
          }
        }
        eventId
        event {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserAssessmentsLandingPage = /* GraphQL */ `
  query listUserAssessmentsByUserId(
    $userId: ID!
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessmentsByUserId(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessment {
          id
          orgId
          creator {
            org {
              name
            }
          }
          name
          description
          status
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          createdBy
          participantEventType
          multiLevel
          tasks {
            items {
              id
            }
          }
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAssessmentForProgress = /* GraphQL */ `
  query GetUserAssessment($id: ID!) {
    getUserAssessment(id: $id) {
      id
      assessment {
        id
        multiLevel
        levels {
          items {
            tasks {
              items {
                id
                task {
                  id
                  recommendedPoints
                  attempts(filter: { success: { eq: true } }) {
                    items {
                      id
                      success
                      assessmentId
                      taskAttemptUserAssessmentId
                      taskAttemptTaskId
                      task {
                        recommendedPoints
                      }
                    }
                  }
                }
              }
            }
          }
        }
        tasks {
          items {
            id
            task {
              id
              recommendedPoints
              attempts(filter: { success: { eq: true } }) {
                items {
                  id
                  success
                  assessmentId
                  taskAttemptUserAssessmentId
                  taskAttemptTaskId
                  task {
                    recommendedPoints
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      key
      private
      members {
        items {
          id
          role
          userId
          User {
            id
            name
            alias
          }
          createdAt
        }
      }
      events {
        items {
          eventId
          event {
            name
            startDateTime
            status
            endDateTime
            availabilityType
            participantEventType
            multiLevel
            levels {
              items {
                tasks {
                  items {
                    task {
                      recommendedPoints
                    }
                  }
                }
              }
            }
            tasks {
              items {
                task {
                  recommendedPoints
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const listUserAssessmentsByTeamId = /* GraphQL */ `
  query ListUserAssessmentsByTeamId(
    $teamId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessmentsByTeamId(
      teamId: $teamId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userAssessmentAssessmentId
        groupId
        teamId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
        assessment {
          id
          hours
          minutes
          multiLevel
          levels {
            items {
              tasks {
                items {
                  task {
                    recommendedPoints
                  }
                }
              }
            }
          }
          tasks {
            items {
              task {
                recommendedPoints
              }
            }
          }
        }
      }
      nextToken
      __typename
    }
  }
`;
export const listTaskAttemptAggregates = /* GraphQL */ `
  query ListTaskAttemptAggregates(
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        status
        attempts
        userId
        points
        task {
          id
          specialtyId
          skills {
            items {
              skillId
              skill {
                id
                name
                alias
              }
            }
          }
          difficulty
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserAssessments = /* GraphQL */ `
  query ListUserAssessmentsByTeamId(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        userAssessmentAssessmentId
        groupId
        teamId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTaskOpenedsByUserAssessmentId = /* GraphQL */ `
  query ListTaskOpenedsByUserAssessmentId(
    $taskOpenedUserAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpenedsByUserAssessmentId(
      taskOpenedUserAssessmentId: $taskOpenedUserAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        taskOpenedUserAssessmentId
        assessmentId
        levelId
        startedSolving
        startedSolvingAt
        createdAt
        updatedAt
        taskOpenedTaskId
      }
      nextToken
    }
  }
`;
export const listUsersByAssessmentId = /* GraphQL */ `
  query ListUsersByAssessmentId(
    $userAssessmentAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByAssessmentId(
      userAssessmentAssessmentId: $userAssessmentAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        teamId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCustomerWorkRolesByOrgId = /* GraphQL */ `
  query ListCustomerWorkRolesByOrgId(
    $orgId: ID!
    $filter: ModelCustomerWorkroleFilterInput
    $limit: Int
    $sortDirection: ModelSortDirection
    $nextToken: String
  ) {
    listCustomerWorkRolesByOrgId(
      orgId: $orgId
      filter: $filter
      limit: $limit
      sortDirection: $sortDirection
      nextToken: $nextToken
    ) {
      items {
        id
        name
        alias
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAffliationsByUserId = /* GraphQL */ `
  query ListAffliationsByUserId(
    $filter: ModelUserOrgAffliationFilterInput
    $limit: Int
    $nextToken: String
    $orgId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $userId: ID!
  ) {
    listAffliationsByUserId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      orgId: $orgId
      sortDirection: $sortDirection
      userId: $userId
    ) {
      items {
        id
        customerWorkroleID
        customerWorkrole {
          id
          name
          alias
        }
      }
      nextToken
      __typename
    }
  }
`;

export const listFdSkillsByCustomerWorkRoleId = /* GraphQL */ `
  query ListFdSkillsByCustomerWorkRoleId(
    $filter: ModelCustomerSkillFdSkillFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $customerWorkroleId: ID!
  ) {
    listFdSkillsByCustomerWorkRoleId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      customerWorkroleId: $customerWorkroleId
    ) {
      items {
        id
        skillId
        skill {
          id
          name
          alias
        }
        customerWorkroleId
      }
      nextToken
      __typename
    }
  }
`;

export const listCustomerSkillsByCustomerWorkRoleId = /* GraphQL */ `
  query ListCustomerSkillsByCustomerWorkRoleId(
    $customerWorkroleId: ID!
    $filter: ModelCustomerSkillWorkroleFilterInput
    $limit: Int
    $sortDirection: ModelSortDirection
    $nextToken: String
  ) {
    listCustomerSkillsByCustomerWorkRoleId(
      customerWorkroleId: $customerWorkroleId
      filter: $filter
      limit: $limit
      sortDirection: $sortDirection
      nextToken: $nextToken
    ) {
      items {
        id
        customerSkillId
        difficulty
      }
      nextToken
    }
  }
`;

export const listCustomerSkillFdSkills = /* GraphQL */ `
  query ListCustomerSkillFdSkills(
    $filter: ModelCustomerSkillFdSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerSkillFdSkills(
      filter: $filter
      limit: $limit

      nextToken: $nextToken
    ) {
      items {
        id
        skillId
        skill {
          id
          name
          alias
        }
        customerWorkroleId
        customerSkillId
      }
      nextToken
    }
  }
`;

export const listTaskAttemptAggregatesByUserId = /* GraphQL */ `
  query ListTaskAttemptAggregatesByUserId(
    $userId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregatesByUserId(
      userId: $userId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        assessmentId
        points
        status
        attempts
        task {
          id
          specialtyId
          specialty {
            id
            name
          }
          skills {
            items {
              skillId
              skill {
                id
                name
                alias
              }
            }
          }
          difficulty
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listBadgeByUserId = /* GraphQL */ `
  query ListBadgeByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserBadgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBadgeByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessment {
          teamBased
          name
          creator {
            org {
              name
            }
          }
        }
        userId
        badgeId
        points
        badge {
          id
          badgeType
          name
          level
          noOfSkillCoverageToAcquireBadge
          specialty {
            name
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCustomerSkillsWorkrolesByOrgId = /* GraphQL */ `
  query ListCustomerSkillsWorkrolesByOrgId(
    $orgId: ID!
    $filter: ModelCustomerSkillWorkroleFilterInput
    $limit: Int
    $sortDirection: ModelSortDirection
    $nextToken: String
  ) {
    listCustomerSkillsWorkrolesByOrgId(
      orgId: $orgId
      filter: $filter
      limit: $limit
      sortDirection: $sortDirection
      nextToken: $nextToken
    ) {
      items {
        id
        customerWorkroleId
        difficulty
        customerSkillId
        orgId
        customerSkill {
          id
          name
        }
      }
      nextToken
    }
  }
`;
