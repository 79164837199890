import React from 'react';
import PropTypes from 'prop-types';
import { Box, Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';
import LabelWithTooltip from '../LabelWithTooltip';
import {
  ErrorIcon,
  TargetProficiency,
  TickIcon,
  DemonstratedIcon,
  NotDemonstratedTraining,
} from '../../Training/icons';

const LegendElement = ({ Icon, label }) => (
  <Box className="flex items-center justify-center gap-x-2">
    <Icon />
    <FdTypography variant="captiontext2">{label}</FdTypography>
  </Box>
);

LegendElement.propTypes = {
  Icon: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
};

const OverlayAssignedWR = ({ overlayWorkrole, setOverlayWorkRole }) => {
  return (
    <Box className="flex justify-center">
      <Box display="flex" flexDirection="column">
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={overlayWorkrole}
                onChange={(e) => {
                  setOverlayWorkRole(e?.target?.checked);
                }}
              />
            }
            label={
              <FdTypography variant="captiontext2" color="secondary">
                Overlay Assigned Work Role
              </FdTypography>
            }
          />
        </FormGroup>
        <Box display="flex" my={1}>
          <LabelWithTooltip
            variant="captiontext2"
            label="Skills Fulfilment Icons Key"
            tooltipText="Skills fulfilment icons are visible when you toggle on the overlay of a user’s assigned work role."
          />
        </Box>

        <Box display="flex">
          <LegendElement Icon={TickIcon} label="Fulfilled Skill (Current)" />
          <Box ml={1.5}>
            <LegendElement
              Icon={ErrorIcon}
              label="Unfulfilled Skill (Current)"
            />
          </Box>
        </Box>

        <Box display="flex" my={1}>
          <LabelWithTooltip
            variant="captiontext2"
            label="Skill-Proficiency Square Key"
            tooltipText="Skill-Proficiency square borders are visible when you toggle on the overlay of a user’s assigned work role."
          />
        </Box>

        <Box display="flex">
          <LegendElement Icon={TargetProficiency} label="Target Proficiency" />
          <Box mx={1.5}>
            <LegendElement Icon={DemonstratedIcon} label="Demonstrated" />
          </Box>
          <LegendElement
            Icon={NotDemonstratedTraining}
            label="Not Demonstrated"
          />
        </Box>
      </Box>
    </Box>
  );
};

OverlayAssignedWR.propTypes = {
  overlayWorkrole: PropTypes.bool.isRequired,
  setOverlayWorkRole: PropTypes.func.isRequired,
};

export default OverlayAssignedWR;
