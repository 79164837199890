import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  badgeContainer: {
    width: 'auto',
    '& svg': {
      height: '100%',
      width: '100%',
    },
  },
});

const BadgeContainer = ({ size, children }) => {
  const classes = useStyles();
  const sizeMap = { small: '100px', medium: '120px', large: '165px' };
  return (
    <Box
      className={classes.badgeContainer}
      style={{ height: sizeMap[size] || size }}
    >
      {children}
    </Box>
  );
};

BadgeContainer.defaultProps = {
  size: 'medium',
};

BadgeContainer.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.node.isRequired,
};

export default BadgeContainer;
