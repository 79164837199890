import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@material-ui/core';
import { FdTypography, FdSkeleton, FdCard } from '@fifthdomain/fe-shared';
import HighlightedLabel from '../Team/Dashboard/HighlightedLabel';
import LabelWithTooltip from '../Insights/LabelWithTooltip';
import PercentageDifferenceIndicator from './PercentageDifferenceIndicator';

const Container = ({ children }) => (
  <Box className="flex items-center gap-x-2">{children}</Box>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

const ScoreSummary = ({ userAverage, loading, affiliated }) => {
  const summaryData = [
    {
      value: (
        <Container>
          <Box className="flex justify-center items-center w-8 h-8 rounded-full ml-3">
            <HighlightedLabel value={Math.round(userAverage?.score) || 0} />
          </Box>
          <PercentageDifferenceIndicator
            value={userAverage?.percentageDifferenceInScore}
            suffix="last month"
          />
        </Container>
      ),
      description: (
        <LabelWithTooltip
          label="FifthDomain Score"
          tooltipText="The FifthDomain Score is a combined weighted sum of a user’s Success, Efficiency, and Speed scores, with Success carrying the highest weightage. 

          The displayed FifthDomain Score is based on this user’s performance in all events they have participated in platform-wide, including any events from outside your organisation. "
        />
      ),
    },
    {
      value: (
        <Container>
          <FdTypography variant="h4" style={{ fontWeight: 500 }}>
            {Math.round(userAverage?.successScore) || 0}
          </FdTypography>
          <PercentageDifferenceIndicator
            value={userAverage?.percentageDifferenceInSuccessScore}
            suffix="last month"
          />
        </Container>
      ),
      description: (
        <LabelWithTooltip
          label="Success Score"
          tooltipText={`The Success Score measures a user’s ability to capture flags, taking into account points earned for solved challenges relative to overall points available.

          The displayed Success Score is based ${
            !affiliated ? ' only ' : ''
          } on this user’s performance in
        
          ${
            affiliated
              ? ' all events they have participated in platform-wide, including any events from outside '
              : ' assigned events hosted by'
          }
          your organisation. 
    `}
        />
      ),
    },
    {
      value: (
        <Container>
          <FdTypography variant="h4" style={{ fontWeight: 500 }}>
            {Math.round(userAverage?.efficiency) || 0}
          </FdTypography>
          <PercentageDifferenceIndicator
            value={userAverage?.percentageDifferenceInEfficiency}
            suffix="last month"
          />
        </Container>
      ),
      description: (
        <LabelWithTooltip
          label="Efficiency Score"
          tooltipText={`The Efficiency Score measures a user’s ability to score points in challenges while minimising the number of attempts made.

          The displayed Efficiency Score is based 
         ${
           affiliated
             ? ' on this user’s performance in all events they have participated in platform-wide, including any events from outside '
             : ' only on this user’s performance in assigned events hosted by '
         }
          your organisation. `}
        />
      ),
    },
    {
      value: (
        <Container>
          <FdTypography variant="h4" style={{ fontWeight: 500 }}>
            {Math.round(userAverage?.speed) || 0}
          </FdTypography>
          <PercentageDifferenceIndicator
            value={userAverage?.percentageDifferenceInSpeed}
            suffix="last month"
          />
        </Container>
      ),
      description: (
        <LabelWithTooltip
          label="Speed Score"
          tooltipText={`The Speed Score reflects a user’s quickness in completing challenges within the allocated time for an event.

          The displayed Speed Score is based 
          ${
            affiliated
              ? ' on this user’s performance in all events they have participated in platform-wide, including any events from outside '
              : ' only on this user’s performance in assigned events hosted by '
          }
          your organisation. `}
        />
      ),
    },
  ];
  return (
    <FdSkeleton loading={loading}>
      <FdCard variant="outlined">
        <Box className="flex items-center justify-around w-full">
          {summaryData?.map((d, idx) => (
            <>
              <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="center">
                  {d?.value}
                </Box>
                <Box display="flex" mt={1}>
                  <FdTypography variant="body2" color="secondary">
                    {d?.description}
                  </FdTypography>
                </Box>
              </Box>
              {idx < summaryData.length - 1 && (
                <Divider orientation="vertical" flexItem />
              )}
            </>
          ))}
        </Box>
      </FdCard>
    </FdSkeleton>
  );
};

ScoreSummary.propTypes = {
  userAverage: PropTypes.shape({
    score: PropTypes.number,
    percentageDifferenceInScore: PropTypes.number,
    successScore: PropTypes.number,
    percentageDifferenceInSuccessScore: PropTypes.number,
    efficiency: PropTypes.number,
    percentageDifferenceInEfficiency: PropTypes.number,
    speed: PropTypes.number,
    percentageDifferenceInSpeed: PropTypes.number,
  }),
  loading: PropTypes.bool,
  affiliated: PropTypes.bool,
};
ScoreSummary.defaultProps = {
  loading: false,
  userAverage: {},
  affiliated: false,
};

export default ScoreSummary;
