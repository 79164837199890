import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdCard, FdMarkdownEditor, FdTextField } from '@fifthdomain/fe-shared';

const PreMessage = ({ heading }) => {
  const { control } = useFormContext();
  return (
    <FdCard
      heading={heading}
      subHeading="The following instructions will be displayed to participants both before and during the competition."
      variant="outlined"
    >
      <Box mt={0}>
        <Controller
          control={control}
          name="preMessage"
          render={({ field, fieldState: { error } }) => (
            <Box mb={2}>
              <FdMarkdownEditor
                id="preMessage"
                name="preMessage"
                markdown={field.value}
                setMarkdown={field.onChange}
                label="Message"
                required
                error={error}
                errorText={error && error.message}
                {...field}
              />
            </Box>
          )}
        />
      </Box>
      <Controller
        control={control}
        name="videoUrl"
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <Box mt={1} mb={2}>
            <FdTextField
              id="videoUrl"
              placeholder="Enter Vimeo URL"
              label="Video"
              fullWidth
              error={error}
              helperText={
                error
                  ? error.message
                  : 'You can paste a Vimeo embed URL below to supplement your pre-competition message.'
              }
              {...rest}
              inputRef={ref}
            />
          </Box>
        )}
      />
    </FdCard>
  );
};

PreMessage.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

PreMessage.defaultProps = {
  heading: 'Set Instructions',
};

export default PreMessage;
