import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import { FdModal } from '@fifthdomain/fe-shared';
import { TROPHY_TYPES } from '../../constants';
import TrophySection from './TrophySection';
import TrophyTile from './TrophyTile';
import SectionModalContent from './SectionModalContent';
import SectionHeading from './SectionHeading';
import TrophyVerticalTile from './TrophyVerticalTile';
import SectionModalContentWithTabs from './SectionModalContentWithTabs';

const TrophySections = ({
  type,
  badges,
  userBadges,
  listAttemptAggregates,
}) => {
  const [currentSection, setCurrentSection] = useState(false);
  const types =
    type === 'All'
      ? TROPHY_TYPES
      : TROPHY_TYPES?.filter((ts) => ts?.name === type);

  return (
    <Box>
      {types?.map((_type) =>
        _type?.rows?.map((_row) => (
          <Box className={`grid grid-cols-${_row?.cols} gap-x-4 gap-y-2 my-3`}>
            {_row?.sections?.map((_section) => {
              const isSingleTile = _section?.tiles?.length === 1;
              const sectionTiles = _section?.tiles?.map((_tile) => {
                const badgesTypes = badges
                  ?.filter((b) => b?.badgeType === _tile?.specialty)
                  ?.sort((a, b) => a?.level - b?.level);

                const badgeLevels = badgesTypes?.map((b, index) => {
                  const badgeAcquiredOn = userBadges?.filter(
                    (ub) => ub?.badgeId === b?.id,
                  )[0]?.createdAt;

                  const userBadge =
                    userBadges?.filter(
                      (ub) =>
                        ub?.badge?.specialty?.name === b?.specialty?.name &&
                        ub?.badge?.level === b?.level,
                    )[0] || {};
                  const pointsScored = userBadge?.points || 0;

                  return {
                    ..._section,
                    level: b?.level,
                    start: badgesTypes[index - 1]?.target || 0,
                    end: b?.target - 1,
                    endDisplay: b?.target,
                    noOfSkillCoverageToAcquireBadge:
                      b?.noOfSkillCoverageToAcquireBadge,
                    specialty: b?.specialty?.name || b?.badgeType,
                    pointsScored,
                    badgeAcquiredOn,
                    championEvents:
                      b?.badgeType === 'competition_champion'
                        ? userBadges?.filter((ub) => ub?.badgeId === b?.id) || 0
                        : 0,
                  };
                });
                const totalPointsScored = _.max(
                  badgeLevels?.map((b) => b?.pointsScored) || [],
                );
                const maxTierScored =
                  _.max(
                    badgeLevels
                      ?.filter((b) => b?.badgeAcquiredOn)
                      ?.map((b) => b?.level) || [],
                  ) || 0;
                const noBadgesAcquired = maxTierScored === 0;
                const currentTierLevel = noBadgesAcquired
                  ? 1
                  : maxTierScored === 5
                  ? 5
                  : maxTierScored + 1;

                const pointsScoredWithoutBadges = noBadgesAcquired
                  ? _.sum(
                      (listAttemptAggregates ?? [])
                        .filter(
                          (a) =>
                            _tile?.specialty === 'points' ||
                            a?.task?.specialty?.name?.toLowerCase() ===
                              _tile?.specialty,
                        )
                        .map((a) => a?.points ?? 0),
                    )
                  : 0;
                const skillsScored = _.uniq(
                  listAttemptAggregates
                    ?.filter(
                      (a) =>
                        a?.task?.specialty?.name?.toLowerCase() ===
                        _tile?.specialty,
                    )
                    ?.map((a) =>
                      a?.task?.skills?.items?.map((s) => ({
                        alias: s?.skill?.alias,
                        level: a?.task?.difficulty,
                      })),
                    )
                    ?.flat() || [],
                );
                const currentBadgeLevel = badgeLevels?.find(
                  (b) => b?.level === currentTierLevel,
                );

                return {
                  ..._tile,
                  currentBadgeLevel,
                  pointsScored: totalPointsScored + pointsScoredWithoutBadges,
                  skillsScored,
                  type: _type?.name,
                  badgeLevels,
                };
              });

              const onView = (_specialty) => {
                const currentSelection = sectionTiles?.find(
                  (s) => s?.specialty === _specialty,
                );
                setCurrentSection({
                  name: currentSelection?.name,
                  type: currentSelection?.type,
                  verticalTiles: _section?.verticalTiles,
                  specialty: currentSelection?.specialty,
                  currentBadgeLevel: currentSelection?.currentBadgeLevel,
                  badgeLevels: currentSelection?.badgeLevels,
                  pointsScored: currentSelection?.pointsScored,
                  championEvents: currentSelection?.championEvents,
                  skillsScored: currentSelection?.skillsScored,
                });
              };

              return (
                <TrophySection
                  key={_section?.name}
                  section={_section}
                  type={_type?.name}
                  showDivider={isSingleTile}
                  onView={onView}
                >
                  <Box
                    className={`grid grid-cols-${
                      isSingleTile ? 1 : 2
                    } gap-x-5 gap-y-3`}
                  >
                    {sectionTiles?.map((_tile) =>
                      isSingleTile ? (
                        _tile?.verticalTiles ? (
                          <TrophyVerticalTile
                            tile={_tile}
                            badgeLevels={_tile?.badgeLevels}
                          />
                        ) : (
                          <TrophyTile
                            tile={_tile}
                            showSkillList={false}
                            badgeLevels={_tile?.badgeLevels}
                          />
                        )
                      ) : (
                        <TrophySection
                          key={_tile?.name}
                          section={_tile}
                          type={_type?.name}
                          showTypeChip={false}
                          onView={onView}
                        >
                          <TrophyTile
                            tile={_tile}
                            badgeLevels={_tile?.badgeLevels}
                          />
                        </TrophySection>
                      ),
                    )}
                  </Box>
                </TrophySection>
              );
            })}
          </Box>
        )),
      )}
      <FdModal
        size={currentSection.verticalTiles ? 'md' : 'lg'}
        dismiss="Close"
        showConfirm={false}
        title={
          <SectionHeading
            name={currentSection?.name}
            type={currentSection?.type}
          />
        }
        open={currentSection}
        onDismiss={() => {
          setCurrentSection(false);
        }}
      >
        {currentSection.verticalTiles ? (
          <SectionModalContentWithTabs section={currentSection} />
        ) : (
          <SectionModalContent section={currentSection} />
        )}
      </FdModal>
    </Box>
  );
};

TrophySections.propTypes = {
  type: PropTypes.string.isRequired,
  badges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userBadges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  listAttemptAggregates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TrophySections;
