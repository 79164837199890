import { addMonths } from 'date-fns';

export const updateTask = (
  _taskIdValue,
  _assessmentId,
  task,
  createModulePartMutation,
  createTaskAssessmentMutation,
  setValue,
  getValues,
) => {
  return new Promise((resolve, reject) => {
    if (task?.type === 'LAB') {
      const taskLabs = getValues('taskLabs');
      const endDateTime = getValues('endDateTime');
      createModulePartMutation({
        variables: {
          input: {
            assessmentId: _assessmentId,
            courseModuleId: _taskIdValue,
            description: task.description,
            name: task.name,
            orderNumber: Object.entries(taskLabs)?.length + 1,
            type: 'LAB',
            labId: task.labId,
            duration: 120, // default value for lab duration
            inactivityExpiry: 14, // default value for lab abandonment
            expiry: endDateTime // default value for lab expiry based on type of competition
              ? new Date(endDateTime)
              : addMonths(new Date(), 2),
          },
        },
        onCompleted: (_data) => {
          const { id, courseModuleId } = _data.createModulePart;
          setValue('taskLabs', {
            ...taskLabs,
            [courseModuleId]: id,
          });

          createTaskAssessmentMutation({
            variables: {
              input: {
                taskId: _taskIdValue,
                assessmentId: _assessmentId,
                modulePartId: id,
              },
            },
            onCompleted: () => resolve(),
            onError: () => reject(),
          });
        },
        onError: () => reject(),
      });
    } else {
      createTaskAssessmentMutation({
        variables: {
          input: {
            taskId: _taskIdValue,
            assessmentId: _assessmentId,
          },
        },
        onCompleted: () => {
          resolve();
        },
        onError: () => reject(),
      });
    }
  });
};
