import React, { useEffect, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import singleSpa from 'single-spa';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  AuthContext,
  useSnapshot,
  globalStore,
  FdProgress,
} from '@fifthdomain/fe-shared';
import setAppMarginRightByIds from '../shared/utils/layout';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const globalSnap = useSnapshot(globalStore);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authUser) => {
        setUser(authUser);
        // add logged in user to global state
        globalStore.user = authUser;
      })
      .catch(() => {
        singleSpa.navigateToUrl('/');
      });
  }, []);

  if (!globalSnap.orgProducts) {
    return <FdProgress />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const type = globalSnap.userType;
        if (user && type === 'PARTICIPANT') {
          return <Redirect to="/landing/landing-homepage" />;
        }
        if (user && ['ADMIN', 'MANAGER'].includes(type)) {
          setAppMarginRightByIds(['topnav', 'competitions'], '0'); // reset topnav and competitions to full width on page initialize
          if (!globalSnap.orgProducts?.includes('Competitions'))
            singleSpa.navigateToUrl('/landing');
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ProtectedRoute;
