import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { Box, useTheme, Divider } from '@material-ui/core';
import {
  FdTypography,
  FdSkeleton,
  useSnapshot,
  globalStore,
  FdIconsV5,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import { listSquadMembersByUserId } from '../../graphql/queries';

const SquadTag = ({ userId }) => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const { Spoke } = FdIconsV5;

  const { data: squadMemberData, loading: squadMemberLoading } = useQuery(
    gql(listSquadMembersByUserId),
    {
      variables: {
        userId: userId || globalSnap?.userId,
      },
    },
  );

  const squadMember =
    squadMemberData?.listSquadMembersByUserId?.items?.find(
      (s) => s.squad && s?.role !== 'VIEWER',
    ) || {};
  const squadName = squadMember?.squad?.name || '';

  return (
    <FdSkeleton height={24} loading={squadMemberLoading} width={200}>
      {squadName && <Divider orientation="vertical" flexItem />}
      <Box>
        {squadName && (
          <FdTooltip
            title={`This user is currently a member of ${squadName}. Squad Managers and Squad Viewers can see their skill and performance data, and how it contributes to this squad. `}
          >
            <Box
              className="flex items-center px-4 py-1 rounded gap-x-2"
              style={{
                height: '28px',
                backgroundColor:
                  theme?.palette?.type === 'dark'
                    ? 'rgba(29, 57, 110, 1)'
                    : 'rgba(227, 230, 236, 1)',
              }}
            >
              <Spoke style={{ height: '16px', width: '16px' }} />
              <FdTypography variant="captiontext1">{squadName}</FdTypography>
            </Box>
          </FdTooltip>
        )}
      </Box>
    </FdSkeleton>
  );
};

SquadTag.defaultProps = {
  userId: null,
};

SquadTag.propTypes = {
  userId: PropTypes.string,
};

export default SquadTag;
