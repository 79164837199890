import React from 'react';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { FdTypography } from '@fifthdomain/fe-shared';

const MultiTileInfo = ({
  title,
  subTitle,
  preMessage,
  postMessage,
  tiles,
  valueVariant,
}) => (
  <Box>
    <FdTypography variant="h4">{title}</FdTypography>
    <Box mt={2}>
      <FdTypography variant="subtitle1">{subTitle}</FdTypography>
    </Box>
    <Box mt={2}>
      <FdTypography variant="body2">{preMessage}</FdTypography>
    </Box>
    <Grid container>
      {tiles?.map((t) => (
        <Grid item xs key={shortid.generate()}>
          <Box display="flex" justifyContent="center">
            <Box
              mt={3}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Box>
                <FdTypography variant={valueVariant}>{t.value}</FdTypography>
              </Box>
              <Box>
                <FdTypography variant="body2" color="secondary">
                  {t.description}
                </FdTypography>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
    <Box mt={2}>
      <FdTypography variant="body2" color="secondary">
        {postMessage}
      </FdTypography>
    </Box>
  </Box>
);

MultiTileInfo.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  preMessage: PropTypes.string,
  postMessage: PropTypes.string,
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  valueVariant: PropTypes.string,
};

MultiTileInfo.defaultProps = {
  subTitle: '',
  preMessage: '',
  postMessage: '',
  valueVariant: 'h4',
  tiles: [],
};

export default MultiTileInfo;
