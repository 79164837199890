import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography, FdIconsV5 } from '@fifthdomain/fe-shared';

const ProgressionTile = ({
  heading,
  subHeading,
  subHeadingAbbreviation,
  description,
  subHeading2,
  subHeading2Abbreviation,
  description2,
  subheadingColor,
}) => {
  const { South: SouthIcon } = FdIconsV5;

  return (
    <>
      <FdTypography variant="body2" style={{ fontWeight: 600 }}>
        {heading}
      </FdTypography>
      <Box className="flex items-center gap-x-2">
        <FdTypography variant="h3" style={{ color: subheadingColor }}>
          {subHeading}
        </FdTypography>
        <FdTypography variant="captiontext2">
          {subHeadingAbbreviation}
        </FdTypography>
      </Box>
      <FdTypography variant="captiontext1" color="secondary">
        {description}
      </FdTypography>
      <SouthIcon className="my-3" />
      <Box className="flex flex-col items-center gap-x-2">
        <Box className="flex items-center gap-x-2">
          <FdTypography variant="h3" style={{ color: subheadingColor }}>
            {subHeading2}
          </FdTypography>
          <FdTypography variant="captiontext2">
            {subHeading2Abbreviation}
          </FdTypography>
        </Box>
        <FdTypography variant="captiontext1" color="secondary">
          {description2}
        </FdTypography>
      </Box>
    </>
  );
};

ProgressionTile.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  subHeadingAbbreviation: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  subHeading2: PropTypes.string.isRequired,
  subHeading2Abbreviation: PropTypes.string.isRequired,
  description2: PropTypes.string.isRequired,
  subheadingColor: PropTypes.string.isRequired,
};

export default ProgressionTile;
