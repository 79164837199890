import { Storage } from 'aws-amplify';

export const getRandomImage = (images) =>
  images[Math.floor(Math.random() * images.length)];

export const getImageUrlFromS3 = async (key) => {
  try {
    const imageData = await Storage.get(key, {
      level: 'public',
    });
    return imageData;
  } catch (error) {
    return error.message;
  }
};
