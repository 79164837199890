import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdSkeleton } from '@fifthdomain/fe-shared';
import HeatMapCell from './HeatMapCell';
import SkillsDemonstrated from './SkillsDemonstrated';

const SPECIALTY_SORT = {
  Intelligence: { order: 1, paddingTop: 28 },
  Penetration: { order: 2, paddingTop: 20 },
  Protection: { order: 3, paddingTop: 2 },
  Detection: { order: 4, paddingTop: 2 },
  Engineering: { order: 5, paddingTop: 10 },
  Investigation: { order: 6, paddingTop: 2 },
};

const CyberBrain = ({
  loading,
  specialtiesFormatted,
  teamBased,
  size,
  toggleSelection,
  isParticipant,
  topSpecialty,
  top3Skills,
}) => {
  const isSolvesSelected = toggleSelection === 'solves';
  const oddNumberSpecialties = specialtiesFormatted?.length % 2 !== 0;
  const specialtiesCount = specialtiesFormatted?.length || 0;

  const layoutSize =
    size === 'small'
      ? {
          brain: '510px',
          rightPadding: 47,
          leftPadding: 36,
        }
      : {
          brain: '580px',
          rightPadding: 38,
          leftPadding: 37,
        };

  return (
    <FdSkeleton loading={loading} height={665}>
      <Box id="cyber-brain" className="flex flex-col items-center">
        <Box
          className="grid grid-cols-2 gap-x-3"
          height="100%"
          width={layoutSize?.brain}
        >
          {specialtiesFormatted?.map((specialty, i) => (
            <Box
              style={{
                paddingRight: specialty?.flip && '1rem',
                position: 'relative',
                paddingBottom: 0,
              }}
            >
              <>
                <HeatMapCell
                  data={specialty?.dataSorted}
                  label={specialty?.specialtyName}
                  flip={specialty?.flip}
                  showHeader={specialty?.showHeader}
                  colors={{
                    type: 'sequential',
                    scheme: isSolvesSelected ? 'purples' : 'reds',
                    minValue: 0,
                    maxValue: 50,
                  }}
                  margin={{
                    top: SPECIALTY_SORT[specialty?.specialtyName]?.paddingTop,
                    right: specialty?.flip ? 2 : layoutSize?.rightPadding,
                    bottom: 0,
                    left: specialty?.flip ? layoutSize?.leftPadding : 2,
                  }}
                />
                {specialty?.flip && (
                  <Box
                    style={{
                      position: 'absolute',
                      top: [0, 1].includes(i) ? '35px' : 0,
                      right: 0,
                      bottom: oddNumberSpecialties
                        ? specialtiesCount - 1
                        : specialtiesCount - 2 === i
                          ? '24px'
                          : 0,
                      borderRight: '1px dashed #63A4FF',
                    }}
                  />
                )}
              </>
            </Box>
          ))}
        </Box>
        {isParticipant && (
          <SkillsDemonstrated
            isSolvesSelected={isSolvesSelected}
            teamBased={teamBased}
            topSpecialty={topSpecialty}
            top3Skills={top3Skills}
          />
        )}
      </Box>
    </FdSkeleton>
  );
};

CyberBrain.defaultProps = {
  toggleSelection: undefined,
  size: 'medium',
  topSpecialty: undefined,
  top3Skills: [],
};

CyberBrain.propTypes = {
  loading: PropTypes.bool.isRequired,
  teamBased: PropTypes.bool.isRequired,
  isParticipant: PropTypes.bool.isRequired,
  toggleSelection: PropTypes.string,
  specialtiesFormatted: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  size: PropTypes.oneOf('small', 'medium'),
  topSpecialty: PropTypes.string,
  top3Skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
};

export default CyberBrain;
