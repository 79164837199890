import { ReactQueryClient } from '@fifthdomain/fe-shared';

export const invalidateAssessmentsHomePageDataQuery = () => {
  ReactQueryClient.removeQueries(['ListUserAssessments']);
  ReactQueryClient.removeQueries(['ListUserAssessmentsWithTasks']);
};

export const invalidateAdminHomePageDataQuery = () => {
  ReactQueryClient.removeQueries(['ListUserAssessments']);
  ReactQueryClient.removeQueries(['ListInvitedUsersByOrg']);
  ReactQueryClient.removeQueries(['ListAssessmentsByOrg']);
};

export const invalidateGetAssessment = () => {
  ReactQueryClient.removeQueries(['GetTasksByAssessmentId']);
};
export const invalidateChallengeChat = () => {
  ReactQueryClient.removeQueries(['ListChallengeChatsByAssessmentId']);
};
