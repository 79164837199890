import React, { useEffect, useRef } from 'react';
import anime from 'animejs';

const UnTickIcon = () => {
  const circleRef = useRef(null);

  useEffect(() => {
    anime({
      targets: circleRef.current,
      fill: ['rgba(0, 0, 0, 0)', 'rgba(51, 51, 255, 1)'],
      easing: 'easeInExpo',
      duration: 1500,
    });
  }, []);

  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        ref={circleRef}
        d="M8 0.0185547C3.584 0.0185547 0 3.60255 0 8.01855C0 12.4346 3.584 16.0186 8 16.0186C12.416 16.0186 16 12.4346 16 8.01855C16 3.60255 12.416 0.0185547 8 0.0185547ZM8 14.4186C4.464 14.4186 1.6 11.5546 1.6 8.01855C1.6 4.48255 4.464 1.61855 8 1.61855C11.536 1.61855 14.4 4.48255 14.4 8.01855C14.4 11.5546 11.536 14.4186 8 14.4186Z"
        fill="#3333FF"
      />
    </svg>
  );
};

export default UnTickIcon;
