import { getTeamsWithSharedEmails } from '../../shared/utils/teamUtils';
import Yup from '../Common';

export const initialValues = {
  teams: [],
};

export const validationSchema = Yup.object().shape({
  teams: Yup.array()
    .of(
      Yup.object().shape({
        teamId: Yup.string(),
        teamName: Yup.string(),
        emails: Yup.array().of(Yup.string()),
      }),
    )
    .min(1)
    .test('unique-email', 'Email must be unique', function (values) {
      const duplicates = getTeamsWithSharedEmails(values);
      return duplicates?.length === 0;
    }),
});
