import React from 'react';

const TrainingIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="12" height="12" rx="6" fill="#B3E5FC" />
    <path
      opacity="0.85"
      d="M5.8139 6.44L5.1865 6.74L3.88915 5.93L4.24007 5.25C4.31096 5.11667 4.41553 5.00833 4.55377 4.925C4.69202 4.84167 4.84266 4.8 5.00572 4.8H5.57996V2.4C5.57996 2.28667 5.62072 2.19167 5.70225 2.115C5.78377 2.03833 5.8848 2 6.00532 2H8.21719C8.29518 2 8.35544 2.03167 8.39797 2.095C8.44051 2.15833 8.44405 2.22333 8.40861 2.29L8.17466 2.71C8.16048 2.74333 8.15339 2.77333 8.15339 2.8C8.15339 2.82667 8.16048 2.85667 8.17466 2.89L8.40861 3.31C8.44405 3.37667 8.44051 3.44167 8.39797 3.505C8.35544 3.56833 8.29518 3.6 8.21719 3.6H6.43068V4.8H6.96238C7.12543 4.8 7.27431 4.84 7.40901 4.92C7.54371 5 7.65005 5.10667 7.72803 5.24L8.10022 5.94L6.82414 6.74L6.19673 6.44C6.14001 6.40667 6.07621 6.39 6.00532 6.39C5.93442 6.39 5.87062 6.40667 5.8139 6.44ZM2.04946 9.42L3.50632 6.65L4.92065 7.53C4.98445 7.57 5.05357 7.59333 5.12801 7.6C5.20245 7.60667 5.27511 7.59333 5.34601 7.56L6.00532 7.25L6.66463 7.56C6.73552 7.59333 6.80819 7.60833 6.88262 7.605C6.95706 7.60167 7.02618 7.58 7.08999 7.54L8.48304 6.67L9.95054 9.42C10.0214 9.55333 10.0161 9.68333 9.93459 9.81C9.85306 9.93667 9.73077 10 9.56771 10H2.43229C2.26923 10 2.14694 9.93667 2.06541 9.81C1.98388 9.68333 1.97857 9.55333 2.04946 9.42Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export default TrainingIcon;
