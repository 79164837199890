import React from 'react';
import PropTypes from 'prop-types';
import { Badge, withStyles } from '@material-ui/core';
import {
  FdAvatarGroup,
  getAvatarColor,
  getAvatarName,
} from '@fifthdomain/fe-shared';
import { TASK_ACTIVITY_TOOLTIP } from '../../constants';

const badgeStyles = {
  boxShadow: '0 0 0 1px #fff',
  '&::after': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    border: '1px solid currentColor',
    content: '""',
  },
  '&.MuiBadge-anchorOriginBottomRightRectangle': {
    top: 28,
    right: 15,
  },
};

const ProgressBadge = withStyles(() => ({
  badge: {
    backgroundColor: '#4CAF50',
    color: '#4CAF50',
    ...badgeStyles,
  },
}))(Badge);

const HelpBadge = withStyles(() => ({
  badge: {
    backgroundColor: '#F57C00',
    color: '#F57C00',
    ...badgeStyles,
  },
}))(Badge);

const BlockedBadge = withStyles(() => ({
  badge: {
    backgroundColor: '#C62828',
    color: '#C62828',
    ...badgeStyles,
  },
}))(Badge);

const TaskActivity = ({ activities }) => {
  const getActivityBadge = (activity) => {
    let AssignedBadge;
    switch (activity) {
      case 'NEED_HELP':
        AssignedBadge = HelpBadge;
        break;
      case 'WORKING':
        AssignedBadge = ProgressBadge;
        break;
      case 'BLOCKED':
        AssignedBadge = BlockedBadge;
        break;
      default:
        AssignedBadge = ProgressBadge;
    }
    return AssignedBadge;
  };

  const avatarsWithBadge = activities
    .filter((a) => ['NEED_HELP', 'WORKING', 'BLOCKED'].includes(a.activity))
    .map((a) => ({
      content: getAvatarName(a.name, true),
      color: getAvatarColor(a.name),
      badge: getActivityBadge(a.activity),
      tooltip: `${a.name} ${TASK_ACTIVITY_TOOLTIP[a.activity]}`,
    }));

  return (
    <FdAvatarGroup
      avatars={avatarsWithBadge}
      size="small"
      spacing={20}
      max={6}
      data-cy="avatar-group"
    />
  );
};

TaskActivity.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      activity: PropTypes.string,
      name: PropTypes.string,
      taskId: PropTypes.string,
    }),
  ).isRequired,
};

export default TaskActivity;
