import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { FdIcons, FdChip, FdTypography } from '@fifthdomain/fe-shared';
import { Summary } from '../Assessment';
import { getTeam } from '../../graphql/queries';
import FdSystemDateCountdown from '../FdSystemDateCountdown';
import getStatusColor from '../../shared/utils/getStatusColor';
import LabelWithTooltip from '../Insights/LabelWithTooltip';
import IconWithLabel from '../Insights/IconWithLabel';

const AssessmentSummary = ({
  assessment: {
    rank,
    tasksCompleted,
    endDateTime,
    firstBloods,
    totalPoints,
    teamBased,
    teamId,
    eventStatus,
    totalTasks,
  },
  onFinish,
  noBorderBox,
  loading,
  scoreBoardDetail,
}) => {
  const theme = useTheme();
  const { data: teamMembers, loading: teamMembersLoading } = useQuery(
    gql(getTeam),
    {
      variables: {
        id: teamId,
      },
      skip: !teamId,
    },
  );
  const teamMemberCount = teamMembers?.getTeam?.members?.items.length || 0;

  const { FlagOutlined, TimerOutlined, Whatshot, SelectAll, Group } = FdIcons;

  const scoreboardData = [
    {
      value: (
        <Box
          justifyContent="center"
          className="flex justify-center items-center w-8 h-8 rounded-full mr-1 my-1"
          style={{
            backgroundColor: theme?.palette?.primary?.main,
            color: '#fff',
          }}
        >
          <FdTypography variant="body1">{`#${rank}`}</FdTypography>
        </Box>
      ),
      description: (
        <LabelWithTooltip
          label="Competition Rank"
          tooltipText="Competition rank is determined by the number of points scored in a competition."
        />
      ),
    },
    {
      value: (
        <FdChip
          color={getStatusColor(eventStatus)}
          size="small"
          label={eventStatus}
        />
      ),
      description: (
        <FdTypography variant="subtitle2">
          {`${teamBased ? 'Team' : 'Participant'} progress status`}
        </FdTypography>
      ),
    },

    ...(teamBased
      ? [
          {
            value: String(teamMemberCount),
            description: (
              <FdTypography variant="subtitle2">
                Number of team members
              </FdTypography>
            ),
          },
        ]
      : []),
    {
      value: String(totalPoints),
      description: (
        <IconWithLabel
          backgroundColor="rgba(232, 234, 246, 1)"
          Icon={SelectAll}
          color="rgba(40, 53, 147, 1)"
          label="Points"
        />
      ),
    },
    {
      value: `${tasksCompleted}/${totalTasks}`,
      description: (
        <IconWithLabel
          backgroundColor="rgba(243, 229, 245, 1)"
          Icon={FlagOutlined}
          color="rgba(142, 36, 170, 1)"
          label="Flags"
        />
      ),
    },
    ...(firstBloods
      ? [
          {
            value: String(firstBloods),
            description: (
              <IconWithLabel
                backgroundColor="rgba(239, 235, 233, 1)"
                Icon={Whatshot}
                color="rgba(78, 52, 46, 1)"
                label="First Solves"
              />
            ),
          },
        ]
      : []),
  ];

  const data = [
    ...(teamBased
      ? [
          {
            value: 'Team Members',
            description: String(teamMemberCount),
            icon: (
              <Group
                style={{
                  fontSize: 38,
                  backgroundColor: ' #E8EAF6',
                  borderRadius: '50%',
                  padding: '0.5rem',
                  color: '#3d3dc2',
                }}
              />
            ),
          },
        ]
      : []),
    ...(endDateTime
      ? [
          {
            value: 'Time Left',
            description: endDateTime && (
              <FdSystemDateCountdown
                endDateTime={endDateTime}
                onFinish={() => {
                  onFinish();
                }}
                daysInHours
              />
            ),
            icon: (
              <TimerOutlined
                style={{
                  fontSize: 38,
                  backgroundColor: '#E0F2F1',
                  borderRadius: '50%',
                  padding: '0.5rem',
                  color: 'rgb(0 192 181)',
                }}
              />
            ),
          },
        ]
      : []),
    {
      value: 'Points',
      description: String(totalPoints),
      icon: (
        <SelectAll
          style={{
            fontSize: 38,
            backgroundColor: ' #E8EAF6',
            borderRadius: '50%',
            padding: '0.5rem',
            color: '#3d3dc2',
          }}
        />
      ),
    },
    ...(firstBloods
      ? [
          {
            value: 'First Solves',
            description: String(firstBloods),
            icon: (
              <Whatshot
                style={{
                  fontSize: 38,
                  backgroundColor: '#DADADA',
                  borderRadius: '50%',
                  padding: '0.5rem',
                  color: '#000',
                }}
              />
            ),
          },
        ]
      : []),
    {
      value: 'Solved',
      description: String(tasksCompleted),
      icon: (
        <FlagOutlined
          style={{
            fontSize: 38,
            backgroundColor: '#F3E5F5',
            borderRadius: '50%',
            padding: '0.5rem',
            color: 'rgb(153 22 172)',
          }}
        />
      ),
    },
  ];

  return (
    <Box width="100%">
      <Summary
        data={scoreBoardDetail ? scoreboardData : data}
        titleVariant={scoreBoardDetail ? 'h3' : 'subtitle2'}
        subtitleVariant="h3"
        justifyContent={scoreBoardDetail ? 'center' : 'flex-start'}
        noBorderBox={noBorderBox}
        loading={loading || teamMembersLoading}
      />
    </Box>
  );
};

AssessmentSummary.propTypes = {
  assessment: PropTypes.shape({
    tasksCompleted: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalTasks: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    endDateTime: PropTypes.string,
    firstBloods: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalPoints: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    teamBased: PropTypes.bool,
    teamId: PropTypes.string,
    orgId: PropTypes.string,
    rank: PropTypes.string,
    eventStatus: PropTypes.string,
  }).isRequired,
  onFinish: PropTypes.func,
  noBorderBox: PropTypes.bool,
  loading: PropTypes.bool,
  scoreBoardDetail: PropTypes.bool,
};

AssessmentSummary.defaultProps = {
  onFinish: () => {},
  noBorderBox: false,
  loading: false,
  scoreBoardDetail: false,
};

export default AssessmentSummary;
