import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@material-ui/core';
import { FdTypography, FdDelayed } from '@fifthdomain/fe-shared';
import { getArrayByLength } from '../../shared/utils/objectUtils';
import { numberToRoman } from '../../shared/utils/stringUtils';
import Progress from './Progress';
import TrophyBadge from './images/tiers/TrophyBadge';
import BadgeContainer from './BadgeContainer';

const TrophyTile = ({ tile, showSkillList, badgeLevels }) => {
  return (
    <Box className="my-2">
      <Box
        className="flex items-center justify-around w-full mb-2"
        style={{ height: '200px' }}
      >
        {getArrayByLength(5, (__, i) => i + 1).map((item) => {
          const status = badgeLevels?.find((bl) => bl?.level === item)
            ?.badgeAcquiredOn
            ? 'unlocked'
            : 'locked';

          return (
            <React.Fragment key={item}>
              <Box className="flex flex-col items-center justify-center">
                <Box
                  key={item}
                  className="flex items-center justify-center mt-4 mb-2"
                >
                  <BadgeContainer height="80px">
                    <TrophyBadge
                      type={tile?.specialty}
                      tier={item}
                      status={status}
                    />
                  </BadgeContainer>
                </Box>
                <Box className="flex flex-col my-3 items-center justify-center">
                  <FdTypography variant="captiontext2" color="secondary">
                    Tier
                  </FdTypography>
                  <FdTypography variant="subtitle1">
                    {numberToRoman(item)}
                  </FdTypography>
                </Box>
              </Box>
              {item < 5 && (
                <Box className="flex items-center">
                  <Divider className="w-5" />
                </Box>
              )}
            </React.Fragment>
          );
        })}
      </Box>
      <Divider />
      <FdDelayed height="60px" showSkeleton>
        <Progress showSkillList={showSkillList} tile={tile} />
      </FdDelayed>
    </Box>
  );
};

TrophyTile.defaultProps = {
  showSkillList: true,
};

TrophyTile.propTypes = {
  section: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  tile: PropTypes.shape({
    specialty: PropTypes.string,
    currentBadgeLevel: PropTypes.shape({}),
    pointsScored: PropTypes.number,
  }).isRequired,
  showSkillList: PropTypes.bool,
  badgeLevels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TrophyTile;
