import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useQueryRecursive } from '@fifthdomain/fe-shared';
import { listChallengeChatsByAssessmentId } from '../../graphql/queries';
import ChallengeChat from '../Chats/ChallengeChat';

const ParticipantChallengeChat = ({ assessmentId, challengeId, team }) => {
  const { data: challengeChatData, loading: challengeChatDataLoading } =
    useQueryRecursive(gql(listChallengeChatsByAssessmentId), {
      variables: {
        assessmentId,
        challengeId: { eq: challengeId },
        filter: { teamId: { eq: team?.id } },
      },
    });
  const chatMessages =
    challengeChatData?.listChallengeChatsByAssessmentId?.items || [];

  return (
    <ChallengeChat
      challengeId={challengeId}
      assessmentId={assessmentId}
      loading={challengeChatDataLoading}
      chatMessages={chatMessages}
      team={team}
    />
  );
};

ParticipantChallengeChat.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  challengeId: PropTypes.string.isRequired,
  team: PropTypes.shape({
    id: PropTypes.string,
    members: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          userId: PropTypes.string,
          User: PropTypes.shape({
            alias: PropTypes.string,
          }),
        }),
      ),
    }),
  }).isRequired,
};

export default ParticipantChallengeChat;
