import React from 'react';
import _ from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Box, useTheme } from '@material-ui/core';
import {
  FdCard,
  FdTypography,
  useSnapshot,
  globalStore,
  useQueryRecursive,
  FdTab,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import {
  getUserById,
  listBadges,
  listTaskAttemptAggregatesByUserId,
} from '../graphql/queries';
import { listBadgeByUserId } from '../queries/customQueries';
import TrophyRoomIcon from '../components/Trophy-room/images/ErrorIcon';
import { TROPHY_TILES_BANNER } from '../constants';
import ComingSoon from '../components/Trophy-room/ComingSoon';
import TrophyBadge from '../components/Trophy-room/images/tiers/TrophyBadge';
import BadgeContainer from '../components/Trophy-room/BadgeContainer';
import TrophySections from '../components/Trophy-room/TrophySections';

const TrophyRoom = () => {
  const globalSnap = useSnapshot(globalStore);
  const { userId } = useParams();
  const theme = useTheme();
  const isDarkTheme = theme?.palette?.type === 'dark';

  const { data: userData, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: userId,
    },
    skip: !userId,
  });
  const selectedUser = userData?.getUserById?.items[0];
  const userName = selectedUser ? `${selectedUser?.name}'s` : 'your';

  const { loading: badgesLoading, data: listBadgesData } = useQueryRecursive(
    gql(listBadges),
    {
      variables: {
        limit: 1000,
      },
      staleTime: { seconds: 0 },
    },
  );

  const badges =
    listBadgesData?.listBadges?.items?.map((b) => ({
      ...b,
      badgeType: (b?.specialty?.name || b?.badgeType)?.toLowerCase() || '',
    })) || [];

  const { loading: listAttemptsLoading, data: listAttemptAggregatesData } =
    useQueryRecursive(gql(listTaskAttemptAggregatesByUserId), {
      variables: {
        limit: 1000,
        userId: userId || globalSnap?.userId,
      },
      staleTime: { seconds: 0 },
    });

  const { loading: listUserBadgesLoading, data: listUserBadgesData } =
    useQueryRecursive(gql(listBadgeByUserId), {
      variables: {
        limit: 1000,
        userId: userId || globalSnap?.userId,
      },
      staleTime: { seconds: 0 },
    });

  const listAttemptAggregates = _.uniqBy(
    listAttemptAggregatesData?.listTaskAttemptAggregatesByUserId?.items?.filter(
      (a) => a?.status === 'COMPLETED',
    ) || [],
    'taskId',
  );
  const userBadges = listUserBadgesData?.listBadgeByUserId?.items || [];
  const loading =
    userLoading ||
    badgesLoading ||
    listAttemptsLoading ||
    listUserBadgesLoading;

  return (
    <Box>
      <FdCard
        variant="outlined"
        media={
          <Box
            style={{
              width: '100%',
              height: '160px',
              background: isDarkTheme
                ? 'conic-gradient(from 90deg at 50% 50%, #170073 0deg, #050505 225.29deg, #170073 360deg)'
                : 'linear-gradient(89.99deg, #B6B0FF 1.46%, #D9D6FE 49.01%, #F0F8FF 99.99%)',
            }}
          >
            <Box className="flex items-center justify-center gap-x-10 h-full">
              {TROPHY_TILES_BANNER?.map((_type) => (
                <BadgeContainer>
                  <TrophyBadge type={_type} tier={5} status="unlocked" />
                </BadgeContainer>
              ))}
            </Box>
          </Box>
        }
      >
        <Box className="flex flex-col">
          <Box className="flex items-center gap-x-3 mb-2">
            <TrophyRoomIcon />
            <FdTypography variant="h3">Trophy Room</FdTypography>
          </Box>
          <FdSkeleton loading={userLoading}>
            <FdTypography variant="captiontext1">
              {`Welcome to ${userName} Trophy Room! Here, you can view all trophies you
            have unlocked across the Achieve, Engage, and Contribute categories,
            as well as all trophies available to obtain.`}
            </FdTypography>
          </FdSkeleton>
        </Box>
      </FdCard>
      <Box mt={1}>
        <FdSkeleton height="2613px" loading={loading}>
          <FdTab
            label={[
              {
                label: 'All',
                index: 0,
                data: (
                  <TrophySections
                    type="All"
                    badges={badges}
                    userBadges={userBadges}
                    listAttemptAggregates={listAttemptAggregates}
                  />
                ),
              },
              {
                label: 'Achieve',
                index: 1,
                data: (
                  <TrophySections
                    type="Achieve"
                    badges={badges}
                    userBadges={userBadges}
                    listAttemptAggregates={listAttemptAggregates}
                  />
                ),
              },
              {
                label: 'Engage',
                index: 1,
                data: <ComingSoon />,
              },
              {
                label: 'Contribute',
                index: 1,
                data: <ComingSoon />,
              },
            ]}
            variant="fullWidth"
          />
        </FdSkeleton>
      </Box>
    </Box>
  );
};

export default TrophyRoom;
