import React, { useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { subHours, isBefore } from 'date-fns';
import {
  FdCard,
  FdTable,
  FdSelect,
  FdAlert,
  FdTypography,
  FdSkeleton,
  FdButton,
  FdIconWithTooltip,
  useQueryRecursive,
  FdChips,
  FdTooltip,
  FdLoadingSpinner,
} from '@fifthdomain/fe-shared';
import { gql } from '@apollo/client';
import { Line as LineGraph } from '../Charts';
import { SCOREBOARD_DURATION } from '../../constants';
import ScoreboardHidden from '../Overview/ScoreboardHidden';
import TeamAvatar from '../Insights/TeamAvatar';
import TableHeaderColumnWithTooltip from './TableHeaderColumnWithTooltip';
import {
  listUserEventTagsByAssessmentId,
  listTeamEventTagsByAssessmentId,
} from '../../graphql/queries';
import { getTagColor } from '../../shared/utils/tagUtils';
import { sortByDateField } from '../../shared/utils/dateUtils';

const Scoreboard = ({
  assessmentData,
  allAttempts,
  isAdmin,
  attemptsTimeline,
  updatedCompetitionStatus,
  serverCurrentTime,
  loading,
  onLeaderBoardSelection,
}) => {
  const [scoreboardDuration, setScoreboardDuration] = useState(
    updatedCompetitionStatus === 'ENDED'
      ? SCOREBOARD_DURATION.ALL_COMPETITION
      : SCOREBOARD_DURATION.HOURS_24,
  );

  const {
    startDateTime,
    endDateTime,
    teamBased,
    hideScoreBoard,
    teams,
    id: assessmentId,
  } = assessmentData?.getAssessment || {
    startDateTime: undefined,
    endDateTime: undefined,
    teamBased: false,
    teams: [],
  };

  // List the event tags for the assessmentId
  const {
    data: listUserEventTagsByAssessmentIdData,
    loading: listUserEventTagsByAssessmentIdLoading,
  } = useQueryRecursive(gql(listUserEventTagsByAssessmentId), {
    variables: {
      assessmentId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !assessmentId || !isAdmin || teamBased,
  });

  // List the event tags for the assessmentId
  const {
    data: listTeamEventTagsByAssessmentIdData,
    loading: listTeamEventTagsByAssesmentIdLoading,
  } = useQueryRecursive(gql(listTeamEventTagsByAssessmentId), {
    variables: {
      assessmentId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !assessmentId || !isAdmin || !teamBased,
  });

  const eventTagsByAssessmentId =
    (teamBased
      ? listTeamEventTagsByAssessmentIdData?.listTeamEventTagsByAssessmentId
          ?.items
      : listUserEventTagsByAssessmentIdData?.listUserEventTagsByAssessmentId
          ?.items) || [];

  const teamIds =
    teams?.items
      ?.filter((t) => t?.status !== 'REMOVED')
      ?.map((t) => ({ teamId: t.teamId, id: t?.id })) || [];

  const eventTags = eventTagsByAssessmentId
    ?.filter((tag) => teamIds?.some((team) => team?.id === tag?.teamEventId))
    ?.map((a) => ({
      ...a,
      teamId: teamIds?.find((item) => item?.id === a?.teamEventId)?.teamId,
    }));

  const allAttemptsTeamsOrParticipants =
    (teamBased
      ? allAttempts
          .filter((t) =>
            teamIds?.map((team) => team?.teamId).includes(t.teamId),
          )
          .map((a) => ({
            ...a,
            userId: a.teamId,
            _userId: a.userId,
            participant: a.team?.name,
            key: a.team?.key,
            eventTag: eventTags?.filter((tag) => tag?.teamId === a?.teamId),
          }))
      : allAttempts.map((a) => ({
          ...a,
          participant: a.user?.alias || '',
          eventTag: eventTagsByAssessmentId?.filter(
            (tag) => tag?.userId === a?.userId,
          ),
        }))) || [];

  const competitionEndDateTime = endDateTime
    ? isBefore(new Date(endDateTime), new Date(serverCurrentTime))
      ? endDateTime
      : new Date(serverCurrentTime)
    : new Date(serverCurrentTime);

  const isDurationLast24Hours =
    scoreboardDuration === SCOREBOARD_DURATION.HOURS_24;

  // graph date range
  const startDate = isDurationLast24Hours
    ? subHours(new Date(competitionEndDateTime), 24)
    : new Date(startDateTime);
  // graph date range
  const endDate = new Date(competitionEndDateTime);

  const getPointsOnInstance = (dataTimeInstance, participantRow) =>
    attemptsTimeline
      .filter(
        (ap) =>
          (teamBased
            ? ap.teamId === participantRow.teamId
            : ap.userId === participantRow.userId) &&
          new Date(ap.updatedAt).getTime() <=
            new Date(dataTimeInstance).getTime(),
      )
      .reduce((acc, f) => f.points + acc, 0);

  const rows =
    _.orderBy(
      allAttemptsTeamsOrParticipants,
      ['points', 'firstBloods', 'successRate'],
      ['desc', 'desc', 'desc'],
    )?.map((a, i) => ({ ...a, slNo: a.points > 0 ? i + 1 : '-' })) || [];

  if (isAdmin) {
    console.log(rows);
  }

  const columns = [
    { field: 'slNo', width: 150, headerName: 'Rank' },
    {
      field: 'participant',
      width: 420,
      headerName: teamBased ? 'Team Name' : 'Participant',
      valueGetter: (params) => params?.value,
      renderCell: (params) =>
        teamBased ? (
          <Box className="flex items-center gap-x-3">
            <TeamAvatar
              size="small"
              team={{ key: params?.row?.key, name: params?.value }}
            />
            <FdTypography variant="body1">{params?.value}</FdTypography>
          </Box>
        ) : (
          <span>{params?.value}</span>
        ),
    },
    ...(isAdmin
      ? [
          {
            field: 'eventTag',
            width: 220,
            headerName: 'Event Tags',
            renderHeader: () => (
              <TableHeaderColumnWithTooltip
                title="Event Tags"
                tooltipText="This column displays the event tags that individuals/teams have chosen for this event. 
          For additional details, visit the 'Event Tags' tab within this event."
              />
            ),
            valueGetter: (params) =>
              params.row?.eventTag.length > 0
                ? [...params.row?.eventTag]
                    .map((t) => t?.eventTag?.name)
                    ?.join(' ')
                : '',
            renderCell: (params) => {
              const _data = _.uniqBy(
                params.row?.eventTag
                  ?.sort(sortByDateField('updatedAt', 'desc'))
                  .map((t) => ({
                    label: t?.eventTag?.name,
                    color: getTagColor(t?.eventTag?.color),
                  })),
                'label',
              );

              return (
                <FdTooltip
                  title={
                    <Box m={2}>
                      <FdTypography>Applied Event Tags:</FdTypography>
                      {_data?.length > 0 ? <FdChips data={_data} /> : 'None'}
                    </Box>
                  }
                >
                  <IconButton>
                    {_data?.length > 0 ? (
                      <FdChips
                        data={_data}
                        numberOfChipsDisplayed={1}
                        caption="More"
                      />
                    ) : (
                      '-'
                    )}
                  </IconButton>
                </FdTooltip>
              );
            },
          },
        ]
      : []),
    {
      field: 'points',
      flex: 1,
      type: 'number',
      headerName: 'Points',
    },
    { field: 'flags', flex: 1, headerName: '#Flags', type: 'number' },
    {
      field: 'firstBloods',
      flex: 1,
      headerName: '#First Solves',
      type: 'number',
    },
    {
      field: 'successRate',
      flex: 1,
      headerName: 'Success Rate',
      type: 'number',
      renderCell: (params) => `${params.row.successRate}%`,
    },
  ];
  const actions = [
    {
      CustomElement: (row) => {
        const { rowData } = row;
        const _id = teamBased ? rowData?.teamId : rowData?.userId;

        const urlValue = teamBased
          ? `/competitions/competition-scoreboard-view/${assessmentId}/view/${_id}/${rowData?._userId}`
          : `/competitions/competition-scoreboard-view/${assessmentId}/view/${_id}`;
        return (
          <>
            <FdButton
              size="small"
              onClick={() =>
                isAdmin
                  ? onLeaderBoardSelection(rowData?.userId)
                  : window.open(urlValue, '_blank')
              }
              disabled={rowData?.points === 0}
            >
              View
            </FdButton>
            {rowData?.points === 0 && (
              <FdIconWithTooltip
                title="You will be able to view the competition profile for the
              paricipant only after they make their first solve."
              />
            )}
          </>
        );
      },
      width: 120,
    },
  ];

  if (
    listUserEventTagsByAssessmentIdLoading ||
    listTeamEventTagsByAssesmentIdLoading
  ) {
    return <FdLoadingSpinner />;
  }

  return (
    <Box>
      {isAdmin && (
        <Box mb={2}>
          <FdAlert
            variant="info"
            message={
              <FdTypography variant="body2">
                The scoreboard is currently being
                <FdTypography fontWeight="bold" display="inline">
                  {hideScoreBoard ? ' hidden ' : ' displayed '}
                </FdTypography>
                {hideScoreBoard ? 'from ' : 'to '}
                participants. Edit Scoreboard Visibility in the Details tab if
                you would like to change this.
              </FdTypography>
            }
          />
        </Box>
      )}
      <FdCard
        variant="outlined"
        heading="Score Progression"
        subHeading={
          !hideScoreBoard &&
          (teamBased
            ? 'A timeline of scores of the top 10 teams. Hover over the timeline for more information.'
            : `Progression of score over the ${
                scoreboardDuration === SCOREBOARD_DURATION.HOURS_24
                  ? 'last 24 hours'
                  : 'entire competition'
              }. Hover to get more information about the flags captured.`)
        }
      >
        <FdSkeleton loading={loading} height="427px">
          {isAdmin || !hideScoreBoard ? (
            <>
              {updatedCompetitionStatus !== 'ENDED' && (
                <Box
                  height="40px"
                  display="flex"
                  justifyContent="flex-end"
                  mt="-54px"
                  mb="22px"
                >
                  <FdSelect
                    id="scoreboardDuration"
                    placeholder="Scoreboard Duration"
                    defaultSelected={SCOREBOARD_DURATION.HOURS_24}
                    options={[
                      SCOREBOARD_DURATION.ALL_COMPETITION,
                      SCOREBOARD_DURATION.HOURS_24,
                    ]}
                    onChange={setScoreboardDuration}
                  />
                </Box>
              )}
              <LineGraph
                data={[...allAttemptsTeamsOrParticipants]
                  ?.sort((a, b) => b.points - a.points)
                  .splice(0, 10)
                  .map((p) => ({
                    name: p.participant || '',
                    data: [
                      ...[
                        [
                          new Date(startDateTime).getTime(),
                          isDurationLast24Hours
                            ? getPointsOnInstance(startDateTime, p)
                            : 0,
                        ],
                      ], // start date entry with zero for whole competition
                      ...attemptsTimeline
                        ?.sort(
                          (a, b) =>
                            new Date(a.updatedAt) - new Date(b.updatedAt),
                        )
                        .map((i) => [
                          new Date(i.updatedAt).getTime(),
                          getPointsOnInstance(i.updatedAt, p),
                        ])
                        .sort((a, b) => a[0] - b[0]),
                      ...[
                        [
                          new Date(competitionEndDateTime).getTime(),
                          getPointsOnInstance(competitionEndDateTime, p),
                        ], // end date entry for whole competition
                      ],
                    ],
                  }))}
                lineColors={{
                  colors: [
                    '#008FFB',
                    '#00E396',
                    '#FEB019',
                    '#FF4560',
                    '#775DD0',
                    '#3F51B5',
                    '#4CAF50',
                    '#F9CE1D',
                    '#D4526E',
                    '#A300D6',
                  ],
                }}
                xaxisDateTimeMin={startDate?.getTime()}
                xaxisDateTimeMax={endDate?.getTime()}
              />
            </>
          ) : (
            <Box height="458px" width="100%">
              <ScoreboardHidden />
            </Box>
          )}
        </FdSkeleton>
      </FdCard>
      <FdCard
        variant="outlined"
        heading="Leaderboard"
        subHeading={
          !hideScoreBoard &&
          `${teamBased ? 'Team' : 'Player'} standings for the competition.`
        }
      >
        <Box mt={2} mb={2} height="458px" width="100%">
          {isAdmin || !hideScoreBoard ? (
            <FdTable
              toolbarSettings={{
                title: teamBased ? 'Top Teams' : 'Top Participants',
                filterButton: true,
                searchBox: true,
              }}
              rows={rows}
              columns={columns}
              actions={actions}
              tablePageSize={10}
              pagination
              rowsPerPageOptions={[5, 10, 20]}
              loading={loading}
              gridId="competitions-admin-scoreboard"
            />
          ) : (
            <Box>
              <ScoreboardHidden />
            </Box>
          )}
        </Box>
      </FdCard>
    </Box>
  );
};

Scoreboard.defaultProps = {
  isAdmin: false,
};

Scoreboard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  assessmentData: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allAttempts: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool,
  attemptsTimeline: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updatedCompetitionStatus: PropTypes.string.isRequired,
  serverCurrentTime: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onLeaderBoardSelection: PropTypes.func.isRequired,
};

export default Scoreboard;
