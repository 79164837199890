import React, { useState } from 'react';
import { Box, colors, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  FdTypography,
  FdModal,
  getAvatarName,
  getAvatarColor,
  FdAvatar,
  FdChip,
  FdAutocomplete,
} from '@fifthdomain/fe-shared';

const WorkRoleSelection = ({
  user,
  loading,
  openModal,
  onDismiss,
  listWorkRoles,
  assignedWorkRole,
  onConfirm,
}) => {
  const { grey } = colors;
  const [selectedWorkRole, setSelectedWorkRole] = useState([]);

  return (
    <FdModal
      size="md"
      title="Edit Assigned Work Role"
      description="Edit the selected user's work role below. The selected work role will overlay onto their Cyber Skills Cortex, allowing visualisation of how their current skills align with their assigned work role’s requirements."
      confirm={loading ? 'Loading' : 'Save'}
      dismiss="CANCEL"
      open={openModal}
      onConfirm={() => {
        onConfirm(selectedWorkRole);
      }}
      onDismiss={() => {
        onDismiss();
      }}
      data-cy="open-modal"
    >
      <Box mt={3} display="flex" flexDirection="column" width="100%">
        <FdTypography variant="subtitle1">Selected User</FdTypography>
        <Box className="flex items-center gap-x-2 mb-4">
          <FdAvatar
            color={getAvatarColor(user?.name)}
            size="small"
            variant="circular"
            content={getAvatarName(user?.name) || ''}
          />
          <FdTypography variant="body2">{user?.name}</FdTypography>
        </Box>
        <FdTypography variant="subtitle1">
          Currently Assigned Work Role
        </FdTypography>
        <Box mt={1} mb={3}>
          <FdChip
            label={assignedWorkRole[0]?.name || 'None Assigned'}
            rounded={false}
            style={{ backgroundColor: grey[100] }}
          />
        </Box>
        <Divider />
        <Box mt={3} mb={1}>
          <FdTypography variant="subtitle1">
            Select New Assigned Work Role
          </FdTypography>
        </Box>
        <FdAutocomplete
          label=""
          placeholder="Select Work Role"
          options={listWorkRoles}
          fullWidth
          optionLabel="name"
          optionValue="id"
          defaultSelected={assignedWorkRole}
          renderOption={(option) => (
            <FdChip label={option?.name} color="default" rounded={false} />
          )}
          onChange={(currentSelection) => {
            setSelectedWorkRole(currentSelection);
          }}
        />
      </Box>
    </FdModal>
  );
};
WorkRoleSelection.defaultProps = {
  loading: false,
  onDismiss: () => {},
  onConfirm: () => {},
};

WorkRoleSelection.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool,
  openModal: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
  listWorkRoles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  assignedWorkRole: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};
export default WorkRoleSelection;
