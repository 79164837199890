import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { isValid as isValidDate } from 'date-fns';
import singleSpa from 'single-spa';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@material-ui/core';
import {
  FdTypography,
  FdModal,
  FdIconsV5,
  FdTextField,
  FdDateTimePicker,
  FdAlert,
  globalStore,
  useSnapshot,
} from '@fifthdomain/fe-shared';
import UserRole from './UserRole';
import {
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import TrainingIcon from './TrainingIcon';
import {
  addUsers,
  createModulePart,
  createNewAssessment,
} from '../../graphql/mutations';
import { getLabBasedTasks } from '../../shared/utils/taskUtils';
import { getSystemTime } from '../../graphql/queries';

const OutComeLabel = ({ value, label, valueColor }) => (
  <Box className="flex items-center gap-2">
    <FdTypography
      variant="body1"
      style={{ color: valueColor, fontWeight: 500 }}
    >
      {value}
    </FdTypography>
    <FdTypography variant="body2">{label}</FdTypography>
  </Box>
);

OutComeLabel.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  valueColor: PropTypes.string.isRequired,
};

const CreateModal = ({
  allTasks,
  userName,
  showModal,
  setShowModal,
  email,
  userId,
  workRoleName,
  data,
}) => {
  const [saveInProgress, setSaveInProgress] = useState(false);
  const { control, setValue, trigger, getValues, reset } = useFormContext();
  const globalSnap = useSnapshot(globalStore);
  const { East: EastIcon } = FdIconsV5;

  const [createNewAssessmentMutation] = useMutation(gql(createNewAssessment));
  const [createModulePartMutation] = useMutation(gql(createModulePart));

  const [addUsersMutation] = useMutation(gql(addUsers), {
    onCompleted: () => {
      setSaveInProgress(false);
      setShowModal(false);
      successToastMessage('Training activity created successfully!');
      reset();
      singleSpa.navigateToUrl(`/competitions/affiliated-view-user/${userId}`);
    },
    onError: (err) => {
      errorToastMessage(err);
    },
  });

  const [getServerTime] = useLazyQuery(gql(getSystemTime));

  const onSave = async () => {
    setValue('startDateTime', new Date());
    const isValid = await trigger();
    if (isValid) {
      setSaveInProgress(true);
      const { name, tasks, endDateTime, jumpBox, preMessage, postMessage } =
        getValues();
      const taskIds = tasks?.map((t) => t?.taskId);
      const labBasedSelectedTasks = getLabBasedTasks(allTasks, taskIds).map(
        (labBasedTask) => ({
          ...labBasedTask,
        }),
      );
      const hasLabBasedTasks = labBasedSelectedTasks.length > 0;
      // create module parts for lab based tasks
      const modulePartsPromises = labBasedSelectedTasks?.map(
        (labBasedTask, index) => {
          const { name: labName, description, labId, id } = labBasedTask;

          return createModulePartMutation({
            variables: {
              input: {
                courseModuleId: id,
                description,
                name: labName,
                orderNumber: index + 1,
                type: 'LAB',
                labId,
              },
            },
          });
        },
      );
      Promise.all(modulePartsPromises).then((modulePartsData) => {
        const modulePartIds = modulePartsData.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.data.createModulePart.courseModuleId]:
              curr.data.createModulePart.id,
          }),
          {},
        );

        getServerTime({
          fetchPolicy: 'network-only',
          onCompleted: (_serverTime) => {
            const trainingStartDate = new Date(
              _serverTime?.getSystemTime,
            ).toISOString();

            createNewAssessmentMutation({
              variables: {
                assesssment: {
                  name,
                  startDateTime: trainingStartDate,
                  status: 'READY',
                  endDateTime,
                  enableJumpbox: jumpBox?.toString()?.toUpperCase() || 'FALSE',
                  enableVPN: 'TRUE',
                  taskIds: hasLabBasedTasks ? [] : tasks.map((t) => t.taskId),
                  taskLabs: hasLabBasedTasks
                    ? taskIds.map((taskId) => ({
                        taskId,
                        modulePartId: modulePartIds[taskId],
                      }))
                    : [],
                  orgId: globalSnap?.orgId,
                  participantEventType: 'TRAINING',
                  teamBased: false,
                  availabilityType: 'AUTOMATIC',
                  preMessage,
                  postMessage,
                },
              },
              onCompleted: async (_data) => {
                const trainingId = _data?.createNewAssessment;
                // add user
                addUsersMutation({
                  variables: {
                    emails: [email],
                    orgId: globalSnap?.orgId,
                    type: 'PARTICIPANT',
                    assessmentId: trainingId,
                    participantEventType: 'TRAINING',
                  },
                });
              },
            });
          },
        });
      });
    }
  };

  return (
    <FdModal
      size="lg"
      title="Great! This training activity is almost ready."
      description=""
      dismiss="Cancel"
      confirm="Create Training Activity"
      open={showModal}
      onDismiss={() => {
        setShowModal(false);
        warningToastMessage('Training activity not created');
      }}
      disableConfirm={saveInProgress}
      confirmLoading={saveInProgress}
      onConfirm={onSave}
    >
      <FdTypography variant="body2" color="secondary">
        To finalise training activity creation, fill in the required details
        below. You can always edit these details later.
      </FdTypography>
      <Box className="flex flex-col mt-2 gap-y-4">
        <Box>
          <FdTypography variant="subtitle1">Assigned User</FdTypography>
          <UserRole name={userName} userRole={workRoleName} />
        </Box>
        <Box>
          <FdTypography variant="subtitle1">
            Training Activity Outcomes
          </FdTypography>
          <Box className="flex mt-3 gap-x-4">
            <OutComeLabel
              value={data?.fullFilledSkillsCount}
              label="Fulfilled Skills (Current)"
              valueColor="rgba(105, 240, 174, 1)"
            />
            <EastIcon style={{ opacity: '0.7' }} />
            <OutComeLabel
              value={data?.fullFilledSkillsCountPostTraining}
              label={
                <Box className="flex items-center">
                  <span>
                    {`Fulfilled Skills (Post-Training) (${data?.tasksCount} x`}
                  </span>
                  <TrainingIcon />
                  <span>)</span>
                </Box>
              }
              valueColor="rgba(105, 240, 174, 1)"
            />
          </Box>
          <Box className="flex my-3 gap-x-4">
            <OutComeLabel
              value={data?.unfulfilledSkillsCount}
              label="Unfulfilled Skills (Current)"
              valueColor="rgba(239, 154, 154, 1)"
            />
            <EastIcon style={{ opacity: '0.7' }} />
            <OutComeLabel
              value={data?.unfulfilledSkillsCountPostTraining}
              label="Unfulfilled Skills (Post-Training)"
              valueColor="rgba(239, 154, 154, 1)"
            />
          </Box>
          <Divider />
          <Box className="flex flex-col my-2 gap-y-1">
            <Controller
              control={control}
              name="name"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id="name"
                    label="Training Activity Name"
                    required
                    fullWidth
                    error={error}
                    placeholder="Training Activity - <Work Role Name> - <User Name> - #No."
                    helperText={
                      error
                        ? error.message
                        : 'This training activity will appear under its designated name in your library. This name will be displayed to the assigned user.'
                    }
                    disabled={saveInProgress}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            <Box className="mb-6">
              <Controller
                control={control}
                name="endDateTime"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <FdDateTimePicker
                    label="Training Activity End"
                    value={rest.value}
                    disablePast
                    minDateMessage="Training Activity End date cannot be in the past"
                    helperText={
                      rest.value && !isValidDate(rest.value)
                        ? 'Training Activity End date and time must be entered in the format “DD/MM/YYYY HH:MM”'
                        : (error && error.message) ||
                          'The assigned user will not be able to attempt the training activity after this date.'
                    }
                    disabled={saveInProgress}
                    error={error}
                    {...rest}
                    inputRef={ref}
                    style={{ '&:p': { width: 'max-content', marginLeft: 0 } }}
                  />
                )}
              />
            </Box>
            <FdAlert
              variant="info"
              message="Note: Training Activity End date and time must be entered in the format “DD/MM/YYYY HH:MM”"
            />
          </Box>
          <Box className="flex flex-col gap-y-2 mt-2">
            <FdTypography variant="subtitle1">Jump Box</FdTypography>
            <Controller
              control={control}
              name="jumpBox"
              render={({
                field: { ref, value: fieldValue, ...rest },
                fieldState: { error },
              }) => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id="jumpBox"
                        checked={fieldValue}
                        value={fieldValue?.value}
                        error={error && error.message}
                        data-cy="jumpBox"
                        {...rest}
                        onChange={(e) => {
                          setValue('jumpBox', e.target.checked);
                        }}
                        disabled={saveInProgress}
                        helperText="When enabled, the assigned user will be able to connect to a Jump Box which allows them to access lab resources securely."
                        inputRef={ref}
                      />
                    }
                    label="Enable Jump Box"
                  />
                </FormGroup>
              )}
            />
            <FdTypography variant="captiontext1" color="secondary">
              When enabled, the assigned user will be able to connect to a Jump
              Box which allows them to access lab resources securely.
            </FdTypography>
            <FdAlert
              variant="info"
              message="Jump Box enablement settings cannot be changed once a training activity is created. Please confirm your desired settings before clicking ‘Create Training Activity’. If enabled, participants will have access to a Jump Box for the entire duration of the event."
            />
          </Box>
        </Box>
      </Box>
    </FdModal>
  );
};

CreateModal.propTypes = {
  userName: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  allTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userId: PropTypes.string.isRequired,
  workRoleName: PropTypes.string.isRequired,
  data: PropTypes.shape({
    fullFilledSkillsCount: PropTypes.string.isRequired,
    unfulfilledSkillsCount: PropTypes.string.isRequired,
    fullFilledSkillsCountPostTraining: PropTypes.string.isRequired,
    unfulfilledSkillsCountPostTraining: PropTypes.string.isRequired,
    tasksCount: PropTypes.number.isRequired,
  }).isRequired,
};

export default CreateModal;
