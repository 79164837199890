import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';

const BarGraphLegend = ({ data }) => {
  const { specialty, solved, totalTasks, pointsScored, totalPoints } =
    data || {};
  return (
    <Box ml={1} mt={3} width="150px">
      {specialty ? (
        <Box>
          <Box width="150px">
            <FdTypography variant="subtitle1">{specialty}</FdTypography>
          </Box>
          <Box mt={1}>
            <FdTypography variant="captiontext2">
              Challenges solved
            </FdTypography>
          </Box>
          <Box>
            <FdTypography variant="subtitle2">{`${solved}/${totalTasks}`}</FdTypography>
          </Box>
          <Box mt={2}>
            <FdTypography variant="captiontext2">Points scored</FdTypography>
          </Box>
          <Box>
            <FdTypography variant="subtitle2">{`${pointsScored}/${totalPoints}`}</FdTypography>
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" height="100%">
          <FdTypography variant="captiontext1">
            Click the bar to display specialty details.
          </FdTypography>
        </Box>
      )}
    </Box>
  );
};

BarGraphLegend.propTypes = {
  data: PropTypes.shape({
    category: PropTypes.string,
    solved: PropTypes.number,
    totalTasks: PropTypes.number,
    pointsScored: PropTypes.number,
    totalPoints: PropTypes.number,
  }),
};

BarGraphLegend.defaultProps = {
  data: {},
};

export default BarGraphLegend;
