import React, { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isValid as isValidDate } from 'date-fns';
import {
  FdCard,
  FdDateTimePicker,
  FdTypography as Typography,
  FdRadioGroup,
  FdAlert,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import { AVAILABILITY_TYPES } from '../../constants';
import { getDateTimeZoneFormatted } from '../../shared/utils/dateUtils';

const Availability = ({
  competitionStatus,
  heading,
  showEdit,
  editAvailability,
  isDuplicateMode,
}) => {
  const { control, trigger } = useFormContext();
  const watchAvailabilityType = useWatch({
    control,
    name: 'availabilityType',
  });
  const watchStartDateTime = useWatch({
    control,
    name: 'startDateTime',
  });
  const watchEndDateTime = useWatch({
    control,
    name: 'endDateTime',
  });

  // force trigger to validate, as react-hook-form sends old error state
  useEffect(() => {
    if (watchStartDateTime && !showEdit) {
      trigger(['startDateTime', 'endDateTime']);
    }
  }, [watchStartDateTime, trigger, showEdit]);

  useEffect(() => {
    if (watchEndDateTime && !showEdit) {
      trigger(['startDateTime', 'endDateTime']);
    }
  }, [watchEndDateTime, trigger, showEdit]);

  return (
    <FdCard
      variant="outlined"
      heading={heading || 'Availability'}
      subHeading={`Select when the ${
        isDuplicateMode ? 'duplicate ' : ''
      } competition will start and end.`}
    >
      {isDuplicateMode &&
        watchAvailabilityType.value === AVAILABILITY_TYPES.AUTOMATIC && (
          <Box my={2}>
            <FdAlert
              variant="info"
              message={
                <Typography variant="body2">
                  Start and End Dates and Times have not been carried forward
                  from the original competition being duplicated. Please select
                  new Dates and Times as required.
                </Typography>
              }
            />
          </Box>
        )}

      {watchAvailabilityType.value === AVAILABILITY_TYPES.AUTOMATIC && (
        <Box mt={2} mb={2}>
          <FdAlert
            variant="info"
            message="You cannot restart a competition after its end date has been reached, so please ensure that the Competition End is far enough into the future. "
          />
        </Box>
      )}

      <Box display="flex" flexDirection="column">
        <Controller
          control={control}
          name="availabilityType"
          render={({
            field: { ref, value: fieldValue, ...rest },
            fieldState: { error },
          }) => (
            <FdDelayed>
              <Box mt={1} mb={2} ml={1}>
                <FdRadioGroup
                  id="availabilityType"
                  label="Availability Type"
                  options={[
                    AVAILABILITY_TYPES.MANUAL,
                    AVAILABILITY_TYPES.AUTOMATIC,
                  ]}
                  defaultValue={fieldValue.value}
                  fullWidth
                  error={error && error.message}
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            </FdDelayed>
          )}
        />
      </Box>
      {watchAvailabilityType?.value === AVAILABILITY_TYPES.AUTOMATIC && (
        <Box>
          <Controller
            control={control}
            name="startDateTime"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <>
                {!showEdit && editAvailability ? (
                  <>
                    <Typography variant="subtitle1">
                      Competition Start
                    </Typography>
                    {rest?.value && (
                      <Typography variant="body1" color="secondary">
                        {getDateTimeZoneFormatted(rest.value)}
                      </Typography>
                    )}
                  </>
                ) : (
                  <FdDateTimePicker
                    label="Competition Start (optional)"
                    value={rest.value}
                    disablePast
                    minDateMessage="Start Date cannot be in the past"
                    disabled={
                      competitionStatus === 'In Progress' &&
                      watchAvailabilityType.value === 'Automatic'
                    }
                    helperText={
                      rest.value && !isValidDate(rest.value)
                        ? 'Start Date and Time must be entered in the format “DD/MM/YYYY HH:MM”'
                        : (error && error.message) ||
                          'Participants will not be able to access the competition until this date'
                    }
                    error={error}
                    {...rest}
                    inputRef={ref}
                  />
                )}
              </>
            )}
          />
          <Box mt={6} mb={3}>
            <Controller
              control={control}
              name="endDateTime"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <FdDateTimePicker
                  label="Competition End"
                  value={rest.value}
                  disablePast
                  minDateMessage="End Date cannot be in the past"
                  helperText={
                    rest.value && !isValidDate(rest.value)
                      ? 'End Date and Time must be entered in the format “DD/MM/YYYY HH:MM”'
                      : (error && error.message) ||
                        'Participants will not be able to access the competition after this date'
                  }
                  error={error}
                  {...rest}
                  inputRef={ref}
                />
              )}
            />
          </Box>
        </Box>
      )}
    </FdCard>
  );
};

Availability.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  showEdit: PropTypes.bool,
  editAvailability: PropTypes.bool,
  isDuplicateMode: PropTypes.bool.isRequired,
  competitionStatus: PropTypes.string.isRequired,
};

Availability.defaultProps = {
  heading: 'Availability',
  showEdit: false,
  editAvailability: undefined,
};

export default Availability;
