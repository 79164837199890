import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton } from '@material-ui/core';
import {
  FdTypography,
  FdModal,
  FdAlert,
  FdTable,
  useQueryRecursive,
  FdSkeleton,
  FdTooltip,
  FdIconsV5,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { listTeams } from '../../graphql/queries';
import NoResultsSvg from '../../shared/images/no-search-results.svg';
import {
  initialValues,
  validationSchema,
} from '../../validation-schemas/Team/inviteTeam';
import { manageTeamEvents } from '../../graphql/mutations';
import {
  errorToastMessage,
  successToastMessage,
} from '../../shared/utils/toast';
import { getTeamsWithSharedEmails } from '../../shared/utils/teamUtils';
import { getCommaSeparated } from '../../shared/utils/stringUtils';

const NoResultsOverlay = () => (
  <Box className="flex flex-col">
    <img src={NoResultsSvg} alt="no-results" style={{ height: '120px' }} />
    <FdTypography variant="subtitle2">
      Use the search bar to populate teams
    </FdTypography>
  </Box>
);

const InviteTeams = ({
  assessmentId,
  teamSize,
  teamsInCompetition,
  openModal,
  onConfirm,
  setOpenModal,
  onDismiss,
}) => {
  const { InfoOutlined } = FdIconsV5;
  const globalSnap = useSnapshot(globalStore);

  const privateValue = globalSnap?.isAAFCOrg ? { eq: true } : { ne: true };
  const { data: listTeamsData, loading: listTeamsLoading } = useQueryRecursive(
    gql(listTeams),
    {
      variables: {
        limit: 1000,
        filter: {
          private: privateValue,
        },
      },
    },
  );
  const [manageTeamEventsMutation, { loading: manageTeamEventsLoading }] =
    useMutation(gql(manageTeamEvents));
  // if team is private then only show when creator's org id matches with current
  const teamsData =
    listTeamsData?.listTeams?.items.filter((t) =>
      t.private ? t?.creator?.orgId === globalSnap.orgId : true,
    ) || [];
  const teamIdsInCompetition = teamsInCompetition.map((t) => t.team?.id);
  const teamMembersInCompetition = teamsInCompetition
    .map((t) => t.team?.members?.items.map((tm) => tm?.email))
    .flat();

  const {
    control,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { fields: teams, replace: replaceTeams } = useFieldArray({
    control,
    name: 'teams',
  });

  const columns = [
    {
      field: 'teamName',
      flex: 1,
      headerName: 'Team Name',
      valueGetter: (row) => row?.row?.teamName,
      renderCell: (params) => (
        <FdTypography
          variant="body2"
          color={params.row.disabled ? 'secondary' : 'primary'}
        >
          {params.value}
        </FdTypography>
      ),
    },
    {
      field: 'memberCount',
      type: 'number',
      flex: 1,
      headerName: 'Member Count',
      filterable: false,
      headerAlign: 'left',
      align: 'left',
      valueGetter: (row) => row?.value,
      renderCell: (params) => {
        const color = params.row.disabled ? 'secondary' : 'primary';
        return (
          <Box className="flex items-center">
            <FdTypography variant="body2" color={color}>
              {params.value}
            </FdTypography>
            {params.row.message && (
              <Box className="flex items-center">
                <FdTypography
                  variant="captiontext1"
                  color={color}
                  style={{ marginLeft: '3px' }}
                >
                  {`- ${params.row.message}`}
                </FdTypography>
                {params.row.tooltip && (
                  <FdTooltip title={params.row.tooltip}>
                    <IconButton size="small">
                      <InfoOutlined />
                    </IconButton>
                  </FdTooltip>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  const getTeamStatus = (team) => {
    let disabled = false;
    let message = '';
    let tooltip;
    // team already exists
    const teamAlreadyExist = teamIdsInCompetition.includes(team?.id);
    if (teamAlreadyExist) {
      disabled = true;
      message = 'already invited';
      return { disabled, message };
    }
    // team count based
    const teamCount = team?.members?.items.length || 0;
    if (teamCount === 0 || teamCount > teamSize) {
      disabled = true;
      if (teamCount === 0) {
        message = 'no team members';
      } else {
        message = 'exceeds max team size';
      }
      return { disabled, message };
    }
    // overlapping team members
    const overlappingTeamMembers = team?.members?.items.some((tm) =>
      teamMembersInCompetition.includes(tm.email),
    );
    if (overlappingTeamMembers) {
      disabled = true;
      message = 'competing team members';
      tooltip =
        'This team cannot be invited as some members are already in the competition as part of other teams.';
      return { disabled, message, tooltip };
    }
    return { disabled, message };
  };

  return (
    <form>
      <FdModal
        size="lg"
        title={
          <Box>
            <FdTypography variant="h3">
              Invite Existing FifthDomain Teams
            </FdTypography>
            <FdTypography variant="body2" color="secondary">
              You can search for all teams with at least one registered user
              across the entire FifthDomain platform below, and invite them to
              participate in this competition.
            </FdTypography>
            <Box mt={1} mb={1}>
              <FdTypography variant="subtitle2">{`Max Team Size: ${teamSize}`}</FdTypography>
            </Box>
            <FdAlert
              variant="info"
              message={
                <Box>
                  <FdTypography variant="subtitle1">
                    You can only invite teams which do not exceed the set max
                    team size limit.
                  </FdTypography>
                  <FdTypography variant="body2">
                    Teams which exceed this number will still be visible in your
                    search results, but will not be selectable for invitation.
                    If required, you can adjust the max team size set for this
                    competition via the &apos;Details&apos; tab.
                  </FdTypography>
                </Box>
              }
            />
            {errors?.teams?.type === 'min' && (
              <Box mt={1}>
                <FdAlert
                  variant="error"
                  message="Please select at least one team to continue."
                />
              </Box>
            )}
          </Box>
        }
        dismiss="CANCEL"
        confirm="Invite Selected Team(s)"
        open={openModal}
        onDismiss={() => {
          reset();
          setOpenModal(false);
          onDismiss();
        }}
        onConfirm={async () => {
          const valid = await trigger();
          if (valid) {
            manageTeamEventsMutation({
              variables: {
                action: 'ADD',
                teams: teams?.map((t) => t?.teamId),
                participantEventType: 'COMPETITION',
                eventId: assessmentId,
              },
              onCompleted: (_data) => {
                setOpenModal(false);
                successToastMessage(_data?.manageTeamEvents?.message);
                reset();
                onConfirm();
              },
              onError: ({ graphQLErrors }) => {
                setOpenModal(false);
                errorToastMessage(graphQLErrors[0]?.message);
              },
            });
          }
        }}
        data-cy="invite-teams-modal"
      >
        <Box>
          <FdSkeleton height="428px" loading={listTeamsLoading}>
            <Box height="428px">
              <FdTable
                toolbarSettings={{
                  title: 'Existing FifthDomain Teams',
                  subTitle:
                    "Search teams by their name. Select teams to invite, and click 'Invite Selected Team(s)' to send invitations.",
                  filterButton: true,
                  searchBox: true,
                }}
                density="compact"
                actions={[]}
                rows={teamsData?.map((t) => {
                  const { disabled, message, tooltip } = getTeamStatus(t);
                  return {
                    id: t?.id,
                    teamName: t?.name,
                    memberCount: t?.members?.items.length || 0,
                    disabled,
                    message,
                    tooltip,
                  };
                })}
                columns={columns}
                selectionModel={teams?.map((t) => t?.teamId)}
                onSelectionModelChange={(selectedTeamIds) => {
                  replaceTeams(
                    teamsData
                      ?.filter((td) => selectedTeamIds.includes(td?.id))
                      ?.map((td) => ({
                        teamId: td?.id,
                        teamName: td?.name,
                        emails: td?.members?.items.map((tm) => tm?.email),
                      })) || [],
                  );
                }}
                loading={manageTeamEventsLoading}
                isRowSelectable={(params) => !params.row.disabled}
                pagination
                selection
                visibleSelection
                rowsPerPageOptions={[5, 10, 20]}
                tablePageSize={10}
                noResultsMessage={<NoResultsOverlay />}
                noRowsMessage={<NoResultsOverlay />}
                showRowsOnlyOnSearch
                gridId="competitions-invited-teams"
              />
            </Box>
          </FdSkeleton>
          {errors?.teams?.type === 'unique-email' && (
            <Box mt={1}>
              <FdAlert
                variant="error"
                alertTitle="A participant cannot be in a competition with multiple teams. There are overlapping team members for:"
                message={
                  <ul style={{ listStyle: 'disc' }}>
                    {getTeamsWithSharedEmails(teams)?.map((e) => (
                      <li>{getCommaSeparated(e?.teams)}</li>
                    ))}
                  </ul>
                }
              />
            </Box>
          )}
          <Box mt={1}>
            <FdTypography variant="subtitle1">
              {`Teams selected for invitation (${teams?.length})`}
            </FdTypography>
            <FdTypography variant="body1" style={{ marginTop: '0.5rem' }}>
              {teams.length > 0
                ? teams?.map((t) => t?.teamName).join(', ')
                : 'No teams selected'}
            </FdTypography>
          </Box>
        </Box>
      </FdModal>
    </form>
  );
};

InviteTeams.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  teamSize: PropTypes.string.isRequired,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  teamsInCompetition: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default InviteTeams;
