import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ResponsiveTreeMap } from '@nivo/treemap';

const TreeMap = ({ data, groupedCompetencies }) => {
  const theme = useTheme();
  const customTheme = {
    ...theme.nivoGraph,
    tooltip: {
      container: {
        fontFamily: 'Montserrat, sans-serif',
        background: '#333',
      },
    },
  };

  return (
    <Box width="100%">
      <Box
        display="flex"
        justifyContent="center"
        style={{ height: '500px', margin: 'auto' }}
      >
        <ResponsiveTreeMap
          theme={customTheme}
          root={data}
          identity="name"
          value="value"
          innerPadding={2}
          outerPadding={2}
          margin={{
            top: 10,
            right: 10,
            bottom: 10,
            left: 10,
          }}
          label="id"
          labelSkipSize={40}
          labelTextColor={{ from: 'color', modifiers: [['darker', 2.5]] }}
          borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
          colors={(d) => d.color}
          isInteractive
          leavesOnly
          tooltip={(d) => {
            const { achieved } = d.data;
            const tooltip = achieved
              ? `The participant have obtained ${
                  d.data.competenciesAchieved
                } out of ${
                  groupedCompetencies[d.data.name]
                    ? groupedCompetencies[d.data.name].length
                    : ''
                } competencies in ${d.data.name} concept.`
              : `The participant could obtain ${
                  d.value
                } more competencies out of ${
                  groupedCompetencies[d.data.name]
                    ? groupedCompetencies[d.data.name].length
                    : ''
                } in ${d.data.name} concept.`;
            return (
              <Typography style={{ color: '#fff' }} variant="subtitle2">
                {tooltip}
              </Typography>
            );
          }}
        />
      </Box>
    </Box>
  );
};

TreeMap.propTypes = {
  data: PropTypes.object.isRequired,
  groupedCompetencies: PropTypes.object.isRequired,
};

export default TreeMap;
