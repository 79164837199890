import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import {
  FdTable,
  FdTypography,
  FdLoadingSpinner,
} from '@fifthdomain/fe-shared';
import { getTeam } from '../../graphql/queries';

const TeamMembers = ({ assessmentData }) => {
  const { data: teamMembersData, loading: teamMembersDataLoading } = useQuery(
    gql(getTeam),
    {
      variables: {
        id: assessmentData?.teamId,
      },
      skip: !assessmentData?.teamId,
    },
  );

  if (teamMembersDataLoading) {
    return <FdLoadingSpinner />;
  }

  const teamMembersCount =
    teamMembersData?.getTeam?.members?.items?.length || 0;
  const userTeamMembers =
    teamMembersData?.getTeam?.members?.items
      ?.filter(
        (member) =>
          member.User && !member.User?.id.includes(assessmentData.userId),
      )
      ?.map((t) => ({ id: t?.User?.id, alias: t?.User?.alias })) || [];

  const columns = [
    { field: 'alias', flex: 1, headerName: 'Registered Team Member Aliases' },
  ];
  const teamName = teamMembersData?.getTeam?.name || 'Team Members';

  return (
    userTeamMembers?.length > 0 && (
      <Box data-cy="team-members">
        <Box mb={2}>
          <FdTypography variant="h4" data-cy="team-members-title">
            {teamName}
          </FdTypography>
        </Box>
        <Box my={1}>
          <FdTypography variant="body2">{`Total Team Members: ${teamMembersCount}`}</FdTypography>
        </Box>
        <FdTable
          id="team-members"
          toolbarSettings={{
            hide: true,
          }}
          selection={false}
          actions={[]}
          rows={userTeamMembers}
          columns={columns}
          gridId="competitions-team-members"
          autoHeight
          hideFooter
        />
      </Box>
    )
  );
};

TeamMembers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  assessmentData: PropTypes.object.isRequired,
};

export default TeamMembers;
