import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

const getBadgeComponent = (type, tier, status) => {
  return lazy(() =>
    import(`./${type}/${status}/BadgeTier${tier?.value || tier}`),
  );
};

const TrophyBadge = ({ type, tier, status }) => {
  const BadgeTier = getBadgeComponent(type, tier, status);

  return (
    <Suspense fallback={<></>}>
      <BadgeTier />
    </Suspense>
  );
};

TrophyBadge.defaultProps = {
  type: 'penetration',
  tier: 1,
  status: 'unlocked',
};

TrophyBadge.propTypes = {
  type: PropTypes.string,
  tier: PropTypes.number,
  status: PropTypes.string,
};

export default TrophyBadge;
