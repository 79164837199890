import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import { FdTooltip, FdTypography, FdIcons } from '@fifthdomain/fe-shared';

const LabelWithTooltip = ({ label, tooltipText, variant }) => {
  const { InfoOutlined } = FdIcons;
  return (
    <Box className="flex items-center">
      <FdTypography variant={variant}>{label}</FdTypography>
      <FdTooltip title={tooltipText}>
        <IconButton size="small" style={{ marginLeft: '5px' }}>
          <InfoOutlined />
        </IconButton>
      </FdTooltip>
    </Box>
  );
};

LabelWithTooltip.propTypes = {
  label: PropTypes.node.isRequired,
  tooltipText: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

LabelWithTooltip.defaultProps = {
  variant: 'subtitle2',
};

export default LabelWithTooltip;
