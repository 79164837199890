import React from 'react';
import PropTypes from 'prop-types';
import singleSpa from 'single-spa';
import { Box, Divider } from '@material-ui/core';
import {
  FdTypography,
  FdCard,
  FdIconWithTooltip,
  FdAlert,
} from '@fifthdomain/fe-shared';
import UserRole from './UserRole';
import TasksEditTable from './TasksEditTable';

const Challenges = ({
  loading,
  trainingUser,
  skillsMapByProficiency,
  workRoleName,
  trainingStatus,
}) => {
  const editHidden = trainingStatus === 'Completed';

  return (
    <Box>
      <FdCard variant="outlined">
        <FdTypography variant="h3">Training Activity Outcomes</FdTypography>
        <Box className="flex flex-col gap-y-4 mt-2">
          <FdTypography variant="body2" color="secondary">
            This section displays the skills targeted for improvement in this
            training activity, based on the included challenges.
          </FdTypography>
          <FdTypography variant="body2" color="secondary">
            To be considered fulfilled, skills must be demonstrated at or above
            their target proficiency levels. Demonstration of skills below the
            target proficiency levels set for each skill in a work role does not
            affect skill fulfilment.
          </FdTypography>
        </Box>
        <Box my={3}>
          <FdTypography variant="subtitle1">Assigned User</FdTypography>
          <UserRole
            name={trainingUser?.user?.name}
            userRole={workRoleName}
            onClickViewProfile={() =>
              singleSpa.navigateToUrl(
                `/competitions/affiliated-view-user/${trainingUser?.userId}`,
              )
            }
            viewProfile
          />
        </Box>
        <Divider />
        <Box mt={3} mb={1}>
          <FdTypography variant="subtitle1">Targeted Skills:</FdTypography>
        </Box>
        <FdTypography variant="body2" color="secondary">
          This training activity comprises challenges that target the following
          skills at specified proficiency levels:
        </FdTypography>
        <Box my={2}>
          <ul style={{ listStyleType: 'disc', marginLeft: '1rem' }}>
            {skillsMapByProficiency?.map((s) => (
              <li>
                <Box className="flex items-center gap-x-1">
                  <FdTypography variant="body2" style={{ fontWeight: 700 }}>
                    {s?.name}
                  </FdTypography>
                  <FdTypography variant="body2" color="secondary">
                    {s?.proficiencies}
                  </FdTypography>
                </Box>
              </li>
            ))}
          </ul>
        </Box>
      </FdCard>
      <FdCard variant="outlined" className="mt-4">
        <Box className="flex items-center gap-x-1 mb-1">
          <FdTypography variant="h3">
            Recommended Challenges in your Library
          </FdTypography>
          <FdIconWithTooltip title="Only challenges associated with required skills the user has not yet demonstrated at Level 3 (Competent) or higher are recommended below. Additionally, only challenges above the user's demonstrated proficiency in a required skill are shown." />
        </Box>
        <FdTypography variant="body2" color="secondary">
          Challenges included in this training activity are listed below. Click
          &apos;Edit&apos; to modify the selection. You can only add challenges
          that align with the assigned user&apos;s currently unfulfilled skills
          and proficiency levels in their current work role. Once a challenge is
          removed, it cannot be re-added if it no longer matches the user&apos;s
          required but unfulfilled skills, or if the user has already
          demonstrated the skill at the challenge&apos;s proficiency level.
        </FdTypography>
        <FdAlert
          style={{ marginTop: '8px' }}
          variant="info"
          message={
            <FdTypography variant="body2">
              All labs in lab-based challenges have a duration of 2 hours.
              Participants can extend their lab instances as many times as they
              require, resetting their lab timers to 2 hours and retaining
              progress made. Participants can also reset their labs as needed,
              which will deploy a new lab instance and erase all progress.
            </FdTypography>
          }
        />
        <TasksEditTable loading={loading} editHidden={editHidden} />
      </FdCard>
    </Box>
  );
};

Challenges.propTypes = {
  loading: PropTypes.bool.isRequired,
  trainingUser: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
  skillsMapByProficiency: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workRoleName: PropTypes.string.isRequired,
  trainingStatus: PropTypes.string.isRequired,
};

export default Challenges;
