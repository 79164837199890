import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Divider, Chip, makeStyles } from '@material-ui/core';
import { FdTypography, FdChip } from '@fifthdomain/fe-shared';
import { PROFICIENCY } from '../../constants';
import { getParticipantAssessmentStatusColor } from '../../shared/utils/getStatusColor';

const useStyles = makeStyles(() => ({
  condensedChip: {
    fontSize: 10,
    lineHeight: 14,
    borderRadius: '4px',
    height: '14px',
    fontWeight: 500,
  },
}));

const TaskDrawerHeaderParticipant = ({
  activeTask,
  taskStatus,
  taskAttempts,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box mb={2} className="flex gap-2">
        <Chip
          style={{
            backgroundColor: PROFICIENCY[activeTask?.difficulty]?.color,
            color: '#000',
          }}
          size="small"
          label={activeTask?.difficulty}
          className={classes.condensedChip}
        />
        <Chip
          size="small"
          label={`${activeTask?.recommendedPoints} pts`}
          className={classes.condensedChip}
        />
      </Box>
      <Card variant="outlined" className="py-3">
        <Box className="flex w-full justify-around">
          <Box className="flex flex-col items-center">
            <FdTypography variant="body1">{activeTask?.specialty}</FdTypography>
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '7px' }}
            >
              Specialty
            </FdTypography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className="flex flex-col items-center">
            <FdChip
              color={getParticipantAssessmentStatusColor(taskStatus)}
              size="medium"
              label={taskStatus}
              style={{ height: '24px' }}
            />
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '7px' }}
            >
              Status
            </FdTypography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className="flex flex-col items-center">
            <FdTypography variant="subtitle1">{taskAttempts}</FdTypography>
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '7px' }}
            >
              Attempts
            </FdTypography>
          </Box>
        </Box>
      </Card>
    </>
  );
};

TaskDrawerHeaderParticipant.propTypes = {
  activeTask: PropTypes.shape({
    specialty: PropTypes.string,
    type: PropTypes.string,
    solves: PropTypes.number,
    recommendedPoints: PropTypes.number,
    difficulty: PropTypes.string,
  }).isRequired,
  taskStatus: PropTypes.string.isRequired,
  taskAttempts: PropTypes.string.isRequired,
};

export default TaskDrawerHeaderParticipant;
