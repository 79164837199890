import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import {
  BasePage,
  FdLoadingSpinner,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { getUserById, listaccomplishmentByUserId } from '../graphql/queries';
import UserProfile from '../components/User/UserProfile';

const AffiliatedUserProfile = () => {
  const { userId } = useParams();
  const { data: userData, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: userId,
    },
    skip: !userId,
  });
  const selectedUser = userData?.getUserById?.items[0];

  const {
    data: listaccomplishmentByUserIdLoadingData,
    loading: listaccomplishmentByUserIdLoading,
  } = useQueryRecursive(gql(listaccomplishmentByUserId), {
    variables: {
      userId: selectedUser?.id,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !selectedUser?.id,
  });

  if (userLoading || listaccomplishmentByUserIdLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <BasePage
      breadCrumbs={[{ name: 'Home', url: '/user-management/affiliated-users' }]}
      currentPageBreadcrumbLabel={`Users / Affiliated / View ${selectedUser?.name}`}
      linkComponent={RouterLink}
      renderBreadCrumbAsButton
      data-cy="user-profile-card"
    >
      <UserProfile
        selectedUser={selectedUser}
        accomplishmentData={listaccomplishmentByUserIdLoadingData}
        affiliated
      />
    </BasePage>
  );
};

export default AffiliatedUserProfile;
