import { isValid as isDateValid, isFuture } from 'date-fns';
import Yup from '../Common';
import preMessageDefault from '../../components/Assessment/preMessageDefault';
import { AVAILABILITY_TYPES, ASSESSMENT_TYPES } from '../../constants';
import { getArrayByLength } from '../../shared/utils/objectUtils';

export const initialValues = {
  assessmentType: '',
  multiLevel: false,
  maxTeamSize: 8,
  levels: null,
  name: '',
  description: '',
  story: '',
  availabilityType: '',
  startDateTime: null,
  endDateTime: null,
  scoreboardVisibility: true,
  tasks: [],
  preMessage: preMessageDefault,
  postMessage: '',
  taskLabs: {},
};

export const validationSchema = Yup.object().shape(
  {
    assessmentType: Yup.object()
      .shape({ id: Yup.string(), value: Yup.string() })
      .nullable()
      .required('Please select an option'),
    maxTeamSize: Yup.number().when(['assessmentType'], {
      is: (assessmentType) =>
        assessmentType?.value === ASSESSMENT_TYPES.TEAM_BASED,
      then: Yup.number()
        .default(8)
        .required('Max team size cannot be less than 1.')
        .typeError('Max team size cannot be less than 1.')
        .min(1, 'Max team size cannot be less than 1.')
        .test(
          'is-decimal',
          'Please enter a valid number',
          (value) => !`${value}`.match(/^\d*\.{1}\d*$/),
        ),
      otherwise: Yup.number().nullable(),
    }),
    multiLevel: Yup.boolean().required(),
    scoreboardVisibility: Yup.boolean(),
    levels: Yup.number().when(['multiLevel'], {
      is: (multiLevel) => multiLevel,
      then: Yup.number()
        .required("Number of levels can't be less than 2.")
        .typeError("Number of levels can't be less than 2.")
        .min(2, "Number of levels can't be less than 2.")
        .test(
          'is-decimal',
          'Please enter a valid number',
          (value) => !`${value}`.match(/^\d*\.{1}\d*$/),
        )
        .test(
          'minLevelForExistingTasks',
          "Number of levels can't be reduced",
          (_levels, context) => {
            if (!context.parent.tasks) {
              return true;
            }
            const { tasks } = context.parent;
            const existingLevels = [
              ...new Set(tasks?.filter((t) => t.level)?.map((t) => t.level)),
            ];
            // levels should be greater than the existing challenge-levels
            return Number(_levels) >= Math.max(...existingLevels);
          },
        ),
      otherwise: Yup.number().nullable(),
    }),
    name: Yup.string()
      .required('Please enter a competition name')
      .max(150, 'Competition name must be 150 characters or less')
      .noWhitespace('Enter a valid competition name'),
    description: Yup.string(),
    story: Yup.string(),
    availabilityType: Yup.object()
      .shape({ id: Yup.string(), value: Yup.string() })
      .nullable()
      .required('Please select an availability type'),
    startDateTime: Yup.date().when(['endDateTime', 'availabilityType'], {
      is: (endDateTime, availabilityType) =>
        availabilityType?.value === AVAILABILITY_TYPES.AUTOMATIC &&
        endDateTime &&
        isDateValid(endDateTime),
      then: Yup.date()
        .min(new Date(), 'Start Date and Time cannot be in the past')
        .max(
          Yup.ref('endDateTime'),
          'Start Date and Time should be before End Date and Time',
        )
        .nullable(),
      otherwise: Yup.date().nullable(),
    }),
    endDateTime: Yup.date().when(['startDateTime', 'availabilityType'], {
      is: (startDateTime, availabilityType) => {
        const startDateTimeValue = startDateTime || new Date();
        return (
          availabilityType?.value === AVAILABILITY_TYPES.AUTOMATIC &&
          isDateValid(startDateTimeValue)
        );
      },
      then: Yup.date()
        .required('Please enter an End Date and Time')
        .typeError('Please enter an End Date and Time')
        .min(
          Yup.ref('startDateTime'),
          'The End Date and Time must occur after the Start Date and Time',
        )
        .test(
          'is-future',
          'End Date and Time cannot be in the past',
          (value) => {
            return isFuture(new Date(value));
          },
        ),
      otherwise: Yup.date().nullable(),
    }),
    tasks: Yup.array().when(['multiLevel'], {
      is: (multiLevel) => !multiLevel,
      then: Yup.array()
        .of(Yup.object().shape({ taskId: Yup.string() }))
        .min(1),
      otherwise: Yup.array()
        .of(
          Yup.object().shape({
            taskId: Yup.string(),
            level: Yup.number(),
          }),
        )
        .test(
          'minOneChallengeForEachLevel',
          'Minimum one challenge for each level',
          (_tasks, context) => {
            if (!context.parent.levels) {
              return true;
            }
            const { levels } = context.parent;
            if (levels < 2) {
              return true;
            }
            const allLevels = getArrayByLength(Number(levels), (_, i) => i + 1);

            const addedLevels = [...new Set(_tasks.map((t) => t.level))];
            // all levels are assigned or not
            return allLevels.every((el) => addedLevels.includes(el));
          },
        ),
    }),
    preMessage: Yup.string()
      .required('Please enter a pre-competition message')
      .noWhitespace('Please enter a valid pre-competition message'),
    videoUrl: Yup.string()
      .nullable()
      .max(1500, 'Vimeo URL must be less than 1500 characters.'),
    postMessage: Yup.string()
      .required('Please enter a post-competition message')
      .noWhitespace('Please enter a valid post-competition message'),
    taskLabs: Yup.lazy((value) => {
      const schema = Object.keys(value || {}).reduce((acc, curr) => {
        acc[curr] = Yup.string().trim().required();
        return acc;
      }, {});

      return Yup.object().shape(schema);
    }),
  },
  [['startDateTime', 'endDateTime']],
);
