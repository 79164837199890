import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import singleSpa from 'single-spa';
import { gql, useQuery } from '@apollo/client';
import {
  BasePage,
  FdLoadingSpinner,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import useGetAssessmentsTasks from '../hooks/useGetAssessmentsTasks';
import { getSystemTime, getAssessment } from '../graphql/queries';
import useStopAssessment from '../hooks/useStopAssessment';
import { onUpdateAssessment } from '../graphql/subscriptions';
import { getCompetitionStatus } from '../shared/utils/getParticipantStatus';
import useSubscription from '../hooks/useSubscription';
import FdSystemDateCountdown from '../components/FdSystemDateCountdown';
import Announcements from '../components/Chats/Announcements';
import notificationStore from '../store/notificationStore';

const AssessmentAnnouncements = () => {
  const { assessmentId } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const notificationSnap = useSnapshot(notificationStore);
  const [endDateTime, setEndDateTime] = useState(undefined);
  const { data: assessmentTasksData, loading: assessmentTasksLoading } =
    useGetAssessmentsTasks({ userId: globalSnap?.userId });

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  // get the assessment selected
  const assessmentDataFiltered =
    assessmentTasksData.listUserAssessments?.items.find(
      (ad) => ad.id === assessmentId,
    );

  // get Assessment EndDateTime
  const {
    data: assessmentData,
    loading: getAssessmentLoading,
    refetch: refetchAssessmentData,
  } = useQuery(gql(getAssessment), {
    variables: {
      id: assessmentDataFiltered?.userAssessmentAssessmentId,
    },
    onCompleted: (data) => {
      setEndDateTime(data?.getAssessment?.endDateTime);
    },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setTimeout(() => {
      refetchAssessmentData();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationSnap.announcementsRefreshPage]);

  const announcements =
    assessmentData?.getAssessment?.announcements?.items || [];

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment(assessmentDataFiltered?.assessment?.participantEventType);

  useSubscription({
    query: gql(onUpdateAssessment),
    variables: {
      filter: {
        id: { eq: assessmentDataFiltered?.userAssessmentAssessmentId },
      },
    },
    onData: (_data) => {
      const _endDateTime = _data?.value?.data?.onUpdateAssessment?.endDateTime;
      setEndDateTime(_endDateTime);

      if (_endDateTime) {
        // call stop user assessment if endDate is present
        // backend will stop the assessment based on server date time
        stopAssessment({
          variables: {
            userAssessmentId: assessmentId,
          },
        });
      }
    },
  });

  if (
    assessmentTasksLoading ||
    serverTimeLoading ||
    stopAssessmentInProgress ||
    getAssessmentLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const {
    assessment: { name, startDateTime },
  } = assessmentDataFiltered || { assessment: {} };

  const updatedCompetitionStatus = getCompetitionStatus(
    startDateTime,
    endDateTime,
    serverTime?.getSystemTime,
  );

  // redirect to homepage only if the competition is in not started state
  if (updatedCompetitionStatus === 'NOT_STARTED') {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  return (
    <BasePage
      heading="Announcements"
      data-cy="announcements-page"
      breadCrumbs={[{ url: '/landing/landing-homepage', name: 'Home' }]}
      currentPageBreadcrumbLabel={`${name} / Announcements`}
      renderBreadCrumbAsButton
      linkComponent={RouterLink}
    >
      {endDateTime && updatedCompetitionStatus !== 'ENDED' && (
        <FdSystemDateCountdown
          endDateTime={endDateTime}
          onFinish={() => {
            stopAssessment({
              variables: {
                userAssessmentId: assessmentId,
              },
            });
          }}
          renderer={({ completed }) => !completed && <></>}
        />
      )}
      <Box mt={1}>
        <Announcements
          announcements={announcements}
          assessmentId={assessmentDataFiltered?.userAssessmentAssessmentId}
          loading={getAssessmentLoading}
          viewOnly
        />
      </Box>
    </BasePage>
  );
};

export default AssessmentAnnouncements;
