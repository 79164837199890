import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { getDateTimeZoneCustom } from '../../shared/utils/dateUtils';
import getGraphTheme from '../../shared/utils/graphUtil';

const Line = ({
  data,
  colors,
  lineColors,
  xaxisDateTimeMin,
  xaxisDateTimeMax,
}) => {
  const theme = useTheme();
  const graphTheme = getGraphTheme(theme);
  const options = {
    series: data,
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        background: 'transparent',
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        row: {
          colors,
        },
      },
      stroke: {
        curve: 'straight',
      },
      legend: {
        show: true,
        position: 'right',
      },
      xaxis: {
        type: 'datetime',
        min: xaxisDateTimeMin,
        max: xaxisDateTimeMax,
        labels: {
          formatter: (value) =>
            getDateTimeZoneCustom(value, 'dd-EEE | HH:mm:ss'),
        },
      },
      tooltip: {
        followCursor: true,
      },
      theme: {
        mode: graphTheme,
      },
      ...lineColors,
    },
  };

  return (
    <Box>
      <Chart
        options={options.options}
        series={options.series}
        type="line"
        width="100%"
        height="350"
      />
    </Box>
  );
};

Line.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  xaxisDateTimeMin: PropTypes.number,
  xaxisDateTimeMax: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.string),
  lineColors: PropTypes.arrayOf(PropTypes.string),
};

Line.defaultProps = {
  colors: [],
  lineColors: [],
  xaxisDateTimeMin: undefined,
  xaxisDateTimeMax: undefined,
};

export default Line;
