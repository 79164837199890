/* eslint-disable no-param-reassign */
export const upperCaseFirstLetter = (str) =>
  str && str[0].toUpperCase() + str.substring(1).toLowerCase();

export const initCaps = (str, replaceUnderscores = false) => {
  let modifiedStr = str;
  if (replaceUnderscores) {
    modifiedStr = modifiedStr.replace(/_/g, ' ');
  }
  if (!modifiedStr) return '';
  return modifiedStr
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
    .join(' ');
};

export const getCommaSeparated = (arr, includeQuotes = false) => {
  const arrQ = includeQuotes ? arr.map((a) => `"${a}"`) : arr;
  if (arrQ.length === 1) return arrQ[0];
  const firsts = arrQ.slice(0, arrQ.length - 1);
  const last = arrQ[arrQ.length - 1];
  return `${firsts.join(', ')} and ${last}`;
};

export const getSearchResults = (data, text) =>
  text === ''
    ? data
    : data?.filter((_content) =>
        Object.values(_content)
          .filter((item) => item !== null && item !== undefined && item !== '')
          .some((val) =>
            val.toString().toLowerCase().startsWith(text.toLowerCase()),
          ),
      );

// input string array
// output - "Intrusion Detection +2" or "Intrusion Detection, Firewall, VPN, +2"
export const getCommaSeparatedPlusSuffix = (strings = [], showItems = 1) => {
  if (strings.length <= showItems) {
    return strings.join(', ');
  }
  const extraValues = strings.length - showItems;
  const printedValues = strings.slice(0, showItems);
  return `${printedValues} +${extraValues}`;
};

export const numberToRoman = (num) => {
  const romanNumeralMap = [
    { value: 1000, symbol: 'M' },
    { value: 900, symbol: 'CM' },
    { value: 500, symbol: 'D' },
    { value: 400, symbol: 'CD' },
    { value: 100, symbol: 'C' },
    { value: 90, symbol: 'XC' },
    { value: 50, symbol: 'L' },
    { value: 40, symbol: 'XL' },
    { value: 10, symbol: 'X' },
    { value: 9, symbol: 'IX' },
    { value: 5, symbol: 'V' },
    { value: 4, symbol: 'IV' },
    { value: 1, symbol: 'I' },
  ];

  return romanNumeralMap.reduce((acc, { value, symbol }) => {
    while (num >= value) {
      acc += symbol;
      num -= value;
    }
    return acc;
  }, '');
};

export const addOrdinalSuffix = (num) => {
  if (Number.isNaN(num)) return '';

  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return `${num}th`;
  }

  switch (lastDigit) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};
