import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography, FdCard } from '@fifthdomain/fe-shared'; // Assuming you're using Material-UI

const TileCard = ({ data, subtitleVariant }) => {
  return (
    <Box className="grid grid-cols-4 gap-x-5 mt-4">
      {data?.map((cardData, index) => (
        <FdCard key={index} variant="outlined">
          <Box className="flex flex-col items-center gap-y-1">
            <Box className="flex items-center" height="50px">
              {cardData.content}
            </Box>
            {cardData.subtitle && (
              <FdTypography variant={subtitleVariant} color="secondary">
                {cardData.subtitle}
              </FdTypography>
            )}
          </Box>
        </FdCard>
      ))}
    </Box>
  );
};

TileCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subtitleVariant: PropTypes.string,
};

TileCard.defaultProps = {
  subtitleVariant: 'subtitle1',
};

export default TileCard;
