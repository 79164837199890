import React from 'react';

const DemonstratedIcon = () => (
  <>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="11"
        height="11"
        fill="url(#paint0_linear_6719_31526)"
      />
      <rect x="0.5" y="0.5" width="11" height="11" stroke="#4DD0E1" />
      <defs>
        <linearGradient
          id="paint0_linear_6719_31526"
          x1="6"
          y1="0"
          x2="6"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCDDEC" />
          <stop offset="1" stopColor="#793188" />
        </linearGradient>
      </defs>
    </svg>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="11"
        height="11"
        fill="url(#paint0_linear_6599_10845)"
      />
      <rect x="0.5" y="0.5" width="11" height="11" stroke="#DBE6FF" />
      <defs>
        <linearGradient
          id="paint0_linear_6599_10845"
          x1="6"
          y1="0"
          x2="6"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCDDEC" />
          <stop offset="1" stopColor="#793188" />
        </linearGradient>
      </defs>
    </svg>
  </>
);

export default DemonstratedIcon;
