import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Box, Divider } from '@material-ui/core';
import { isWithinInterval, subMonths, format } from 'date-fns';
import {
  FdTab,
  FdLoadingSpinner,
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { gql } from '@apollo/client';
import CyberBrain from './Cortex/CyberBrain';
import TimePeriodFilter from '../Participant/SkillsOverview/TimePeriodFilter';
import WorkRole from '../WorkRole/WorkRole';
import {
  listAffliationsByUserId,
  listCustomerSkillsByCustomerWorkRoleId,
  listCustomerSkillFdSkills,
} from '../../queries/customQueries';
import {
  listSkills,
  listTaskAttemptAggregatesByTeamId,
  listTaskAttemptAggregatesByUserId,
} from '../../graphql/queries';
import { getArrayByLength } from '../../shared/utils/objectUtils';
import {
  SKILLS_PERFORMANCE_TIMELINE_START_DATE,
  degradationRate,
} from '../../constants';
import SkillsDemonstrated from './Cortex/SkillsDemonstrated';
import OverlayAssignedWR from './Cortex/OverlayAssignedWR';

const SPECIALTY_SORT = {
  Intelligence: { order: 1, paddingTop: 28 },
  Penetration: { order: 2, paddingTop: 20 },
  Protection: { order: 3, paddingTop: 2 },
  Detection: { order: 4, paddingTop: 2 },
  Engineering: { order: 5, paddingTop: 10 },
  Investigation: { order: 6, paddingTop: 2 },
};

const CyberSkillCortex = ({
  _userId,
  marks,
  affiliated,
  selectedUser,
  teamBased,
  teamId,
  isParticipant,
}) => {
  const { orgId } = useSnapshot(globalStore);
  const { userId } = useParams();
  const [toggleSelection, setToggleSelection] = useState('solves');
  const [overlayWorkrole, setOverlayWorkRole] = useState(false);
  const [cortexValueRange, setCortexValueRange] = useState([0, 100]);

  const userIdData = _userId || userId;

  // get the assigned Work Role
  const {
    data: listAffliationsByUserIdData,
    loading: listAffliationsByUserIdLoading,
    refetch: refetchUserOrgAffliations,
  } = useQueryRecursive(gql(listAffliationsByUserId), {
    variables: {
      userId: userIdData,
    },
    staleTime: { seconds: 0 },
    skip: !affiliated,
  });

  const userOrgAffliation =
    listAffliationsByUserIdData?.listAffliationsByUserId?.items?.map(
      (item) => ({
        ...item,
        name: item?.customerWorkrole?.name,
      }),
    ) || [];

  const assignedWorkRole = userOrgAffliation?.filter(
    (item) => item?.customerWorkroleID !== null,
  );

  // get list of customer skills for a workrole id
  const {
    data: listCustomerSkillsByCustomerWorkRoleIdData,
    loading: listCustomerSkillsByCustomerWorkRoleIdLoading,
  } = useQueryRecursive(gql(listCustomerSkillsByCustomerWorkRoleId), {
    variables: {
      customerWorkroleId: assignedWorkRole[0]?.customerWorkroleID,
      limit: 1000,
    },
    staleTime: { seconds: 0 },
    skip: assignedWorkRole?.length === 0 || isParticipant || !affiliated,
  });

  const listCustomerSkills =
    listCustomerSkillsByCustomerWorkRoleIdData
      ?.listCustomerSkillsByCustomerWorkRoleId?.items || [];

  // get list of all customer to fd skills mapping and get intersection of FD skills
  const {
    data: listCustomerSkillFdSkillsData,
    loading: listCustomerSkillFdSkillsLoading,
  } = useQueryRecursive(gql(listCustomerSkillFdSkills), {
    variables: {
      limit: 1000,
    },
    staleTime: { seconds: 0 },
    skip: assignedWorkRole?.length === 0 || isParticipant || !affiliated,
  });

  const listAllFdSkills =
    listCustomerSkillFdSkillsData?.listCustomerSkillFdSkills?.items || [];

  const result = listAllFdSkills?.filter((item) =>
    listCustomerSkills
      ?.map((i) => i?.customerSkillId)
      ?.includes(item?.customerSkillId),
  );

  const listFdSkills = result?.map((skill) => ({
    ...listCustomerSkills?.find(
      (item) => item?.customerSkillId === skill?.customerSkillId,
    ),
    ...skill,
  }));

  const { data: listSkillsData, loading: listSkillsLoading } =
    useQueryRecursive(gql(listSkills));

  const {
    data: listTaskAttemptAggregatesByUserIdData,
    loading: listTaskAttemptAggregatesByUserIdLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByUserId), {
    variables: {
      userId: userIdData,
      limit: 1000,
    },
    skip: teamBased,
  });

  const {
    data: listTaskAttemptAggregatesByTeamIdData,
    loading: listTaskAttemptAggregatesByTeamIdLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByTeamId), {
    variables: {
      teamId,
      limit: 1000,
    },
    skip: !teamBased,
  });

  const specialties = _(listSkillsData?.listSkills?.items || [])
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items,
    }))
    .value();

  const difficultyLevels = getArrayByLength(5, (__, i) => i + 1);
  const listTaskAttemptAggregates = teamBased
    ? listTaskAttemptAggregatesByTeamIdData?.listTaskAttemptAggregatesByTeamId
        ?.items || []
    : listTaskAttemptAggregatesByUserIdData?.listTaskAttemptAggregatesByUserId
        ?.items || [];

  const taskAttemptData =
    affiliated || isParticipant
      ? listTaskAttemptAggregates
      : listTaskAttemptAggregates?.filter(
          (item) => item?.assessment?.orgId === orgId,
        );
  const attemptsData = taskAttemptData?.filter(
    (la) => la?.task?.skills?.items?.length > 0,
  );

  const getTimeLineDate = (_value) =>
    (_value === 100
      ? new Date()
      : marks?.find((m) => m?.value === _value)?.date) || new Date();

  const timeLine = teamBased
    ? {
        startDate: SKILLS_PERFORMANCE_TIMELINE_START_DATE,
        endDate: new Date(),
      }
    : {
        startDate: getTimeLineDate(cortexValueRange[0]),
        endDate: getTimeLineDate(cortexValueRange[1]),
      };

  const attemptsDataUnique = _.uniqBy(attemptsData, 'taskId');
  const attemptsDataTimeLineBased = attemptsDataUnique?.filter((a) =>
    isWithinInterval(new Date(a?.updatedAt), {
      start: new Date(timeLine?.startDate),
      end: new Date(timeLine?.endDate),
    }),
  );

  const onlyAttempts = attemptsDataTimeLineBased?.filter(
    (a) => a?.status === 'ATTEMPTED',
  );
  const onlySolves = attemptsDataTimeLineBased?.filter(
    (a) => a?.status === 'COMPLETED',
  );
  const solvesOrEfforts =
    toggleSelection === 'solves' ? onlySolves : onlyAttempts;
  const isSolvesSelected = toggleSelection === 'solves';
  const fdSkills =
    overlayWorkrole && isSolvesSelected
      ? listFdSkills?.map((skill) => ({
          skillId: skill?.skillId,
          proficiencyLevelsRequired: skill?.difficulty,
        }))
      : [];

  // logic for level solves/effort
  // Ln = All solves/effort in Sum (Ln.. + L5)
  const getLevelPoints = (_level, _attempts) => {
    const levelEndDate2 = new Date(timeLine?.endDate);
    const levelStartDate2 = subMonths(levelEndDate2, degradationRate[_level]);

    return (
      _attempts?.filter(
        (a) =>
          a?.task?.difficulty === _level &&
          isWithinInterval(new Date(a?.updatedAt), {
            start: levelStartDate2,
            end: levelEndDate2,
          }),
      )?.length || 0
    );
  };

  const getDegradationScore = (_level, _attempts) => {
    let finalScore = 0;
    for (let index = _level; index <= 5; index += 1) {
      const levelEndDate = new Date(timeLine?.endDate);
      const levelStartDate = subMonths(levelEndDate, degradationRate[index]);
      const scoreInTimeFrame =
        _attempts?.filter(
          (a) =>
            a?.task?.difficulty === index &&
            isWithinInterval(new Date(a?.updatedAt), {
              start: levelStartDate,
              end: levelEndDate,
            }),
        )?.length || 0;
      finalScore += scoreInTimeFrame;
    }
    return finalScore > 0 ? finalScore : 0;
  };

  const getStrokedDashedArray = (_skill, _level, _solved) => {
    const isSkillInWorkrole = fdSkills
      ?.map((s) => s?.skillId)
      ?.includes(_skill);
    const proficiencyLevelsRequired = _.maxBy(
      fdSkills
        ?.filter((s) => s?.skillId === _skill)
        ?.map((i) => i?.proficiencyLevelsRequired),
    );
    const cellBorder = () => {
      let borderColor = '#E3E6EC';
      let borderWidth = 1;
      if (!isSkillInWorkrole) {
        borderColor = '#EAEEF4';
      }
      if (_level < proficiencyLevelsRequired) {
        borderColor = '#4DD0E1';
      }
      if (_level > proficiencyLevelsRequired) {
        borderColor = '#DBE6FF';
      }
      if (proficiencyLevelsRequired === _level) {
        borderColor = '#0097A7';
        borderWidth = 2;
      }
      return { borderColor, borderWidth };
    };
    return { strokeColor: cellBorder() };
  };

  const specialtiesFormatted =
    specialties
      ?.sort(
        (a, b) =>
          SPECIALTY_SORT[a.specialtyName]?.order -
          SPECIALTY_SORT[b.specialtyName]?.order,
      )
      ?.map((specialty, idx) => {
        const flip = (idx + 1) % 2 === 1;
        const showHeader = idx + 1 < 3;
        const levels = flip
          ? [...difficultyLevels].reverse()
          : difficultyLevels;

        const dataSorted = specialty?.items?.map((skill) => {
          const attempts =
            solvesOrEfforts?.filter((a) =>
              a?.task.skills.items?.some((s) =>
                s?.skill?.alias?.includes(skill?.alias),
              ),
            ) || [];
          const lastSolveDate = _.maxBy(
            attempts,
            (item) => new Date(item.updatedAt),
          )?.updatedAt;
          const lastSolveDateFormatted = lastSolveDate
            ? format(new Date(lastSolveDate), 'dd/MM/yyyy')
            : 'N/A';
          const proficiencyLevelsRequired = _.maxBy(
            fdSkills
              ?.filter((s) => s?.skillId === skill?.id)
              ?.map((i) => i?.proficiencyLevelsRequired),
          );
          const isSkillInWorkrole = fdSkills
            ?.map((s) => s?.skillId)
            ?.includes(skill?.id);
          return {
            id: skill?.alias,
            proficiencyLevelsRequired,
            data: [
              ...levels?.map((l) => {
                const levelPoints = getLevelPoints(l, attempts) || undefined;
                const { strokeColor } = getStrokedDashedArray(
                  skill?.id,
                  l,
                  getDegradationScore(l, attempts) || 0,
                );
                return {
                  x: l,
                  y: getDegradationScore(l, attempts) || 0,
                  yLabel: !overlayWorkrole
                    ? levelPoints
                    : isSkillInWorkrole && levelPoints,
                  yLabelName: `${skill?.name} (${skill?.alias})`,
                  yType: toggleSelection,
                  yLevelPoints:
                    attempts?.filter((a) => a?.task?.difficulty === l)
                      ?.length || 0,
                  yLabelLastSolve: levelPoints ? lastSolveDateFormatted : 'N/A',
                  stroke: strokeColor?.borderColor, // borderColor
                  strokeWidth: strokeColor?.borderWidth, // borderWidth
                  cellFillColor:
                    overlayWorkrole && !isSkillInWorkrole && '#EAEEF4', // greyout
                };
              }),
            ],
          };
        });
        return { ...specialty, flip, showHeader, levels, dataSorted };
      }) || [];

  const specialtiesDataSorted =
    specialtiesFormatted?.map((specialty) => specialty?.dataSorted)?.flat() ||
    [];

  const topSpecialties = specialtiesFormatted
    ?.map((s) => {
      const proficiencyLevelOfSolves = s?.dataSorted?.reduce(
        (acc, i) =>
          // eslint-disable-next-line no-unsafe-optional-chaining
          acc + i?.data?.reduce((dAcc, d) => dAcc + (d.y !== 0 ? 1 : 0), 0),
        0,
      );
      const skillsCovered = s?.dataSorted?.reduce(
        // eslint-disable-next-line no-unsafe-optional-chaining
        (acc, i) => acc + i?.data?.reduce((dAcc, d) => dAcc + (d.y ? 1 : 0), 0),
        0,
      );
      return {
        specialty: s?.specialtyName,
        score: (1 / 3) * proficiencyLevelOfSolves + (2 / 3) * skillsCovered,
        dataSorted: s?.dataSorted,
      };
    })
    .sort((a, b) => b.score - a.score);

  const topSpecialty =
    _.orderBy(
      topSpecialties?.filter((ts) => ts?.score > 0),
      ['score', 'specialty'],
      ['desc', 'asc'],
    )?.[0]?.specialty || '';

  const allTopSkills = topSpecialties
    ?.map((ts) =>
      ts?.dataSorted?.map((d) => ({
        id: d?.id,
        // eslint-disable-next-line no-unsafe-optional-chaining
        score: d?.data?.reduce((acc, i) => acc + i?.y, 0),
        topRow: _.maxBy(
          d?.data?.filter((ds) => ds?.y > 0),
          'x',
        ),
      })),
    )
    ?.flat()
    ?.filter((x) => x?.score > 0);

  const top3Skills =
    _.orderBy(allTopSkills, ['score', 'id'], ['desc', 'asc']).slice(0, 3) || [];

  const onCortexValueRangeChange = (__, newValue) =>
    setCortexValueRange(newValue);

  if (
    listAffliationsByUserIdLoading ||
    listCustomerSkillsByCustomerWorkRoleIdLoading ||
    listCustomerSkillFdSkillsLoading
  ) {
    return <FdLoadingSpinner />;
  }

  return (
    <Box className="flex flex-row gap-x-3">
      <Box width={teamBased ? '100%' : '50%'}>
        {!teamBased && (
          <Box display="flex" justifyContent="center">
            <FdTab
              label={[
                {
                  label: '                Solved                      ',
                  tabRoute: `/competitions/affiliated-view-user/${userIdData}`,
                  index: 0,
                  data: () => <>{setToggleSelection('solves')}</>,
                },
                {
                  label: '                       Unsolved                   ',
                  tabRoute: `/competitions/affiliated-view-user/${userIdData}`,
                  index: 1,
                  data: () => <>{setToggleSelection('attempts')}</>,
                },
              ]}
            />
          </Box>
        )}
        <CyberBrain
          isParticipant={isParticipant}
          topSpecialty={topSpecialty}
          top3Skills={top3Skills}
          teamBased={teamBased}
          loading={
            listSkillsLoading ||
            listTaskAttemptAggregatesByUserIdLoading ||
            listTaskAttemptAggregatesByTeamIdLoading
          }
          specialtiesFormatted={specialtiesFormatted}
          overlayWorkrole={overlayWorkrole}
          toggleSelection={toggleSelection}
          assignedWorkRole={assignedWorkRole}
          timeLine={{
            startDate: getTimeLineDate(cortexValueRange[0]),
            endDate: getTimeLineDate(cortexValueRange[1]),
          }}
        />

        {affiliated && assignedWorkRole?.length > 0 && (
          <OverlayAssignedWR
            overlayWorkrole={overlayWorkrole}
            setOverlayWorkRole={setOverlayWorkRole}
          />
        )}
      </Box>
      {!teamBased && (
        <Box>
          <TimePeriodFilter
            marks={marks}
            step={null}
            valueRange={cortexValueRange}
            onValueChange={onCortexValueRangeChange}
            description={
              isParticipant
                ? `The slider below enables you to filter your view to a
      specific time period in the past. The leftmost position represents
      week 1 of October 2023 (the earliest view available), and the
      rightmost indicates the current week. Drag the slider to explore
      skills demonstrated from October 2023 to the selected week.`
                : 'Use the slider to explore weekly performance metrics, starting from Week 1 of October 2023 on the left to the current week on the right.'
            }
          />
          {!isParticipant && (
            <SkillsDemonstrated
              isSolvesSelected={isSolvesSelected}
              teamBased={teamBased}
              topSpecialty={topSpecialty}
              top3Skills={top3Skills}
              managerMode
            />
          )}
          <Box mt={1}>
            <Divider />
          </Box>
          {affiliated && (
            <WorkRole
              listFdSkills={listFdSkills}
              userOrgAffliation={userOrgAffliation[0]}
              selectedUser={selectedUser}
              assignedWorkRole={assignedWorkRole}
              overlayWorkrole={overlayWorkrole}
              setOverlayWorkRole={setOverlayWorkRole}
              refetch={refetchUserOrgAffliations}
              specialtiesDataSorted={specialtiesDataSorted}
              userId={userId}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

CyberSkillCortex.defaultProps = {
  teamId: undefined,
  affiliated: false,
  _userId: undefined,
  isParticipant: false,
};

CyberSkillCortex.propTypes = {
  teamBased: PropTypes.bool.isRequired,
  teamId: PropTypes.string,
  marks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  affiliated: PropTypes.bool,
  _userId: PropTypes.string,
  isParticipant: PropTypes.bool,
  selectedUser: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    alias: PropTypes.string,
  }).isRequired,
};

export default CyberSkillCortex;
