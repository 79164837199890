export const EDUCATION_TYPE = Object.freeze({
  YEAR12: 'Year 12 or equivalent',
  TRADE_TECHNICAL: 'Trade/technical/vocational training',
  CERTIFICATEIV: 'Certificate IV',
  DIPLOMA: 'Diploma',
  ADVANCED_DIPLOMA: 'Advanced Diploma/Associate Degree',
  BACHELOR_DEGREE: 'Bachelor Degree',
  MASTERS_DEGREE: 'Masters Degree',
  DOCTORATE_DEGREE: 'Doctorate Degree',
  OTHER_EDUCATION: 'Other',
});

export const FORMAL_TRAINING_TYPE = Object.freeze({
  SHORT_COURSES: 'Short courses',
  CERTIFICATEIV: 'Certificate IV',
  DIPLOMA: 'Diploma',
  ADVANCED_DIPLOMA: 'Advanced Diploma/Associate Degree',
  BACHELOR_DEGREE: 'Bachelor Degree',
  MASTERS_DEGREE: 'Masters Degree',
  DOCTORATE_DEGREE: 'Doctorate Degree',
  OTHER_FORMAL_TRAINING: 'Other',
});

export const CYBER_TRAINING_TYPE = Object.freeze({
  FORMAL_TRAINING: 'Formal Training',
  SELF_DIRECTED: 'Self-directed Learning',
  ON_JOB: 'On-job Training',
  NON_ACCREDITED: 'Non-accredited Training',
  NONE: 'I have not received any cyber training',
});

export const WORKING_INDUSTRY_TYPE = Object.freeze({
  FINANCE: 'Finance',
  DEFENCE: 'Defence',
  INTELLIGENCE: 'Intelligence',
  HEALTHCARE: 'Healthcare',
  EDUCATION: 'Education',
  GOVERNMENT: 'Government/Public Administration',
  INFORMATION_TECHNOLOGY: 'Information Technology',
  LAW_ENFORCEMENT: 'Law enforcement',
  MANUFACTURING: 'Manufacturing',
  RETAIL: 'Retail/E-commerce',
  OTHER_WORKING_INDUSTRY: 'Other',
});

export const ASSESSMENT_STATUS_SORT_ORDER = {
  STARTED: 1,
  NOT_STARTED: 2,
  NOT_COMPLETED: 3,
  FINISHED: 4,
};

export const SCOREBOARD_DURATION = Object.freeze({
  HOURS_24: 'Last 24 Hours',
  ALL_COMPETITION: 'All Competition',
});

export const ASSESSMENT_TYPES = {
  INDIVIDUAL_BASED: 'Individual-Based',
  TEAM_BASED: 'Team-Based',
};

export const AVAILABILITY_TYPES = {
  MANUAL: 'Manual',
  AUTOMATIC: 'Automatic',
};

export const THEMES = Object.freeze([
  { value: 'LIGHT', label: 'Light Theme' },
  { value: 'RETRO', label: 'Retro Theme' },
]);

export const ERROR_MESSAGE = Object.freeze({
  SUBSCRIPTION_ERROR: 'Observable cancelled prematurely',
});

export const TASK_ACTIVITY = Object.freeze([
  {
    value: 'NOT_WORKING',
    desc: "I'm not working on it",
  },
  { value: 'WORKING', desc: "I'm working on it" },
  { value: 'BLOCKED', desc: "I'm blocked" },
  { value: 'NEED_HELP', desc: 'I need help' },
]);

export const TASK_ACTIVITY_TOOLTIP = Object.freeze({
  NOT_WORKING: 'is not working on it',
  WORKING: 'is working on it',
  BLOCKED: 'is blocked',
  NEED_HELP: 'needs help',
});

export const USER_PERMISSIONS = Object.freeze({
  MANAGE_GROUPS: 'manage-groups',
  MANAGE_ADMINS: 'manage-admins',
  MANAGE_TRAINING: 'manage-training',
  CREATE: 'create',
  VIEW_INSIGHTS: 'view-insights',
});

export const LEVEL_NAMES = [
  'Alfa',
  'Bravo',
  'Charlie',
  'Delta',
  'Echo',
  'Foxtrot',
  'Golf',
  'Hotel',
  'India',
  'Juliette',
  'Kilo',
  'Lima',
  'Mike',
  'November',
  'Oscar',
  'Papa',
  'Quebec',
  'Romeo',
  'Sierra',
  'Tango',
  'Uniform',
  'Victor',
  'Whiskey',
  'X-ray',
  'Yankee',
  'Zulu',
];

export const CONTENT_IMAGES = [
  'Analysis.jpg',
  'Intrusion Detection.jpg',
  'Incident Management.jpg',
  'Vulnerability.jpg',
  'Custom.jpg',
];

export const TAG_COLOUR = [
  {
    backgroundColor: '#FFE082',
    value: 'YELLOW',
  },
  {
    backgroundColor: '#FFAB91',
    value: 'ORANGE',
  },
  {
    backgroundColor: '#F48FB1',
    value: 'PINK',
  },
  {
    backgroundColor: '#CE93D8',
    value: 'PURPLE',
  },
  {
    backgroundColor: '#40C4FF',
    value: 'BLUE',
  },
  {
    backgroundColor: '#80CBC4',
    value: 'TEAL',
  },
];

export const SPECIALTY_COLOR = {
  Intelligence: '#A328ED',
  Protection: '#4E4EFF',
  Detection: '#C849A4',
  Investigation: '#3EB3D6',
  Penetration: '#97B1FF',
  Engineering: '#A4B8D1',
};

export const PROFICIENCY = {
  Novice: {
    color: '#BAE8D2',
    range: '1',
    completeColor: '#76D1A5',
  },
  'Advanced Beginner': {
    color: '#CBD9FF',
    range: '2',
    completeColor: '#97B4FF',
  },
  Competent: {
    color: '#FADCB8',
    range: '3',
    completeColor: '#F6B971',
  },
  Proficient: {
    color: '#F0BFDD',
    range: '4',
    completeColor: '#E380BB',
  },
  Expert: {
    color: '#EAA9A9',
    range: '5',
    completeColor: '#D55353',
  },
};

export const PROFICIENCY_RANGE = {
  Novice: {
    range: '1-2',
  },
  'Advanced Beginner': {
    range: '3-4',
  },
  Competent: {
    range: '5-6',
  },
  Proficient: {
    range: '7-8',
  },
  Expert: {
    range: '9-10',
  },
};

export const PROFICIENCY_LEVELS = {
  1: 'Novice',
  2: 'Advanced Beginner',
  3: 'Competent',
  4: 'Proficient',
  5: 'Expert',
};

export const proficiencyLevels = [
  'Novice',
  'Advanced Beginner',
  'Competent',
  'Proficient',
  'Expert',
];

export const defaultLabDuration = 120;

export const TEAM_MEMBER_TYPES = {
  TEAM_MANAGER: 'Team Manager',
  GENERAL_MEMBER: 'General Member',
};

// skills performance timeline data starts from this date - week 1 of October 2023
export const SKILLS_PERFORMANCE_TIMELINE_START_DATE = new Date(2023, 9, 1);
export const TEAM_ROLES = {
  TEAM_MANAGER: 'TEAM_MANAGER',
  GENERAL_MEMBER: 'GENERAL_MEMBER',
};

export const EVENT_AVAILABILITY_TYPES = {
  MANUAL: 'MANUAL',
  AUTOMATIC: 'AUTOMATIC',
};

export const ACCOMPLISHMENT_TYPES = Object.freeze({
  EDUCATION: 'Education/Course',
  CERTIFICATIONS: 'Certification',
  OTHER: 'Other Accomplishment',
});

export const WORK_ROLE_SKILLS_COLOR = {
  fulfilledSkills: '#69F0AE',
  unfulfilledSkills: '#EF9A9A',
};

export const CHAMPION_LEVELS = [
  { name: 'Gold', value: 1 },
  { name: 'Silver', value: 2 },
  { name: 'Bronze', value: 3 },
];

export const TROPHY_TILES = {
  POINTS: {
    name: 'Points Prowess',
    specialty: 'points',
    noGhosted: true,
  },
  COMPETITION_CHAMPION: {
    name: 'Competition Champion',
    specialty: 'competition_champion',
    verticalTiles: true,
    noGhosted: true,
  },
  Intelligence: {
    name: 'Intelligence Completionist',
    specialty: 'intelligence',
    description:
      'Intelligence Completionist trophies highlight achievement milestones in the Intelligence Professional Specialty. Each of the five trophy tiers have increasingly higher requirements for points, skill coverage, and proficiency.',
  },
  Penetration: {
    name: 'Penetration Completionist',
    specialty: 'penetration',
    description:
      'Penetration Completionist trophies highlight achievement milestones in the Penetration Professional Specialty. Each of the five trophy tiers have increasingly higher requirements for points, skill coverage, and proficiency.',
  },
  Protection: {
    name: 'Protection Completionist',
    specialty: 'protection',
    description:
      'Protection Completionist trophies highlight achievement milestones in the Protection Professional Specialty. Each of the five trophy tiers have increasingly higher requirements for points, skill coverage, and proficiency.',
  },
  Detection: {
    name: 'Detection Completionist',
    specialty: 'detection',
    description:
      'Detection Completionist trophies highlight achievement milestones in the Detection Professional Specialty. Each of the five trophy tiers have increasingly higher requirements for points, skill coverage, and proficiency.',
  },
  Engineering: {
    name: 'Engineering Completionist',
    specialty: 'engineering',
    description:
      'Engineering Completionist trophies highlight achievement milestones in the Engineering Professional Specialty. Each of the five trophy tiers have increasingly higher requirements for points, skill coverage, and proficiency.',
  },
  Investigation: {
    name: 'Investigation Completionist',
    specialty: 'investigation',
    description:
      'Investigation Completionist trophies highlight achievement milestones in the Investigation Professional Specialty. Each of the five trophy tiers have increasingly higher requirements for points, skill coverage, and proficiency.',
  },
};

export const TROPHY_TILES_BANNER = Object.values(TROPHY_TILES)
  ?.filter((t) => !['points', 'competition_champion'].includes(t.specialty))
  ?.map((t) => t.specialty);

export const TROPHY_SECTIONS = {
  POINTS: {
    name: 'Point Prowess',
    description:
      'Point Prowess trophies showcase your ability to earn points in competitions and assessments across any Professional Specialty. Achieve higher trophy tiers as you score more points and reach point targets.',
    tiles: [TROPHY_TILES.POINTS],
    specialty: 'points',
  },
  COMPETITION_CHAMPION: {
    name: 'Competition Champion',
    description:
      'Competition Champion trophies award those who achieve the first, second, and third places in competitions. Top-scoring individuals and members of top-scoring teams receive Gold, Silver and Bronze trophies accordingly.',
    tiles: [TROPHY_TILES.COMPETITION_CHAMPION],
    specialty: 'competition_champion',
    verticalTiles: true,
  },
  PROFESSIONAL_SPECIALTY_COMPETITION: {
    name: 'Professional Specialty',
    description:
      'The badges below are obtained by achieving target point thresholds across the six Professional Specialties.',
    tiles: [
      TROPHY_TILES.Intelligence,
      TROPHY_TILES.Penetration,
      TROPHY_TILES.Protection,
      TROPHY_TILES.Detection,
      TROPHY_TILES.Engineering,
      TROPHY_TILES.Investigation,
    ],
  },
};

export const TROPHY_TYPES = [
  {
    name: 'Achieve',
    rows: [
      {
        cols: 2,
        sections: [
          TROPHY_SECTIONS.POINTS,
          TROPHY_SECTIONS.COMPETITION_CHAMPION,
        ],
      },
      {
        cols: 1,
        sections: [TROPHY_SECTIONS.PROFESSIONAL_SPECIALTY_COMPETITION],
      },
    ],
  },
];

export const degradationRate = {
  1: 2,
  2: 3,
  3: 5,
  4: 8,
  5: 13,
};
