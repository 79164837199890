import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme, TextField } from '@material-ui/core';
import {
  FdCard,
  FdTypography,
  FdChip,
  FdAutocompleteStatic,
  FdIcons,
  FdSkeleton,
  FdButton,
} from '@fifthdomain/fe-shared';
import TeamAvatar from './TeamAvatar';
import { Summary } from '../Assessment';
import LabelWithTooltip from './LabelWithTooltip';
import IconWithLabel from './IconWithLabel';
import { getParticipantStatusColor } from '../../shared/utils/getStatusColor';
import { getParticipantStatus } from '../../shared/utils/getParticipantStatus';
import { scrollToTop } from '../../shared/utils/scroll';
import useGetProfileLink from '../../hooks/useGetProfileLink';

const DEFAULT_AUTOCOMPLETE_WIDTH = 330;

const InsightsSummary = ({
  allContestants,
  contestantSelected,
  setContestantSelected,
  loading,
  statusLoading,
  teamBased,
  scoreboardSummaryData,
  selectionFromLeaderBoard,
}) => {
  const theme = useTheme();
  const [autoCompleteWidth, setAutoCompleteWidth] = useState(
    DEFAULT_AUTOCOMPLETE_WIDTH,
  );
  const [leaderBoardInitialized, setLeaderBoardInitialized] = useState(false);
  const { SelectAll, FlagOutlined, WhatshotOutlined } = FdIcons;
  const teamOrParticipant = teamBased ? 'Team' : 'Participant';
  const { selectedContestantData, participantStatus, totalFlags } =
    scoreboardSummaryData || {};
  const { rank, points, firstBloods, flags, memberCount } =
    selectedContestantData || { memberCount: 0 };

  useEffect(() => {
    // pre select if selection made from leader board
    if (
      selectionFromLeaderBoard &&
      !contestantSelected &&
      !leaderBoardInitialized
    ) {
      const selectedOption = allContestants?.find(
        (a) => a?.id === selectionFromLeaderBoard,
      );
      if (selectedOption) {
        setContestantSelected(selectedOption);
        setAutoCompleteWidth(
          DEFAULT_AUTOCOMPLETE_WIDTH + (selectedOption?.name?.length || 0),
        );
        setLeaderBoardInitialized(true);
        scrollToTop();
      }
    }
  }, [
    allContestants,
    selectionFromLeaderBoard,
    setContestantSelected,
    contestantSelected,
    leaderBoardInitialized,
  ]);
  // get profile link based on affiliation
  const profileLink = useGetProfileLink(contestantSelected?.id);

  return (
    <Box mt={2}>
      <FdCard variant="outlined">
        <FdSkeleton
          loading={
            loading ||
            (selectionFromLeaderBoard &&
              !contestantSelected &&
              !leaderBoardInitialized)
          }
        >
          <Box className="flex items-center gap-x-3">
            <FdAutocompleteStatic
              id="select-contestant"
              options={allContestants}
              value={contestantSelected}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => {
                return (
                  <Box className="flex items-center mr-2">
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <Box mr={1}>
                            <TeamAvatar
                              size="small"
                              team={{
                                name: contestantSelected?.name,
                                key: contestantSelected?.teamKey,
                              }}
                            />
                          </Box>
                        ),
                        style: {
                          height: '50px',
                          fontSize: '1.25rem',
                          width: `${autoCompleteWidth}px`,
                        },
                      }}
                      placeholder={`Select a ${teamOrParticipant?.toLowerCase()}`}
                    />
                  </Box>
                );
              }}
              onChange={(_event, _value) => {
                setContestantSelected(_value);
                setAutoCompleteWidth(
                  DEFAULT_AUTOCOMPLETE_WIDTH + (_value?.name?.length || 0),
                );
              }}
            />
            {contestantSelected && !teamBased && (
              <FdSkeleton loading={!profileLink} height="34px" width="140px">
                <FdButton
                  variant="secondary"
                  onClick={() => window.open(profileLink, '_blank')}
                >
                  View Profile
                </FdButton>
              </FdSkeleton>
            )}
          </Box>
        </FdSkeleton>

        {contestantSelected && (
          <Box mt={2}>
            <Summary
              loading={loading}
              data={[
                {
                  value: (
                    <Box
                      className="flex justify-center items-center w-8 h-8 rounded-full mr-1 my-1"
                      style={{
                        backgroundColor: theme?.palette?.primary?.main,
                        color: '#fff',
                      }}
                    >
                      <FdTypography variant="body1">{`#${rank}`}</FdTypography>
                    </Box>
                  ),
                  description: (
                    <LabelWithTooltip
                      label="Competition Rank"
                      tooltipText="Competition rank is determined by the number of points scored in a competition."
                    />
                  ),
                },
                {
                  value: (
                    <FdSkeleton loading={statusLoading} height={41} width={95}>
                      <FdChip
                        style={{
                          marginBottom: '9px',
                        }}
                        size="medium"
                        color={getParticipantStatusColor(participantStatus)}
                        label={getParticipantStatus(participantStatus)}
                      />
                    </FdSkeleton>
                  ),
                  description: `${teamOrParticipant} progress status`,
                },
                ...(teamBased
                  ? [
                      {
                        value: memberCount,
                        description: (
                          <Box height="34px" className="flex items-center pt-1">
                            Number of team members
                          </Box>
                        ),
                      },
                    ]
                  : []),
                {
                  value: points,
                  description: (
                    <IconWithLabel
                      backgroundColor="rgba(232, 234, 246, 1)"
                      Icon={SelectAll}
                      color="rgba(40, 53, 147, 1)"
                      label="Points"
                    />
                  ),
                },
                {
                  value: `${flags}/${totalFlags}`,
                  description: (
                    <IconWithLabel
                      backgroundColor="rgba(243, 229, 245, 1)"
                      Icon={FlagOutlined}
                      color="rgba(142, 36, 170, 1)"
                      label="Flags"
                    />
                  ),
                },
                {
                  value: firstBloods,
                  description: (
                    <IconWithLabel
                      backgroundColor="rgba(239, 235, 233, 1)"
                      Icon={WhatshotOutlined}
                      color="rgba(78, 52, 46, 1)"
                      label="First Solves"
                    />
                  ),
                },
              ]}
              titleVariant="h3"
              subtitleVariant="subtitle2"
            />
          </Box>
        )}
      </FdCard>
    </Box>
  );
};

InsightsSummary.defaultProps = {
  participantStatus: undefined,
  contestantSelected: undefined,
  selectionFromLeaderBoard: undefined,
};

InsightsSummary.propTypes = {
  allContestants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  contestantSelected: PropTypes.objectOf(PropTypes.shape({})),
  setContestantSelected: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  statusLoading: PropTypes.bool.isRequired,
  teamBased: PropTypes.bool.isRequired,
  scoreboardSummaryData: PropTypes.shape({
    selectedContestantData: PropTypes.shape({}),
    participantStatus: PropTypes.string,
    totalFlags: PropTypes.number,
  }).isRequired,
  participantStatus: PropTypes.string,
  selectionFromLeaderBoard: PropTypes.string,
};

export default InsightsSummary;
