import { isAfter, isBefore, isPast, format } from 'date-fns';
import {
  formatMinutesToDaysHoursMinutes,
  getDifferenceInMinutes,
} from './dateUtils';
import { EVENT_AVAILABILITY_TYPES } from '../../constants';

export const getParticipantStatus = (status) => {
  const _status = status.replaceAll('_', ' ');
  switch (_status) {
    case 'STARTED':
      return 'In Progress';
    case 'NOT STARTED':
      return 'Not Started';
    case 'FINISHED':
      return 'Completed';
    case 'REMOVED':
      return 'Removed';
    case 'INVITED':
      return 'Invited';
    case 'NOT COMPLETED':
      return 'Not Completed';
    case 'ENDED':
      return 'Ended';
    default:
      return 'Completed';
  }
};

export const getCompetitionStatus = (
  startDateTime,
  endDateTime,
  serverTime,
) => {
  if (
    !startDateTime ||
    isAfter(new Date(startDateTime || undefined), new Date(serverTime))
  ) {
    return 'NOT_STARTED';
  }
  if (isBefore(new Date(endDateTime || undefined), new Date(serverTime))) {
    return 'ENDED';
  }
  if (isBefore(new Date(startDateTime || undefined), new Date(serverTime))) {
    return 'STARTED';
  }
  if (!serverTime) {
    return 'STARTED';
  }
  return 'ENDED';
};

export const getTimeStampText = (
  startDateTime,
  endDateTime,
  availabilityType,
  status,
) => {
  // manual start/stop no dates defined
  if (availabilityType === EVENT_AVAILABILITY_TYPES.MANUAL && !endDateTime) {
    if (startDateTime && status === 'NOT_STARTED') {
      return 'Start TBD by Admin';
    }
    return 'End TBD by Admin';
  }
  // ended
  if (endDateTime && isPast(new Date(endDateTime))) {
    return format(new Date(endDateTime), 'MM/dd/yy HH:mm');
  }
  // in progress
  if (startDateTime && isPast(new Date(startDateTime))) {
    const minsDiff = getDifferenceInMinutes(
      new Date(endDateTime),
      new Date(startDateTime),
    );
    return `${formatMinutesToDaysHoursMinutes(minsDiff)} remaining`;
  }
  // not started
  const minsDiff = getDifferenceInMinutes(new Date(), new Date(startDateTime));
  return `Starting in ${formatMinutesToDaysHoursMinutes(minsDiff)}`;
};

export const getTrainingStatus = (
  status,
  startDateTime,
  endDateTime,
  serverTime,
) => {
  switch (status) {
    case 'NOT_STARTED':
      return 'Not Started';
    case 'FINISHED':
      return 'Completed';
    case 'STARTED':
      return getCompetitionStatus(startDateTime, endDateTime, serverTime) ===
        'ENDED'
        ? 'Completed'
        : 'In Progress';
    default:
      return null;
  }
};
