import React, { useEffect, useRef } from 'react';
import anime from 'animejs';
import { useTheme } from '@material-ui/core';

const BlueFillerAniImage = () => {
  const svgRef = useRef(null);
  const theme = useTheme();
  useEffect(() => {
    if (svgRef.current) {
      anime({
        targets: svgRef.current.querySelectorAll('path'),
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutSine',
        duration: 500,
        delay: (el, i) => i * 50,
      });
    }
  }, []);

  const stroke =
    theme.palette.type === 'dark' ? 'rgba(190, 209, 255, 1)' : '#3333FF';

  return (
    <svg
      ref={svgRef}
      viewBox="0 0 160 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M140.179 78.7119V117.746C129.085 117.612 120.133 108.578 120.133 97.4517V78.7119H140.179Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M159.75 78.7135V117.748H140.395V98.3171C140.395 87.5729 149.038 78.8473 159.75 78.7135Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M60.1283 78.7119V117.746C49.1236 117.612 40.2439 108.65 40.2439 97.6135V78.7119H60.1283Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M79.9201 78.7135V117.748H60.3408V98.5412C60.3408 87.6732 69.0839 78.8473 79.9201 78.7135Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M119.839 117.748L80.0816 117.748C80.2155 107.011 88.9608 98.3489 99.7292 98.3489L119.839 98.3489L119.839 117.748Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M80.0801 98.1416L80.0801 78.7119L119.839 78.7119L119.839 98.1416L80.0801 98.1416Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M40.0092 117.748L0.251558 117.748C0.38543 107.011 9.13068 98.3489 19.8992 98.3489L40.0092 98.3489L40.0092 117.748Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M0.25 98.1416L0.249999 78.7119L40.0092 78.7119L40.0092 98.1416L0.25 98.1416Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M140.014 0.25V39.2845C128.824 39.1506 119.795 30.0381 119.795 18.8166V0.25H140.014Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M159.75 0.251548V39.286H140.227V20.0226C140.227 9.18596 148.945 0.385428 159.75 0.251548Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M60.0485 0.25V39.2845C49.0255 39.1506 40.1311 30.1733 40.1311 19.1186V0.25H60.0485Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M79.8722 0.251541V39.286H60.2605V20.1117C60.2605 9.22584 69.0181 0.385427 79.8722 0.251541Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M119.5 39.2861L80.1673 39.2861C80.3012 28.5494 89.0465 19.887 99.8149 19.887L119.5 19.887L119.5 39.2861Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M80.1658 19.6797L80.1658 0.250008L119.5 0.250006L119.5 19.6797L80.1658 19.6797Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M39.8956 39.2861L0.251558 39.2861C0.38543 28.5494 9.13068 19.887 19.8991 19.887L39.8956 19.887L39.8956 39.2861Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M0.25 19.6797L0.249999 0.250008L39.8956 0.250006L39.8956 19.6797L0.25 19.6797Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M100.241 117.817V156.852C89.1306 156.718 80.1655 147.67 80.1655 136.528V117.817H100.241Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M119.839 117.819V156.853H100.456V137.451C100.456 126.691 109.111 117.953 119.839 117.819Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M20.1611 117.817V156.852C9.15631 156.718 0.276611 147.755 0.276611 136.719V117.817H20.1611Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M39.9528 117.819V156.853H20.3735V137.647C20.3735 126.779 29.1166 117.953 39.9528 117.819Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M79.872 156.854L40.1144 156.854C40.2482 146.117 48.9935 137.454 59.7619 137.454L79.872 137.454L79.872 156.854Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M40.1128 137.247L40.1128 117.817L79.872 117.817L79.872 137.247L40.1128 137.247Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M159.75 156.854L119.992 156.854C120.126 146.117 128.871 137.454 139.64 137.454L159.75 137.454L159.75 156.854Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M119.991 137.247L119.991 117.817L159.75 117.817L159.75 137.247L119.991 137.247Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M100.241 39.4453V78.4798C89.1308 78.3459 80.1658 69.298 80.1658 58.1562V39.4453H100.241Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M119.839 39.4469V78.4813H100.456V59.0785C100.456 48.3188 109.111 39.5807 119.839 39.4469Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M20.1611 39.4453V78.4798C9.15631 78.3459 0.276611 69.3834 0.276611 58.3469V39.4453H20.1611Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M39.9528 39.4469V78.4813H20.3735V59.2746C20.3735 48.4066 29.1166 39.5807 39.9528 39.4469Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M79.872 78.4814L40.1144 78.4814C40.2482 67.7447 48.9935 59.0823 59.7619 59.0823L79.872 59.0823L79.872 78.4814Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M40.1128 58.875L40.1128 39.4453L79.872 39.4453L79.872 58.875L40.1128 58.875Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M159.75 78.4814L119.992 78.4814C120.126 67.7447 128.871 59.0823 139.64 59.0823L159.75 59.0823L159.75 78.4814Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M119.991 58.875L119.991 39.4453L159.75 39.4453L159.75 58.875L119.991 58.875Z"
        stroke={stroke}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default BlueFillerAniImage;
