import React from 'react';
import PropTypes from 'prop-types';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { setContext } from '@apollo/client/link/context';
import { Auth, Amplify } from 'aws-amplify';
import { amplifyConfig } from '@fifthdomain/fe-shared';

Amplify.configure(amplifyConfig);

const {
  aws_project_region: region,
  aws_appsync_graphqlEndpoint: url,
  aws_appsync_authenticationType: type,
} = amplifyConfig;

const getToken = async () => {
  try {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    return jwtToken;
  } catch (e) {
    return '';
  }
};

const httpLink = createHttpLink({
  uri: url,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

const auth = {
  type,
  jwtToken: async () => {
    const token = await getToken();
    return token;
  },
};

const link = ApolloLink.from([
  authLink,
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

function GraphQlClient({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

GraphQlClient.propTypes = {
  children: PropTypes.element.isRequired,
};

export default GraphQlClient;
