import { gql } from '@apollo/client';
import { useQueryRecursive } from '@fifthdomain/fe-shared';
import { getAssessmentWithTasks } from '../queries/customQueries';

const useGetAssessmentsTasks = ({ userId }) =>
  useQueryRecursive(gql(getAssessmentWithTasks), {
    variables: {
      userId,
      limit: 10000,
    },
    staleTime: { hours: 24 },
  });

export default useGetAssessmentsTasks;
