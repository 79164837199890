import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { getAssessment } from '../../graphql/queries';
import Announcements from '../Chats/Announcements';

const AdminAnnouncements = ({ assessmentId, isCompetitionEnded }) => {
  const { data: assessmentData, loading: assessmentLoading } = useQuery(
    gql(getAssessment),
    {
      variables: {
        id: assessmentId,
      },
      skip: !assessmentId,
      fetchPolicy: 'network-only',
    },
  );
  const announcements =
    assessmentData?.getAssessment?.announcements?.items || [];

  return (
    <Announcements
      announcements={announcements}
      assessmentId={assessmentId}
      viewOnly={isCompetitionEnded}
      loading={assessmentLoading}
      reactionsViewOnly
      adminView
    />
  );
};

AdminAnnouncements.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  isCompetitionEnded: PropTypes.bool.isRequired,
};

export default AdminAnnouncements;
