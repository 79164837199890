import { gql } from '@apollo/client';
import { useQueryRecursive } from '@fifthdomain/fe-shared';
import {
  listScoreboardsByTeamId,
  listScoreboardsByUserId,
} from '../graphql/queries';

const useGetScoreboard = ({ assessmentId, teamBased, ...otherFilters }) => {
  const scoreboardQuery = teamBased
    ? listScoreboardsByTeamId
    : listScoreboardsByUserId;

  return useQueryRecursive(gql(scoreboardQuery), {
    variables: {
      ...otherFilters,
      assessmentId: { eq: assessmentId },
      limit: 2000,
    },
    skip: !assessmentId,
    staleTime: { minutes: 5 },
  });
};

export default useGetScoreboard;
