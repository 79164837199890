import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';

const LevelIndicator = ({ level, fontVariant }) => (
  <Box display="flex" flexDirection="column" alignItems="center" width="100%">
    <Box>
      <FdTypography variant="captiontext1" color="secondary">
        {level?.levelTitle}
      </FdTypography>
    </Box>
    <Box>
      <FdTypography variant={fontVariant}>{level?.levelName}</FdTypography>
    </Box>
  </Box>
);

LevelIndicator.propTypes = {
  level: PropTypes.objectOf(
    PropTypes.shape({
      levelTitle: PropTypes.string.isRequired,
      levelName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fontVariant: PropTypes.string,
};

LevelIndicator.defaultProps = {
  fontVariant: '',
};

export default LevelIndicator;
