import React from 'react';

const AssignedTraining = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.17466 0.82C2.43066 0.82 2.65466 0.87 2.84666 0.97C3.04266 1.066 3.19466 1.214 3.30266 1.414C3.41466 1.614 3.47066 1.866 3.47066 2.17V4H3.04466V2.212C3.04466 1.88 2.96066 1.63 2.79266 1.462C2.62866 1.29 2.39666 1.204 2.09666 1.204C1.87266 1.204 1.67666 1.25 1.50866 1.342C1.34466 1.43 1.21666 1.56 1.12466 1.732C1.03666 1.9 0.992656 2.104 0.992656 2.344V4H0.566656V0.85H0.974656V1.714L0.908656 1.552C1.00866 1.324 1.16866 1.146 1.38866 1.018C1.60866 0.886 1.87066 0.82 2.17466 0.82Z"
      fill="#03A9F4"
    />
  </svg>
);

export default AssignedTraining;
