import React, { useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import singleSpa from 'single-spa';
import { useParams } from 'react-router';
import {
  gql,
  useMutation,
  useSubscription,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import { isWithinInterval, isBefore } from 'date-fns';
import {
  BasePage,
  FdCard,
  FdTypography,
  FdButton,
  FdLoadingSpinner,
  useSnapshot,
  globalStore,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import AssessmentRules from './AssessmentRules';
import {
  downloadVPNConfigureFile,
  getUserAssessment,
  listTagsByAssessmentId,
} from '../graphql/queries';
import {
  startAssessmentForUser,
  startTeamEvents,
  updateUserAssessment,
} from '../graphql/mutations';
import { onUpdateAssessment } from '../graphql/subscriptions';
import { getDateTimeZoneFormatted } from '../shared/utils/dateUtils';
import CompetitionStory from '../components/Participant/CompetitionStory';
import { invalidateAssessmentsHomePageDataQuery } from '../queries/invalidateQueries';
import appStore from '../store';
import TeamMembers from '../components/Participant/TeamMembers';
import EventTags from '../components/EventTag/EventTags';

const AssessmentStart = () => {
  const globalSnap = useSnapshot(globalStore);
  const snap = useSnapshot(appStore);
  const { assessmentId } = useParams();

  const {
    data: assessmentData,
    loading: assessmentLoading,
    refetch: refetchAssessmentTasksDataQuery,
  } = useQuery(gql(getUserAssessment), {
    variables: {
      id: assessmentId,
    },
    fetchPolicy: 'network-only',
  });

  const [downloadVPNConfig, { loading: downloadVPNConfigInProgress }] =
    useLazyQuery(gql(downloadVPNConfigureFile));

  // get the assessment selected
  const assessmentDataSelected = assessmentData?.getUserAssessment || {};

  const isEventGuided = assessmentDataSelected?.assessment?.guided;
  // List the event tags for the assessmentId
  const {
    data: listTagsByAssessmentIdData,
    loading: listTagsByAssessmentIdLoading,
  } = useQueryRecursive(gql(listTagsByAssessmentId), {
    variables: {
      assessmentId: assessmentDataSelected?.userAssessmentAssessmentId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !assessmentDataSelected?.userAssessmentAssessmentId || isEventGuided,
  });
  const [updateUserAssessmentMutation] = useMutation(gql(updateUserAssessment));

  const [startAssessment, { loading: startAssessmentInProgress }] = useMutation(
    gql(startAssessmentForUser),
    {
      refetchQueries: ['ListUserAssessments'],
      awaitRefetchQueries: true,
      onCompleted: (_data) => {
        invalidateAssessmentsHomePageDataQuery();
        singleSpa.navigateToUrl(
          `/competitions/competition-tasks/${_data?.startAssessmentForUser}`,
        );
      },
      onError: () => singleSpa.navigateToUrl('/competitions/error'),
    },
  );

  const listEventTags =
    listTagsByAssessmentIdData?.listTagsByAssessmentId?.items;

  const [
    startTeamAssessmentMutation,
    { loading: startTeamAssessmentProgress },
  ] = useMutation(gql(startTeamEvents), {
    onCompleted: (_data) => {
      invalidateAssessmentsHomePageDataQuery();
      singleSpa.navigateToUrl(
        `/competitions/competition-tasks/${assessmentId}`,
      );
      return null;
    },
    onError: () => singleSpa.navigateToUrl('/competitions/error'),
  });

  useSubscription(gql(onUpdateAssessment), {
    variables: {
      id: assessmentDataSelected?.userAssessmentAssessmentId,
    },
    onData: () => {
      refetchAssessmentTasksDataQuery();
    },
  });

  useEffect(() => {
    if (assessmentDataSelected.status) {
      if (assessmentDataSelected?.status === 'STARTED') {
        singleSpa.navigateToUrl(
          `/competitions/competition-tasks/${assessmentId}`,
        );
        return;
      }
      // navigate to homepage if status is not valid
      if (assessmentDataSelected?.status !== 'NOT_STARTED') {
        invalidateAssessmentsHomePageDataQuery();
        singleSpa.navigateToUrl('/landing/landing-homepage');
      }
    }
  }, [assessmentDataSelected, assessmentId]);

  if (
    assessmentLoading ||
    startAssessmentInProgress ||
    startTeamAssessmentProgress ||
    downloadVPNConfigInProgress ||
    listTagsByAssessmentIdLoading ||
    !assessmentDataSelected
  ) {
    return <FdLoadingSpinner />;
  }

  const {
    assessment: {
      name,
      startDateTime,
      endDateTime,
      preMessage,
      story,
      videoUrl,
    },
    id: userAssessmentId,
  } = assessmentDataSelected;

  const startDate = startDateTime && getDateTimeZoneFormatted(startDateTime); // TimeZone, like AEDT
  const endDate = endDateTime && getDateTimeZoneFormatted(endDateTime); // TimeZone, like AEDT
  const currentTime = snap.systemDate;

  const readyToStart =
    startDateTime && endDateTime && currentTime
      ? isWithinInterval(new Date(currentTime), {
          start: new Date(startDateTime),
          end: new Date(endDateTime),
        })
      : startDateTime && currentTime
      ? isBefore(new Date(startDateTime), new Date(currentTime))
      : false;

  const isManual = !startDateTime && !endDateTime;

  const enterCompetition = () => {
    if (assessmentDataSelected?.assessment?.teamBased) {
      startTeamAssessmentMutation({
        variables: {
          teamId: assessmentDataSelected?.teamId,
          eventId: assessmentDataSelected?.userAssessmentAssessmentId,
        },
      });
      updateUserAssessmentMutation({
        variables: {
          input: {
            id: assessmentDataSelected?.id,
            lastActive: new Date().toISOString(),
          },
        },
      });
    } else {
      startAssessment({
        variables: {
          userAssessmentId,
        },
      });
      updateUserAssessmentMutation({
        variables: {
          input: {
            id: assessmentDataSelected?.id,
            lastActive: new Date().toISOString(),
          },
        },
      });
    }
  };

  return (
    <BasePage heading={name}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <CompetitionStory story={story} />
          <AssessmentRules
            videoUrl={videoUrl}
            userFullName={globalSnap.name}
            preMessage={preMessage}
            assessmentData={assessmentDataSelected}
          />
          <TeamMembers assessmentData={assessmentData} />
        </Grid>
        <Grid item xs={3}>
          <FdCard variant="outlined" style={{ marginTop: '16px' }}>
            {startDate && (
              <Box>
                <FdTypography variant="h4">Available from</FdTypography>
                <FdTypography variant="subtitle1">{startDate}</FdTypography>
              </Box>
            )}
            {endDate && (
              <Box mt={2}>
                <FdTypography variant="h4">Available until</FdTypography>
                <FdTypography variant="subtitle1">{endDate}</FdTypography>
              </Box>
            )}
            <Box mt={3}>
              <FdTypography variant="subtitle1">
                {isManual
                  ? 'The competition has not yet started. The competition will start whenever the Competition Organizer is ready. You will be able to access the Competition from this page once it has started.'
                  : readyToStart
                  ? 'The competition has started! You can click the Enter Competition button to access the competition.'
                  : 'The competition has not yet started. You can access the competition at any time within the time period listed above.'}
              </FdTypography>
              <Box mt={3}>
                <FdButton
                  size="medium"
                  style={{ width: '100%' }}
                  disabled={!readyToStart}
                  onClick={async () => {
                    if (
                      assessmentDataSelected?.assessment?.enableVPN === 'TRUE'
                    ) {
                      const orgId = assessmentDataSelected?.assessment?.orgId;
                      await downloadVPNConfig({
                        variables: {
                          userAssessmentId: assessmentDataSelected?.id,
                          assessmentId: assessmentDataSelected?.assessment?.id,
                          orgId,
                        },
                        onCompleted: () => {
                          enterCompetition();
                        },
                      });
                    } else {
                      enterCompetition();
                    }
                  }}
                >
                  Enter Competition
                </FdButton>
              </Box>
            </Box>
          </FdCard>
          {assessmentDataSelected?.assessment?.teamBased && (
            <FdCard variant="outlined" style={{ marginTop: '16px' }}>
              <TeamMembers assessmentData={assessmentDataSelected} />
            </FdCard>
          )}
          {!listTagsByAssessmentIdLoading && listEventTags?.length > 0 && (
            <EventTags
              eventTagData={listEventTags}
              teamBased={assessmentDataSelected?.assessment?.teamBased}
              teamId={assessmentDataSelected?.teamId}
              _assessmentId={assessmentDataSelected?.userAssessmentAssessmentId}
            />
          )}
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default AssessmentStart;
