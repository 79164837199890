import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Switch } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdButton,
  FdTypography as Typography,
  FdChip,
} from '@fifthdomain/fe-shared';
import { gql, useMutation } from '@apollo/client';
import {
  updateAssessment,
  createAssessmentLevel,
  deleteAssessmentLevel,
} from '../../graphql/mutations';
import {
  Details,
  Availability,
  PreMessage,
  PostMessage,
  CompetitionType,
  ScoreboardVisibility,
  Jumpbox,
} from '../Assessment';
import { getDateTimeZoneFormatted } from '../../shared/utils/dateUtils';
import { AVAILABILITY_TYPES } from '../../constants';
import FdTextView from '../FdTextView';
import { getArrayByLength } from '../../shared/utils/objectUtils';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';

const ViewDetails = ({
  assessmentData,
  hasManagePermission,
  status,
  showEditButton,
  editDetails,
  setEditDetails,
  isEventGuided,
}) => {
  const { trigger, getValues, reset } = useFormContext();

  const [editJumpbox, setEditJumpbox] = useState(false);
  const [editAvailability, setEditAvailability] = useState(false);
  const [editPreAssessment, setEditPreAssessment] = useState(false);
  const [editPostAssessment, setEditPostAssessment] = useState(false);
  const [editScoreboardVisibility, setEditScoreBoardVisibility] =
    useState(false);

  const [deleteAssessmentLevelMutation] = useMutation(
    gql(deleteAssessmentLevel),
  );
  const [createAssessmentLevelMutation] = useMutation(
    gql(createAssessmentLevel),
  );
  const {
    startDateTime,
    endDateTime,
    id: assessmentId,
  } = assessmentData?.getAssessment || {
    teamBased: false,
    startDateTime: undefined,
    endDateTime: undefined,
  };

  const [updateAssessmentMutation, { loading: updateLoading }] = useMutation(
    gql(updateAssessment),
    {
      onCompleted: (data) => {
        reset({
          name: data.updateAssessment?.name,
          maxTeamSize: data.updateAssessment?.maxTeamSize,
          description: data.updateAssessment?.description,
          availabilityType:
            data.updateAssessment?.availabilityType === 'MANUAL'
              ? { id: 'availabilityType', value: AVAILABILITY_TYPES.MANUAL }
              : { id: 'availabilityType', value: AVAILABILITY_TYPES.AUTOMATIC },
          startDateTime: data.updateAssessment?.startDateTime
            ? new Date(data.updateAssessment?.startDateTime)
            : null,
          endDateTime: data.updateAssessment?.endDateTime
            ? new Date(data.updateAssessment?.endDateTime)
            : null,
          preMessage: data.updateAssessment?.preMessage,
          postMessage: data.updateAssessment?.postMessage,
          taskIds:
            data.updateAssessment?.tasks.items?.map((task) => task.taskId) ||
            [],
          multiLevel: data.updateAssessment?.multiLevel || false,
          videoUrl: data.updateAssessment?.videoUrl,
          scoreboardVisibility: !data?.updateAssessment?.hideScoreBoard,
          levels: !data.updateAssessment?.multiLevel
            ? null
            : data.updateAssessment?.level,
          story: data.updateAssessment?.story,
          tasks: data.updateAssessment?.multiLevel
            ? data.updateAssessment?.levels?.items
                ?.map((l) =>
                  l.tasks.items?.map((t) => ({
                    level: l.levelNumber,
                    taskId: t.taskId,
                  })),
                )
                .flat() || []
            : data.updateAssessment?.tasks.items?.map((task) => ({
                taskId: task.taskId,
              })) || [],
        });
      },
    },
  );

  return (
    <form>
      <Box>
        {editDetails ? (
          <FdCard
            variant="outlined"
            heading={
              <Box display="flex" justifyContent="space-between">
                Details
                <Box display="flex" justifyContent="space-between">
                  <Box pr={1}>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        reset();
                        setEditDetails(false);
                        warningToastMessage('No changes were saved');
                      }}
                    >
                      CANCEL
                    </FdButton>
                  </Box>
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={async () => {
                        const details = await trigger([
                          'multiLevel',
                          'levels',
                          'name',
                          'description',
                          'story',
                        ]);

                        if (details) {
                          const {
                            name,
                            multiLevel,
                            levels,
                            description,
                            story,
                            maxTeamSize,
                          } = getValues();
                          updateAssessmentMutation({
                            variables: {
                              input: {
                                id: assessmentId,
                                name,
                                description,
                                multiLevel,
                                level: levels,
                                story,
                                maxTeamSize,
                              },
                            },
                            onCompleted: () =>
                              successToastMessage('Competition updated'),
                          });

                          if (multiLevel) {
                            const existingLevels =
                              assessmentData.getAssessment?.level || 0;
                            // delete levels if reduced
                            const deleteLevels =
                              Number(existingLevels) - Number(levels);
                            if (deleteLevels > 0) {
                              const oldLevels = getArrayByLength(
                                deleteLevels,
                                // eslint-disable-next-line no-shadow
                                (_, i) => i + 1,
                              );
                              oldLevels.forEach((i) => {
                                const levelId =
                                  assessmentData.getAssessment?.levels?.items.find(
                                    (l) => Number(levels) + i === l.levelNumber,
                                  )?.id;
                                deleteAssessmentLevelMutation({
                                  variables: {
                                    input: {
                                      id: levelId,
                                    },
                                  },
                                });
                              });
                            }

                            // create new levels if not present
                            const createLevels =
                              Number(levels) - Number(existingLevels);
                            if (createLevels > 0) {
                              const newLevels = getArrayByLength(
                                createLevels,
                                // eslint-disable-next-line no-shadow
                                (_, i) => i + 1,
                              );
                              newLevels.forEach((i) => {
                                const levelNumber = Number(existingLevels) + i;
                                createAssessmentLevelMutation({
                                  variables: {
                                    input: {
                                      assessmentId,
                                      levelNumber,
                                      name: `Level ${levelNumber}`,
                                    },
                                  },
                                });
                              });
                            }
                          }
                          setEditDetails(false);
                        }
                      }}
                    >
                      {updateLoading ? 'Loading...' : 'Save'}
                    </FdButton>
                  </Box>
                </Box>
              </Box>
            }
          >
            <CompetitionType editMode />
            <Details editMode />
          </FdCard>
        ) : (
          <FdCard
            variant="outlined"
            heading={
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h3">Details</Typography>
                <Box>
                  {showEditButton && (
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={() => setEditDetails(true)}
                    >
                      EDIT
                    </FdButton>
                  )}
                </Box>
              </Box>
            }
          >
            <FdTextView
              mt={2}
              label="Competition Type"
              value={getValues('assessmentType')?.value}
            />

            <Box mt={2}>
              <FdTextView
                label="Max Team Size"
                value={getValues('maxTeamSize')}
                hideOnEmpty
              />
            </Box>

            {getValues('levels') && (
              <Box>
                <FdTextView label="Level" value="Multi- Level" />
                <Box mt={2}>
                  <Box>
                    <Typography variant="subtitle1">
                      {`No. of Levels : ${getValues('levels')}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            <FdTextView
              mt={2}
              label="Is this a Guided Competition"
              value={getValues('guided') ? 'Yes' : 'No'}
            />
            <FdTextView mt={2} label="Name" value={getValues('name')} />
            <FdTextView
              mt={2}
              label="Description"
              value={getValues('description')}
              hideOnEmpty
            />
            <FdTextView label="Story" value={getValues('story')} hideOnEmpty />
          </FdCard>
        )}
        {editAvailability ? (
          <Availability
            showEdit={showEditButton}
            competitionStatus={status}
            editAvailability={editAvailability}
            heading={
              <Box display="flex" justifyContent="space-between">
                Availability
                <Box display="flex" justifyContent="space-between">
                  <Box pr={1}>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        reset();
                        setEditAvailability(false);
                        warningToastMessage('No changes were saved');
                      }}
                    >
                      CANCEL
                    </FdButton>
                  </Box>
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={async () => {
                        const automatic =
                          getValues('availabilityType')?.value ===
                          AVAILABILITY_TYPES.AUTOMATIC;
                        const availability =
                          status === 'Not Started'
                            ? await trigger(['startDateTime', 'endDateTime'])
                            : await trigger('endDateTime');
                        const startDate = getValues('startDateTime')
                          ? new Date(getValues('startDateTime')).toISOString()
                          : new Date().toISOString();
                        // if manual already started then use that date
                        const manualStartDate =
                          status === 'In Progress' && getValues('startDateTime')
                            ? new Date(getValues('startDateTime')).toISOString()
                            : null;
                        if (availability) {
                          updateAssessmentMutation({
                            variables: {
                              input: {
                                id: assessmentId,
                                startDateTime: automatic
                                  ? startDate
                                  : manualStartDate,
                                endDateTime: automatic
                                  ? new Date(
                                      getValues('endDateTime'),
                                    ).toISOString()
                                  : null,
                                availabilityType:
                                  getValues(
                                    'availabilityType',
                                  )?.value?.toUpperCase(),
                              },
                            },
                            onCompleted: successToastMessage(
                              'Competition updated',
                            ),
                          });
                          setEditAvailability(false);
                        }
                      }}
                    >
                      {updateLoading ? 'Loading...' : 'Save'}
                    </FdButton>
                  </Box>
                </Box>
              </Box>
            }
          />
        ) : (
          <FdCard
            variant="outlined"
            heading={
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h3">Availability</Typography>
                <Box>
                  {showEditButton && hasManagePermission && (
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={() => setEditAvailability(true)}
                    >
                      EDIT
                    </FdButton>
                  )}
                </Box>
              </Box>
            }
          >
            <Box mt={2}>
              <Typography variant="subtitle1">Availability Type</Typography>
              <Typography variant="body1" color="secondary">
                {getValues('availabilityType')?.value}
              </Typography>
            </Box>
            <Box>
              {startDateTime && (
                <FdTextView
                  mt={2}
                  label="Competition Start"
                  value={getDateTimeZoneFormatted(startDateTime)}
                />
              )}
              {endDateTime && (
                <FdTextView
                  mt={2}
                  label="Competition End"
                  value={getDateTimeZoneFormatted(endDateTime)}
                />
              )}
            </Box>
          </FdCard>
        )}
        {editJumpbox ? (
          <FdCard
            variant="outlined"
            heading={
              <Box display="flex" justifyContent="space-between">
                Advanced Networking Features
                <Box display="flex" justifyContent="space-between">
                  <Box pr={1}>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        reset();
                        setEditJumpbox(false);
                        warningToastMessage('No changes were saved');
                      }}
                    >
                      CANCEL
                    </FdButton>
                  </Box>
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={async () => {
                        updateAssessmentMutation({
                          variables: {
                            input: {
                              id: assessmentId,
                              enableJumpbox: getValues('jumpbox')
                                .toString()
                                .toUpperCase(),
                              enableVPN: getValues('enableVPN')
                                .toString()
                                .toUpperCase(),
                            },
                          },
                          onCompleted: () => {
                            successToastMessage('Competition updated');
                          },
                        });
                        setEditJumpbox(false);
                      }}
                    >
                      {updateLoading ? 'Loading...' : 'Save'}
                    </FdButton>
                  </Box>
                </Box>
              </Box>
            }
          >
            <Jumpbox editMode />
          </FdCard>
        ) : (
          <FdCard
            variant="outlined"
            heading={
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h3">
                  Advanced Networking Features
                </Typography>

                <Box>
                  {hasManagePermission &&
                    status !== 'In Progress' &&
                    status !== 'Ended' && (
                      <FdButton
                        variant="primary"
                        size="small"
                        onClick={() => setEditJumpbox(true)}
                      >
                        EDIT
                      </FdButton>
                    )}
                </Box>
              </Box>
            }
          >
            <Box>
              <FdTextView
                mt={2}
                label="Enable VPN configuration for participants"
              />
              <Box mt={2}>
                {getValues('enableVPN') ? (
                  <FdChip color="default" size="medium" label="VPN Enabled" />
                ) : (
                  <Typography style={{ color: 'grey' }}>
                    VPN not enabled
                  </Typography>
                )}
              </Box>
            </Box>
            <Box mt={1}>
              <FdTextView mt={2} label="Enable Jump Box for participants" />
              <Switch checked={getValues('jumpbox')} />
            </Box>
          </FdCard>
        )}
        {editScoreboardVisibility ? (
          <FdCard
            variant="outlined"
            heading={
              <Box display="flex" justifyContent="space-between">
                Scoreboard Visibility
                <Box display="flex" justifyContent="space-between">
                  <Box pr={1}>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        reset();
                        setEditScoreBoardVisibility(false);
                        warningToastMessage('No changes were saved');
                      }}
                    >
                      CANCEL
                    </FdButton>
                  </Box>
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={async () => {
                        const { scoreboardVisibility } = getValues();
                        updateAssessmentMutation({
                          variables: {
                            input: {
                              id: assessmentId,
                              hideScoreBoard: !scoreboardVisibility,
                            },
                          },
                          onCompleted: () =>
                            successToastMessage('Competition updated'),
                        });
                        setEditScoreBoardVisibility(false);
                      }}
                    >
                      {updateLoading ? 'Loading...' : 'Save'}
                    </FdButton>
                  </Box>
                </Box>
              </Box>
            }
          >
            <ScoreboardVisibility editMode />
          </FdCard>
        ) : (
          !isEventGuided && (
            <FdCard
              variant="outlined"
              heading={
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h3">Scoreboard Visibility</Typography>
                  <Box>
                    {hasManagePermission && (
                      <FdButton
                        variant="primary"
                        size="small"
                        onClick={() => setEditScoreBoardVisibility(true)}
                      >
                        EDIT
                      </FdButton>
                    )}
                  </Box>
                </Box>
              }
            >
              <FdTextView mt={2} label="Display Scoreboard" />
              <Switch checked={getValues('scoreboardVisibility')} />
            </FdCard>
          )
        )}
        {editPreAssessment ? (
          <PreMessage
            heading={
              <Box display="flex" justifyContent="space-between">
                Pre-Competition Message
                <Box display="flex" justifyContent="space-between">
                  <Box pr={1}>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        reset();
                        setEditPreAssessment(false);
                        warningToastMessage('No changes were saved');
                      }}
                    >
                      CANCEL
                    </FdButton>
                  </Box>
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={async () => {
                        const preMessageRes = await trigger('preMessage');

                        if (preMessageRes) {
                          updateAssessmentMutation({
                            variables: {
                              input: {
                                id: assessmentId,
                                preMessage: getValues('preMessage'),
                                videoUrl: getValues('videoUrl'),
                              },
                            },
                            onCompleted: successToastMessage(
                              'Competition updated',
                            ),
                          });
                          setEditPreAssessment(false);
                        }
                      }}
                    >
                      {updateLoading ? 'Loading...' : 'Save'}
                    </FdButton>
                  </Box>
                </Box>
              </Box>
            }
          />
        ) : (
          <FdCard
            variant="outlined"
            heading={
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h3">Pre-Competition Message</Typography>
                <Box>
                  {showEditButton && (
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={() => setEditPreAssessment(true)}
                    >
                      EDIT
                    </FdButton>
                  )}
                </Box>
              </Box>
            }
          >
            <Box mt={2}>
              <Typography variant="body1" color="secondary">
                {getValues('preMessage')}
              </Typography>
            </Box>
            <FdTextView
              mt={2}
              label="Video"
              value={getValues('videoUrl') || 'Video not added'}
            />
          </FdCard>
        )}
        {editPostAssessment ? (
          <PostMessage
            heading={
              <Box display="flex" justifyContent="space-between">
                Post-Competition Message
                <Box display="flex" justifyContent="space-between">
                  <Box pr={1}>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        reset();
                        setEditPostAssessment(false);
                        warningToastMessage('No changes were saved');
                      }}
                    >
                      CANCEL
                    </FdButton>
                  </Box>
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={async () => {
                        const postMessageRes = await trigger('postMessage');

                        if (postMessageRes) {
                          updateAssessmentMutation({
                            variables: {
                              input: {
                                id: assessmentId,
                                postMessage: getValues('postMessage'),
                              },
                            },
                            onCompleted: successToastMessage(
                              'Competition updated',
                            ),
                          });
                          setEditPostAssessment(false);
                        }
                      }}
                    >
                      {updateLoading ? 'Loading...' : 'Save'}
                    </FdButton>
                  </Box>
                </Box>
              </Box>
            }
          />
        ) : (
          <FdCard
            variant="outlined"
            heading={
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h3">Post-Competition Message</Typography>
                <Box>
                  {showEditButton && (
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={() => setEditPostAssessment(true)}
                    >
                      EDIT
                    </FdButton>
                  )}
                </Box>
              </Box>
            }
          >
            <Box mt={2}>
              <Typography variant="body1" color="secondary">
                {getValues('postMessage')}
              </Typography>
            </Box>
          </FdCard>
        )}
      </Box>
    </form>
  );
};

ViewDetails.propTypes = {
  assessmentData: PropTypes.shape({
    getAssessment: PropTypes.shape({
      id: PropTypes.string,
      level: PropTypes.number,
      levels: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
  hasManagePermission: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  showEditButton: PropTypes.bool.isRequired,
  editDetails: PropTypes.bool.isRequired,
  setEditDetails: PropTypes.func.isRequired,
  isEventGuided: PropTypes.bool.isRequired,
};

export default ViewDetails;
