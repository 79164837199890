export const setToolTipData = (_setter, _data) => {
  const { specialty, taskName, points, estimatedTime, status, attempts } =
    _data;
  _setter({
    specialty,
    taskName,
    points,
    estimatedTime,
    status,
    attempts,
  });
};

export const getTasksByDifficulty = (_tasks, _difficulty) =>
  _tasks?.filter((t) => _difficulty === t.difficulty);

export const getDonutGraphRow = (t, _proficiency) => ({
  id: t.id,
  label: _proficiency,
  status: t.status,
  specialty: t.specialty,
  value: t.recommendedPoints,
  taskName: t.name,
  points: t.recommendedPoints,
  estimatedTime: t.estimatedSolveTime,
  attempts: t.attempts,
});
