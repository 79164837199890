import Yup from '../Common';

export const initialValues = {
  tagName: '',
  tagDescription: '',
  tagColor: 'YELLOW',
};

export const validationSchema = Yup.object().shape({
  tagName: Yup.string()
    .required('Please enter a tag name.')
    .max(50, 'Tag name must be 50 characters or less.')
    .noWhitespace('Please enter a tag name.'),
  tagDescription: Yup.string()
    .test(
      'noWhitespaceNonRequired',
      'Please enter a valid tag description',
      (value) => !value || (value && value.trim().length),
    )
    .max(100, 'Tag description must be 100 characters or less.'),
  tagColor: Yup.string().nullable(),
});
