import { sortByDateField } from '../../shared/utils/dateUtils';
import { getImageUrlFromS3 } from '../../shared/utils/images';

export const loadMessagesWithImages = async (
  announcements,
  currentUserId,
  teams = [],
) => {
  const messagesDisplay = await Promise.all(
    [...announcements]
      ?.sort(sortByDateField('createdAt', 'asc'))
      ?.map(async (a) => {
        const message =
          a?.messages?.find((m) => m?.type === 'TEXT')?.content || '';
        const _imageKey =
          a?.messages?.find((m) => m?.type === 'IMAGE')?.content || '';
        const team = teams?.find((t) =>
          t?.team?.members.items.some((member) => member?.userId === a?.userId),
        )?.team;

        return {
          messageId: a?.id,
          comment: message,
          image: _imageKey ? await getImageUrlFromS3(_imageKey) : undefined,
          dateTime: new Date(a?.createdAt),
          userName: a?.user?.alias,
          userId: a?.userId,
          role: a?.role,
          reactionCount:
            a?.reactions?.items?.filter((r) => r?.reactionType === 'LIKE')
              ?.length || 0,
          currentUserLiked: a?.reactions?.items.some(
            (item) =>
              item.userId === currentUserId && item?.reactionType === 'LIKE',
          ),
          updatedAt: a?.updatedAt,
          team,
        };
      }) || [],
  );
  return messagesDisplay;
};
