import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { FdIcons, FdTypography } from '@fifthdomain/fe-shared';
import LevelIndicator from './LevelIndicator';
import LevelIcon from './LevelIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listItem: {
    background: theme.palette.background.paper,
    marginBottom: '16px',
    height: '60px',
    minWidth: '171px',
    border:
      theme.palette.type === 'dark'
        ? `1px solid ${theme.palette.table.border}`
        : 'none',
  },
  listItemText: {
    wordBreak: 'break-all',
  },
  listIcon: {
    minWidth: '38px',
    paddingLeft: 0,
    marginLeft: 0,
  },
  highlighted: {
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    paddingLeft: '12px',
  },
  circularShadow: {
    marginLeft: '6px',
    marginRight: '6px',
    position: 'relative',
    display: 'inline-flex',
    color: theme.palette.type === 'light' ? '#CCD5DE' : '#90caf9',
  },
  circularSuccess: {
    color: theme.palette.type === 'light' ? '#4caf50' : '#90caf9',
  },
  successIcon: {
    color: theme.palette.type === 'light' ? '#4caf50' : '#90caf9',
    fontSize: '48px !important',
    paddingLeft: '2px',
  },
}));

const IconWithProgress = ({ percentageComplete }) => {
  const classes = useStyles();
  const { CheckCircleOutline } = FdIcons;
  return percentageComplete === 100 ? (
    <Box pr="0.1rem">
      <CheckCircleOutline className={classes.successIcon} />
    </Box>
  ) : (
    <Box>
      <Box className={classes.circularShadow}>
        <CircularProgress
          variant="determinate"
          value={100}
          color="inherit"
          thickness={5}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            variant="determinate"
            value={percentageComplete}
            className={classes.circularSuccess}
            thickness={5}
          />
        </Box>
      </Box>
    </Box>
  );
};

IconWithProgress.propTypes = {
  percentageComplete: PropTypes.number.isRequired,
};

const LevelNumberIcon = ({ level, completed }) => {
  return (
    <Box
      className="flex items-center justify-center h-10 w-10"
      style={{
        borderRadius: '8px',
        backgroundColor: completed
          ? 'rgba(40, 149, 123, 1)'
          : 'rgba(76, 90, 255, 1)',
        color: '#fff',
      }}
    >
      <FdTypography variant="body1" style={{ fontFamily: 'Raitor' }}>
        {`0${level}`.slice(-2)}
      </FdTypography>
    </Box>
  );
};

LevelNumberIcon.propTypes = {
  level: PropTypes.number.isRequired,
  completed: PropTypes.bool.isRequired,
};

const LevelSelector = ({ levels, onSelect, customIcons }) => {
  const classes = useStyles();
  const ListIcon = ({ percentageComplete, level, selected }) =>
    customIcons ? (
      <LevelIcon
        level={level}
        completed={percentageComplete === 100}
        selected={selected}
      />
    ) : (
      <LevelNumberIcon level={level} completed={percentageComplete === 100} />
    );
  ListIcon.propTypes = {
    percentageComplete: PropTypes.number.isRequired,
    level: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
  };

  return (
    <Box height="702px" style={{ overflowY: 'auto' }}>
      <List component="nav" disablePadding>
        {levels.map((l) => (
          <ListItem
            button
            className={`${classes.listItem} ${
              l.selected && classes.highlighted
            }`}
            onClick={() => onSelect(l.level)}
            key={shortid.generate()}
          >
            <ListItemIcon className={classes.listIcon}>
              <ListIcon
                level={l.level}
                percentageComplete={l.percentageComplete}
                selected={l.selected}
              />
            </ListItemIcon>
            <ListItemText>
              <LevelIndicator level={l} />
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

LevelSelector.defaultProps = {
  customIcons: false,
};

LevelSelector.propTypes = {
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.number,
      percentageComplete: PropTypes.number,
      selected: false,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  customIcons: PropTypes.bool,
};

export default LevelSelector;
