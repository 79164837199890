import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@material-ui/core';
import getGraphTheme from '../../shared/utils/graphUtil';

const BarStacked = ({
  data,
  keys,
  indexBy,
  onHover,
  onLeave,
  onMouseClick,
}) => {
  const theme = useTheme();
  const graphTheme = getGraphTheme(theme);
  const isDarkTheme = graphTheme === 'dark';
  const legendColor = isDarkTheme
    ? 'rgba(255, 255, 255, 0.7)'
    : 'rgba(0, 0, 0, 0.54)';
  const isRotateLegend = data.length > 5;
  const legendLength = isRotateLegend ? 3 : 20;
  const customTheme = {
    ...theme.nivoGraph,
    fontFamily: 'Montserrat, sans-serif',
    tooltip: {
      container: {
        fontFamily: 'Montserrat, sans-serif',
      },
    },
  };

  return (
    <ResponsiveBar
      theme={customTheme}
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 20, right: 0, bottom: 30, left: 0 }}
      padding={0.5}
      innerPadding={1}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={({ id, data: _data }) => _data[`${id}Color`]}
      role="application"
      tooltip={() => null}
      enableLabel={false}
      enableGridY={false}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      onClick={onMouseClick}
      axisBottom={{
        format: (v) => {
          return v?.length > legendLength ? (
            <tspan style={{ fill: legendColor }}>
              {`${v.substring(0, legendLength)}...`}
              <title>{v}</title>
            </tspan>
          ) : (
            <tspan style={{ fill: legendColor }}>{v}</tspan>
          );
        },
        tickSize: 0,
        tickPadding: isRotateLegend ? 3 : 10,
        tickRotation: isRotateLegend ? 90 : 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
    />
  );
};

BarStacked.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  indexBy: PropTypes.string.isRequired,
  onHover: PropTypes.func,
  onLeave: PropTypes.func,
  onMouseClick: PropTypes.func,
};

BarStacked.defaultProps = {
  data: [],
  onHover: () => null,
  onLeave: () => null,
  onMouseClick: () => null,
};

export default BarStacked;
