import React from 'react';
import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';
import { Box, Grid } from '@material-ui/core';
import {
  FdCard,
  FdTypography,
  FdAccordion,
  FdMarkdownRender,
} from '@fifthdomain/fe-shared';
import FdTextView from '../FdTextView';
import { getDateTimeZoneFormatted } from '../../shared/utils/dateUtils';
import SummaryTasksTable from './SummaryTasksTable';
import { getArrayByLength } from '../../shared/utils/objectUtils';

const AssessmentTasksSummary = ({ values, allTasks }) => {
  const {
    assessmentType,
    name,
    description,
    multiLevel,
    levels,
    preMessage,
    postMessage,
    availabilityType,
    startDateTime,
    endDateTime,
    tasks,
  } = values;
  const allLevels = getArrayByLength(
    Number(levels),
    (_, i) => `Level ${i + 1}`,
  );

  return (
    <Box>
      <FdCard heading="Details" variant="outlined">
        <FdTextView label="Competition Type" value={assessmentType?.value} />
        <FdTextView label="Name" value={name} />
        <FdTextView label="Description" value={description} />
        {multiLevel && (
          <FdTypography variant="subtitle1">{`No. of  Levels : ${levels}`}</FdTypography>
        )}
      </FdCard>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={10}>
          <FdCard
            heading="Instructions"
            style={{ height: '100%' }}
            variant="outlined"
          >
            <FdTypography variant="subtitle1">
              <ShowMoreText
                lines={5}
                more="see more"
                less="see less"
                anchorClass="see-more"
              >
                <FdMarkdownRender markdown={preMessage} />
              </ShowMoreText>
            </FdTypography>
            <Box mt={3}>
              <Box mb={2}>
                <FdTypography variant="h4">
                  Post-Competition message
                </FdTypography>
              </Box>
              <FdTypography variant="subtitle1">
                <ShowMoreText
                  lines={5}
                  more="see more"
                  less="see less"
                  anchorClass="see-more"
                >
                  <FdMarkdownRender markdown={postMessage} />
                </ShowMoreText>
              </FdTypography>
            </Box>
          </FdCard>
        </Grid>
        <Grid item xs={12} md={2}>
          <FdCard
            heading="Availability"
            style={{ height: '100%' }}
            variant="outlined"
          >
            <FdTextView
              label="Availability Type"
              value={availabilityType?.value}
            />
            {availabilityType?.value !== 'Manual' && (
              <Box>
                <FdTextView
                  label="Competition Start"
                  value={getDateTimeZoneFormatted(startDateTime)}
                />
                <FdTextView
                  label="Competition End"
                  value={getDateTimeZoneFormatted(endDateTime)}
                />
              </Box>
            )}
          </FdCard>
        </Grid>
      </Grid>
      <Box mt={2.5}>
        {multiLevel ? (
          <FdCard heading="Level Details" variant="outlined">
            {allLevels.map((level, index) => (
              <FdAccordion
                summary={() => (
                  <FdTypography variant="h3">{level}</FdTypography>
                )}
                startAdornment
                showToggleButton={false}
                content={() => (
                  <SummaryTasksTable
                    data={
                      tasks
                        .filter((t) => t.level === index + 1)
                        .map((t, i) => ({
                          ...allTasks.find((at) => at.id === t.taskId),
                          slNo: i + 1,
                        })) || []
                    }
                    levelBased
                  />
                )}
              />
            ))}
          </FdCard>
        ) : (
          <FdCard heading="Challenges" variant="outlined">
            <SummaryTasksTable
              data={
                tasks.map((t, i) => ({
                  ...allTasks.find((at) => at.id === t.taskId),
                  slNo: i + 1,
                })) || []
              }
            />
          </FdCard>
        )}
      </Box>
    </Box>
  );
};

AssessmentTasksSummary.propTypes = {
  values: PropTypes.objectOf({}).isRequired,
  allTasks: PropTypes.arrayOf(PropTypes.objectOf({})).isRequired,
};

export default AssessmentTasksSummary;
