import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FdTypography, FdTextField, FdModal } from '@fifthdomain/fe-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { TAG_COLOUR } from '../../constants';
import { validationSchema } from '../../validation-schemas/TagDirectory';

const useStyles = makeStyles(() => ({
  selectedTag: {
    border: '2px solid #BDBDBD',
    padding: '4px',
    borderRadius: '50%',
  },
}));

const CreateEventTag = ({
  openModal,
  isEdit,
  onConfirm,
  onDismiss,
  tagDetails,
  loading,
}) => {
  const classes = useStyles();
  const reactHookFormMethods = useForm({
    defaultValues: tagDetails,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { control, getValues, setValue, trigger, reset } = reactHookFormMethods;

  useEffect(() => {
    reset(tagDetails);
  }, [tagDetails, reset]);

  return (
    <FormProvider {...reactHookFormMethods}>
      <form style={{ width: '100%' }}>
        <FdModal
          size="md"
          title={isEdit ? 'Edit Event Tag' : 'Create Event Tag'}
          description={
            !isEdit &&
            'Create a tag that participants/teams can apply to themselves for the duration of this event.'
          }
          confirm={loading ? 'Loading' : isEdit ? 'Save' : 'Create'}
          dismiss="CANCEL"
          open={openModal}
          onConfirm={async () => {
            const result = await trigger();
            // submit confirm with values
            if (result) {
              const { tagName, tagDescription, tagColor, id } = getValues();
              onConfirm(
                {
                  tagName: tagName.trim(),
                  tagDescription,
                  tagColor,
                  id,
                },
                reset,
              );
            }
          }}
          onDismiss={() => {
            reset();
            onDismiss();
          }}
          data-cy="open-modal"
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Controller
              control={control}
              name="tagName"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1}>
                  <FdTextField
                    id="tagName"
                    label="Tag Name"
                    required
                    fullWidth
                    error={error}
                    helperText={error?.message}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            <Box mt={3} mb={3}>
              <Controller
                control={control}
                name="tagDescription"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mb={2}>
                    <FdTextField
                      id="tagDescription"
                      label="Tag Description"
                      fullWidth
                      multiline
                      rows={3}
                      error={error}
                      helperText={error?.message}
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
            </Box>
            <Controller
              control={control}
              name="tagColor"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box>
                  <FdTypography variant="subtitle1">Tag Colour</FdTypography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt={1}
                    mb={2}
                    alignItems="baseline"
                  >
                    {TAG_COLOUR?.map((tagColour) => {
                      return (
                        <Box
                          mr={3}
                          className={
                            rest.value === tagColour.value
                              ? classes.selectedTag
                              : null
                          }
                        >
                          <IconButton
                            id="tagColor"
                            style={{
                              height: '40px',
                              borderRadius: '50%',
                              width: '40px',
                              backgroundColor: tagColour.backgroundColor,
                            }}
                            error={error && error.message}
                            data-cy="tagType"
                            {...rest}
                            inputRef={ref}
                            onClick={() => {
                              setValue('tagColor', tagColour.value);
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
            />
          </Box>
        </FdModal>
      </form>
    </FormProvider>
  );
};

CreateEventTag.propTypes = {
  loading: PropTypes.bool.isRequired,
  openModal: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  tagDetails: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
};

CreateEventTag.defaultProps = {
  isEdit: false,
  tagDetails: {},
};

export default CreateEventTag;
