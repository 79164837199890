import React, { useEffect, useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { AuthContext, FdProgress } from '@fifthdomain/fe-shared';
import { getUserById } from '../graphql/queries';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const { data, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: user?.username,
    },
    skip: !user,
  });
  const history = useHistory();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch(() => {
        history.push('/');
      });
  }, []);

  if (userLoading) return <FdProgress />;

  return (
    <Route
      {...rest}
      render={(props) => {
        // all other valid auth routes
        if (user && data) {
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default AuthRoute;
