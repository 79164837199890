import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdCard, FdMarkdownRender } from '@fifthdomain/fe-shared';

const CompetitionStory = ({ story }) => {
  return (
    story && (
      <FdCard heading="Competition Story" variant="outlined">
        <Box>
          <FdMarkdownRender markdown={story} />
        </Box>
      </FdCard>
    )
  );
};

CompetitionStory.propTypes = {
  story: PropTypes.string,
};

CompetitionStory.defaultProps = {
  story: '',
};

export default CompetitionStory;
