import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, makeStyles } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';
import SpecialtyIcon from '../SpecialtyIcon';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '10px 0',
  },
  tooltip: {
    backgroundColor:
      theme.palette.type === 'dark'
        ? 'rgba(23, 56, 106, 1)'
        : theme.palette.background.default,
    color: theme.palette.typography.primary,
  },
}));

const LevelTooltip = ({ data }) => {
  const classes = useStyles();
  const { specialty, taskName, points, estimatedTime, status, attempts } = data;

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt={3}
    >
      <Box
        display="flex"
        height="62px"
        width="450px"
        alignItems="center"
        style={{
          borderRadius: 4,
        }}
        pt="4px"
        pb="2px"
        px="16px"
        className={classes.tooltip}
      >
        <Box mr={1.5}>
          <SpecialtyIcon specialty={specialty} />
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <Box>
              <FdTypography variant="captiontext1">{taskName}</FdTypography>
            </Box>
            <Box mt={0.2}>
              <FdTypography variant="captiontext2">{`Points: ${points}`}</FdTypography>
            </Box>
            <Box>
              <FdTypography variant="captiontext2">
                {`Est. time: ${estimatedTime} min`}
              </FdTypography>
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            className={classes.divider}
            flexItem
          />
          <Box>
            <FdTypography variant="captiontext1">{status}</FdTypography>
            <Box mt={0.2}>
              <FdTypography variant="captiontext2">
                {`No. of attempts: ${attempts || 0}`}
              </FdTypography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

LevelTooltip.propTypes = {
  data: PropTypes.shape({
    specialty: PropTypes.string,
    taskName: PropTypes.string,
    points: PropTypes.number,
    estimatedTime: PropTypes.number,
    status: PropTypes.string,
    attempts: PropTypes.number,
  }).isRequired,
};

export default LevelTooltip;
