import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { FdChip } from '@fifthdomain/fe-shared';
import { PROFICIENCY } from '../../constants';

const useStyles = makeStyles(() => ({
  cellHeading: {
    lineHeight: '28px',
  },
  condensedChip: {
    fontSize: '10px !important',
    height: '16px !important',
    borderRadius: '4px !important',
    marginBottom: '3px',
    marginRight: '0.5rem',
    padding: '9px 0',
    '& [class*="MuiChip-label"]': {
      padding: '3px 5px',
    },
  },
}));

const ChallengeInfoCell = ({ values }) => {
  const classes = useStyles();
  const { difficultyLabel, name } = values;

  return (
    <Box>
      <Box className={classes.cellHeading}>{name}</Box>
      <Box display="flex">
        <FdChip
          style={{ backgroundColor: PROFICIENCY[difficultyLabel]?.color }}
          size="small"
          label={difficultyLabel}
          className={classes.condensedChip}
        />
      </Box>
    </Box>
  );
};

ChallengeInfoCell.propTypes = {
  values: PropTypes.objectOf({
    difficulty: PropTypes.string,
    name: PropTypes.string,
    estimatedSolveTime: PropTypes.string,
  }).isRequired,
};

export default ChallengeInfoCell;
