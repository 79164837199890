export const getTeamsWithSharedEmails = (teams) => {
  const teamGroups = [];
  // Create a map to store teams by email
  const emailToTeamsMap = new Map();
  teams.forEach((team) => {
    const { teamName, emails } = team;
    const matchingGroup = teamGroups.find((group) =>
      emails.some(
        (email) =>
          email in emailToTeamsMap && group.includes(emailToTeamsMap[email]),
      ),
    );
    if (matchingGroup) {
      matchingGroup.push(teamName);
    } else {
      // If no matching group is found, create a new group
      const group = [teamName];
      // eslint-disable-next-line no-return-assign, prefer-destructuring
      emails?.forEach((email) => (emailToTeamsMap[email] = group[0]));
      teamGroups.push(group);
    }
  });
  const filteredGroups = teamGroups.filter((group) => group.length > 1);
  return filteredGroups.map((group) => ({ teams: group }));
};
