import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import { Box, useTheme } from '@material-ui/core';

const LineNivo = ({ data, showDashedForIds, colors, ...props }) => {
  const theme = useTheme();
  const customTheme = {
    ...theme.nivoGraph,
    tooltip: {
      container: {
        fontFamily: 'Montserrat, sans-serif',
      },
    },
  };
  const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data: _data, color }) => (
      <path
        key={id}
        d={lineGenerator(
          _data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          })),
        )}
        fill="none"
        stroke={showDashedForIds?.find((i) => i?.id === id)?.color || color}
        style={
          showDashedForIds?.find((i) => i?.id === id)
            ? {
                strokeDasharray: '6, 6',
                strokeWidth: 3,
              }
            : {
                strokeWidth: 2,
              }
        }
      />
    ));
  };

  return (
    <Box data-cy="line-chart" height="100%">
      <ResponsiveLine
        data={data}
        colors={colors}
        margin={{ top: 30, right: 50, bottom: 40, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 100,
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Performance Score',
          legendOffset: -40,
          legendPosition: 'middle',
          tickValues: [0, 25, 50, 75, 100],
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh
        enableCrosshair={false}
        theme={customTheme}
        gridYValues={[0, 25, 50, 75, 100]}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'line',
          'slices',
          'points',
          'mesh',
          'legends',
          DashedSolidLine,
        ]}
        {...props}
      />
    </Box>
  );
};

LineNivo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  showDashedForIds: PropTypes.arrayOf(PropTypes.shape({})),
};

LineNivo.defaultProps = {
  colors: [],
  showDashedForIds: [],
};

export default LineNivo;
